import { Pinyin } from "newComponents/Word";
import { useGet, usePost } from "Services";
import Spinner from "newComponents/Spinner";
import Sentence from "newComponents/Sentence";
import SimpleVideo, { VideoPreview } from "newComponents/SimpleVideo";
import { Button } from "newComponents/base";
import { toast } from "react-toastify";
import parseErrorMessage from "utils/errors";
import StrokeDiagram from "newComponents/StrokeDiagram";
import WordClasses from "./WordClasses";
import { useModals } from "contexts/ModalProvider";

const ResultItem = ({
  word,
  showSource = false,
  videosInline = true,
  showSpinner = true,
  wordTarget = "_self"
}) => {
  const { loading, data } = useGet(`/dictionary/${word.id}`);
  const [addToFlashcards, addToFlashcardsRes] = usePost("/flashcards", {
    onComplete: () => toast.success("Added to flashcards."),
    onError: error => toast.error(parseErrorMessage(error))
  });

  const videoModal = useModals();

  if (loading && !videosInline && showSpinner)
    return (
      <div className="py-12">
        <Spinner />
      </div>
    );

  return (
    <div>
      <div className="md:flex items-center justify-between flex-wrap">
        <div className="flex items-center">
          {showSource ? <div className="text-2xl pr-4">{word.text}</div> : null}
          <Pinyin word={word} />
        </div>
        <Button
          loading={addToFlashcardsRes.loading}
          disabled={addToFlashcardsRes.loading}
          className="mt-2 md:mt-0 md:ml-8"
          onClick={() => addToFlashcards({ word_id: word.id, quality: "0" })}>
          Add to Flashcards
        </Button>
      </div>

      <WordClasses classes={data && data.class.length > 0 ? data.class : null} />

      {data ? (
        <div className="mt-4">
          <div className="overflow-x-auto overflow-y-hidden">
            <div className="flex items-center space-x-4">
              {data.characters
                .filter(char => char.stroke)
                .map((char, index) => (
                  <StrokeDiagram index={index} key={`${word.id}${index}`} {...char} />
                ))}
            </div>
          </div>
          <div className="text-xs opacity-50 mt-2">Click to see how to write these characters.</div>
        </div>
      ) : null}

      <div className="mt-8">
        <label>Definition</label>
        <div className="mt-2 flex flex-wrap text-xl">{word.definitions.join(", ")}</div>
      </div>

      {data && data.sentences.length > 0 ? (
        <div className="mt-10">
          <label>Example Sentences</label>
          <div className="text-lg">
            {data.sentences.map((i, index) => (
              <Sentence key={index} sentence={i} selectedWord={data} wordTarget={wordTarget} />
            ))}
          </div>
          <div className="mt-4 text-xs text-gray-500">
            Click words in sentence to see definition, example sentence and videos in dictinoary.
          </div>
        </div>
      ) : null}

      {data && data.videos.length > 0 ? (
        <div className="mt-10">
          <label>Videos</label>
          <div
            className={`mt-4 md:flex space-y-6 md:space-y-0
                  ${videosInline ? "w-full overflow-auto space-x-6" : "flex-wrap md:-mx-4"}
                `}>
            {data.videos.map((video, index) => (
              <div key={index} className="w-full md:w-1/2 md:px-4 mb-6">
                <VideoPreview
                  video={video}
                  onClick={() =>
                    videoModal.present({
                      maxWidth: "max-w-7xl",
                      children: <SimpleVideo video={video} />
                    })
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ResultItem;
