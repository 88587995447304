import { useGet } from "Services";
import Spinner from "./Spinner";
import Errors from "./Errors";
import Simpleviddeo from "newComponents/SimpleVideo";

const WordVideo = ({ word }) => {
  const { loading, error, data } = useGet(`/dictionary/${word.id}`);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  if (data.videos && data.videos.length > 0) {
    const video = data.videos[0];
    return <Simpleviddeo video={video} />;
  }

  return <div>No videos for this word.</div>;
};

export default WordVideo;
