import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDelete, useGet, usePost } from "Services";
import LessonVideos from "./LessonVideos";
import VideoPlayer from "newComponents/video/VideoPlayer";
import { Button, Switcher, BackButton, PrimaryLink } from "newComponents/base";
import VideoQuiz from "./quiz/VideoQuiz";
import Spinner from "newComponents/Spinner";
import VideoComments from "./VideoComments";
import { ApiContext } from "contexts/ApiContext";
import { toast } from "react-toastify";
import { toABC } from "utils/string";
import { IoIosSend } from "react-icons/io";
import Feedback from "pages/feedback";
import Errors from "newComponents/Errors";
import parseErrorMessage from "utils/errors";
import { FaChevronRight } from "react-icons/fa";
import { ModalContext, useModals } from "contexts/ModalProvider";
import usePromo from "hooks/usePromo";

function getNextVideo(videos, currentVideoId) {
  const index = videos.findIndex((i) => i.id === currentVideoId);
  if (index + 1 < videos.length) {
    return videos[index + 1];
  } else {
    return null;
  }
}

const VideolNavigation = () => {
  const { id } = useParams();
  const { db } = useContext(ApiContext);

  const video = db.videos.find((i) => i.id === id);
  const lesson = db.lessons.find((i) => i.id === video.videoable_id);
  const chapter = db.chapters.find((i) => i.id === lesson.chapter_id);
  const level = db.levels.find((i) => i.id === chapter.level_id);
  const course = db.courses.find((i) => i.id === level.course_id);

  return (
    <div className="whitespace-nowrap">
      <BackButton
        id="back-button"
        to={`/study/${course.id}/${level.id}/${chapter.id}/${lesson.id}/${video.id}`}
        label={`Back to Lesson ${toABC(lesson.ordering)}`}
      />
      <div className="flex items-center overflow-auto">
        <div className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">{course.name}</div>
        <span className="px-3 text-gray-500">
          <FaChevronRight />
        </span>
        <div className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">{level.name}</div>
        <span className="px-3 text-gray-500">
          <FaChevronRight />
        </span>
        <div className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">{chapter.name}</div>
        <span className="px-3 text-gray-500">
          <FaChevronRight />
        </span>
        <div className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
          Lesson {toABC(lesson.ordering)} - {lesson.name}
        </div>
      </div>
      <h1 className="w-full whitespace-normal break-all">{video.name}</h1>
    </div>
  );
};

// Lesson video
const StudyVideo = () => {
  const { studying, completedItems, setApiState, db } = useContext(ApiContext);

  const { coupon, bigDiscount } = usePromo();
  const { hasModals } = useContext(ModalContext);
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useGet(`frontend/videos/${id}`);
  const [addFavourte, addFavourteRes] = usePost(`/favourites/${id}`, {
    onComplete: () => {
      toast.success("Saved to your favourite video list.");
    },
  });
  const [removeFavourte, removeFavourteRes] = useDelete(`/favourites/${id}`, {
    onComplete: () => {
      toast.success("Remove.");
    },
  });
  const [skipQuiz, setSkipQuiz] = useState(localStorage.getItem("video:skipQuiz") || false);
  useEffect(() => {
    if (skipQuiz) {
      localStorage.setItem("video:skipQuiz", "1");
    } else {
      localStorage.removeItem("video:skipQuiz");
    }
  }, [skipQuiz]);

  const [hasShowQuiz, setHasShowQuiz] = useState(false);
  const [hasShownCompletedLesson, setHasShownCompletedLesson] = useState(false);

  const [wideMode, setWideMode] = useState(localStorage.getItem("wideMode", false));

  const [isFavourite, setIsFavourite] = useState(false);

  const [hasMarkedCompleted, setHasMarkedCompleted] = useState(false);
  const [completeVideo] = usePost(`/videos/${id}/complete`, {
    onComplete: () => {
      setHasMarkedCompleted(true);
      setApiState("completedItems", {
        ...completedItems,
        videoIds: [...completedItems.videoIds, id],
      });
    },
    onError: (error) => toast.error(parseErrorMessage(error)),
  });

  useEffect(() => {
    setHasMarkedCompleted(false);
    setHasShowQuiz(false);
    if (data) {
      setIsFavourite(data.is_favourite);
      setApiState("studying", {
        ...studying,
        course_id: data.course_id,
        level_id: data.level_id,
        chapter_id: data.chapter_id,
        lesson_id: data.lesson_id,
        video_id: id,
      });
    }
  }, [id, data]);

  useEffect(() => {
    if (completedItems && completedItems.videoIds) setHasMarkedCompleted(completedItems.videoIds.includes(id));
  }, [completedItems]);

  const [forcePlaying, setForcePlaying] = useState(true);
  const [wasPlaying, setWasPlaying] = useState(true);
  const [forceReplay, setForceReplay] = useState(0);

  const feedbackModal = useModals();
  const quizModal = useModals();
  const lessonCompleteModal = useModals();

  useEffect(() => {
    if (hasModals) {
      setForcePlaying(false);
    } else {
      setForcePlaying(wasPlaying);
    }
  }, [hasModals]);

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  const localVideo = db.videos.find((i) => i.id === id);
  const lesson = db.lessons.find((i) => i.id === studying.lesson_id);
  const lessonVideos = db.videos.filter((i) => i.videoable_id === studying.lesson_id && i.videoable_type === "App\\Lesson");
  const nextVideo = getNextVideo(lessonVideos, id);

  const course = db.courses.find((i) => i.id === studying.course_id);

  function showLastVideoOfLessonModal() {
    if (!hasShownCompletedLesson) {
      lessonCompleteModal.present({
        center: true,
        children: (
          <div className="px-10 pt-16 pb-12 text-center">
            <h1 className="">Lesson Summary Completed!!</h1>
            <h1 className="my-8">🎉🎉🎉</h1>
            <div className="space-y-10">
              <div>Congratulations! You have completed the last video of this lesson.</div>
              {course.has_quiz ? (
                <div className="flex space-x-8 mt-8 justify-center">
                  <PrimaryLink to={`/study/lesson/${lesson.id}/quiz`}>Take the Lesson Quiz</PrimaryLink>
                </div>
              ) : null}

              <div className="space-x-16 mt-8 flex justify-center">
                <Button back customColor="text-gray-600" to="/study">
                  Go to Lesson overview
                </Button>

                {course.has_quiz ? (
                  <Button customColor="text-gray-600" to="/">
                    Go back to Dashboard
                  </Button>
                ) : (
                  <PrimaryLink to="/">Go to Dashboard</PrimaryLink>
                )}
              </div>
            </div>
          </div>
        ),
      });
      setHasShownCompletedLesson(true);
    }
  }

  function showQuiz() {
    quizModal.present({
      center: true,
      noPadding: true,
      children: (
        <VideoQuiz
          id={id}
          type="video"
          goNextVideo={nextVideo ? goNextVideo : null}
          watchVideoAgain={watchVideoAgain}
          lastVideoOfLesson={lesson}
          coupon={coupon}
          bigDiscount={bigDiscount}
          checkout={() => {
            quizModal.hide();
            window.open(process.env.REACT_APP_SUBSCRIBE_PAGE);
          }}
        />
      ),
    });
  }

  function onEnded() {
    if (document.fullscreenElement) document.exitFullscreen();
    if (skipQuiz) {
      completeVideo();
      if (nextVideo) {
        goNextVideo();
      } else {
        showLastVideoOfLessonModal();
      }
    } else {
      if (data.has_quiz) {
        if (!hasShowQuiz) {
          showQuiz();
          setHasShowQuiz(true);
        }
      } else {
        completeVideo();
        if (nextVideo) {
          goNextVideo();
        } else {
          showLastVideoOfLessonModal();
        }
      }
    }
  }

  function toggleWideMode() {
    const value = !wideMode;
    setWideMode(value);
    if (value) {
      localStorage.setItem("wideMode", value);
    } else {
      localStorage.removeItem("wideMode");
    }
  }

  function goNextVideo() {
    history.push(`/study/video/${nextVideo.id}`);
    quizModal.hide();
    setForcePlaying(true);
    setForceReplay(0);
  }

  function watchVideoAgain() {
    quizModal.hide();
    setForceReplay((prev) => prev + 1);
    setForcePlaying(true);
  }

  function didPlayPause(wasPlaying, playing) {
    setWasPlaying(wasPlaying);
    setForcePlaying(playing);
  }

  return (
    <div className="container mx-auto px-6 2xl:px-12">
      <div className="py-6 space-y-4">
        <VideolNavigation />
      </div>

      <div className="flex">
        <div className="flex-grow">
          <VideoPlayer
            video={data}
            onEnded={onEnded}
            wideMode={wideMode}
            toggleWideMode={toggleWideMode}
            forcePlaying={forcePlaying}
            forceReplay={forceReplay}
            didPlayPause={didPlayPause}
            enableKeyboard={!hasModals}
          />

          <div className="flex mt-8 justify-between">
            <div className="flex-1">
              <div className="lg:flex justify-between bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm">
                <div className="space-y-4">
                  {data.has_quiz || nextVideo ? (
                    <div className="space-y-4 md:space-y-0 md:flex md:space-x-8">
                      {data.has_quiz ? (
                        <Button className="flex items-center space-x-2" rightIcon={<BsPencil size={17} />} onClick={showQuiz}>
                          Take the Quiz
                        </Button>
                      ) : null}

                      {nextVideo ? (
                        <Button className="flex items-center space-x-2" next to={`/study/video/${nextVideo.id}`}>
                          Next Video
                        </Button>
                      ) : null}
                    </div>
                  ) : null}

                  <div className="flex space-x-6">
                    <div className="text-yellow-500 text-xl cursor-pointer transition-all transform scale-100 hover:scale-150 duration-200">
                      {data.is_complete || hasMarkedCompleted ? (
                        <AiFillStar className="text-yellow-400" />
                      ) : (
                        <AiOutlineStar className="text-yellow-400" onClick={() => completeVideo()} title="Mark Complete" />
                      )}
                    </div>

                    <Button
                      className="flex items-center scale-100 hover:scale-150 transform-gpu duration-200 active:scale-125"
                      disabled={addFavourteRes.loading || removeFavourteRes.loading}
                      onClick={() => {
                        setIsFavourite((prev) => !prev);
                        if (isFavourite) {
                          removeFavourte();
                        } else {
                          addFavourte();
                        }
                      }}
                      rightIcon={isFavourite ? <AiFillHeart className="text-red-500 text-xl" /> : <AiOutlineHeart className="text-red-500 text-xl" />}
                    />

                    <Button
                      className="flex items-center space-x-2"
                      rightIcon={<IoIosSend size={18} />}
                      onClick={() => {
                        feedbackModal.present({
                          title: "Video Feedback",
                          center: true,
                          keepChildren: true,
                          children: <Feedback hide={feedbackModal.hide} itemType="video" itemId={id} data={{ title: data.name }} />,
                        });
                      }}
                    >
                      Video Feedback
                    </Button>
                  </div>
                </div>

                <div className="flex items-center space-x-2 mt-4 sm:justify-end lg:mt-0 lg:ml-8">
                  <label className="whitespace-nowrap">Skip Quiz</label>
                  <Switcher isOn={skipQuiz} onChange={() => setSkipQuiz((prev) => !prev)} />
                </div>
              </div>

              <div className="text-gray-500 mt-4 text-xs px-4">
                ☆ indicates that whether you complete videos or not. You could mark a video or lesson completed by clicking / tapping the star.
              </div>

              {localVideo.description ? (
                <div className="bg-white dark:bg-gray-800 rounded-2xl px-8 py-6 mt-6 leading-relaxed">{localVideo.description}</div>
              ) : null}

              <VideoComments videoId={id} onShowModal={() => setForcePlaying(false)} onHideModal={() => setForcePlaying(wasPlaying)} />
            </div>

            <div
              title="Theater Mode"
              className={`
              ${wideMode ? "hidden xl:block xl:ml-8" : "hidden"}
            `}
            >
              <LessonVideos label="Lesson Videos" lesson_id={data.lesson_id} video_id={id} />
            </div>
          </div>
        </div>

        <div
          className={`
            ml-8 w-96 flex-shrink-0
            ${wideMode ? "hidden" : "hidden xl:block"}
            `}
        >
          <LessonVideos label="Lesson Videos" lesson_id={data.lesson_id} video_id={id} />
        </div>
      </div>
    </div>
  );
};

export default StudyVideo;
