import Errors from "newComponents/Errors";
import Page from "newComponents/Page";
import Spinner from "newComponents/Spinner";
import { useEffect, useState } from "react";
import { useGet } from "Services";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  ComposedChart,
  Tooltip,
  Legend,
  Line,
  BarChart,
  Bar,
  Pie,
  PieChart,
  LabelList,
  Cell
} from "recharts";
import { Button } from "newComponents/base";
import { interpolateCool, interpolateWarm } from "d3-scale-chromatic";
import * as XLSX from "xlsx";
import { BsArrowDownCircle } from "react-icons/bs";

const AnalyticsPage = () => {
  const [startDate, setStartDate] = useState(moment().add(-30, "days").toDate());
  const [endDate, setEndDate] = useState(new Date());

  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showEndPicker, setShowEndPicker] = useState(false);
  const { loading, error, data } = useGet(
    `/reports/subscriptions?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(
      endDate
    ).format("YYYY-MM-DD")}`
  );

  useEffect(() => {
    localStorage.setItem("analytics:startDate", moment(startDate).format("YYYY-MM-DD"));
    localStorage.setItem("analytics:endDate", moment(endDate).format("YYYY-MM-DD"));
  }, [startDate, endDate]);

  const toYear = new Date().getFullYear();

  return (
    <Page>
      <div className="flex justify-between items-baseline">
        <h1 className="py-8 md:py-12">Analytics</h1>

        <div className="flex space-x-2">
          <div className="relative">
            <Button
              onClick={() => {
                setShowStartPicker(prev => !prev);
                setShowEndPicker(false);
              }}>
              {moment(startDate).format("YYYY-MM-DD")}
            </Button>
            {showStartPicker ? (
              <DayPicker
                className="absolute right-0 dark:bg-gray-900 dark:bg-opacity-70 backdrop-blur p-4 rounded-2xl z-20"
                mode="single"
                fromYear={2018}
                toYear={toYear}
                captionLayout="dropdown"
                selected={startDate}
                onSelect={d => {
                  setStartDate(d);
                  setShowStartPicker(false);
                }}
              />
            ) : null}
          </div>

          <span>~</span>

          <div className="relative">
            <Button
              onClick={() => {
                setShowEndPicker(prev => !prev);
                setShowStartPicker(false);
              }}>
              {moment(endDate).format("YYYY-MM-DD")}
            </Button>
            {showEndPicker ? (
              <div>
                <DayPicker
                  fromYear={2018}
                  toYear={toYear}
                  className="absolute right-0 dark:bg-gray-900 dark:bg-opacity-70 backdrop-blur p-4 rounded-2xl z-20"
                  mode="single"
                  captionLayout="dropdown"
                  selected={endDate}
                  onSelect={d => {
                    setEndDate(d);
                    setShowEndPicker(false);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div>
        {loading ? (
          <div className="p-24">
            <Spinner />
          </div>
        ) : error ? (
          <Errors error={error} />
        ) : data ? (
          <Charts data={data} />
        ) : null}
      </div>
    </Page>
  );
};

const getGroupName = plan => {
  let groupName = "Unknown Plans";
  if (plan === "de_professional" || plan === "de_ambassador") {
    groupName = "New Plans";
  } else if (plan.includes("de")) {
    groupName = "Old Plans";
  } else if (plan.includes("classroom_")) {
    groupName = "Classroom";
  } else if (plan === "open_one_year") {
    groupName = "Open 1 Year";
  }
  return groupName;
};

const Charts = ({ data }) => {
  const subscriptionData = Object.entries(
    data.find(i => i.name === "subscription_counts").data
  ).map(([key, value]) => ({ ...value, date: key }));
  const pageViewsData = Object.entries(data.find(i => i.name === "page_views").data).map(
    ([key, value]) => ({ ...value, date: key })
  );

  let allPlans = [];

  const transactions = Object.entries(data.find(i => i.name === "transactions").data).map(
    ([key, value]) => {
      let res = {};
      Object.entries(value).forEach(([k, v]) => {
        const groupName = getGroupName(k);
        if (!allPlans.includes(groupName)) allPlans.push(groupName);
        if (res[groupName]) res[groupName] += v;
        res[groupName] = v;
      });
      return { date: key, ...res };
    }
  );

  allPlans = allPlans
    .sort((a, b) => (a > b ? 1 : -1))
    .map((plan, index) => ({
      key: plan,
      title: plan,
      color: interpolateWarm((index + 1) / (allPlans.length + 1))
    }));

  const locations = data.find(i => i.name === "user_location").data;

  function exportXlxs(filename, data) {
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "stock");
    XLSX.writeFile(wb, filename + ".xlsx");
  }

  return (
    <div className=" space-y-8">
      <div className="p-6 rounded-2xl shadow-sm bg-white dark:bg-gray-800">
        <div className="flex items-center space-x-2 mb-4">
          <h4>Page Views</h4>
          <Button
            leftIcon={<BsArrowDownCircle />}
            onClick={() => exportXlxs("Page Views", pageViewsData)}></Button>
        </div>

        <ResponsiveContainer height={400}>
          <ComposedChart
            data={pageViewsData}
            margin={{
              top: 30,
              right: 30,
              bottom: 0,
              left: 0
            }}>
            <XAxis dataKey="date" />
            <YAxis yAxisId="1" />
            <YAxis yAxisId="2" orientation="right" domain={[0, 50]} />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const data = payload[0];
                  return (
                    <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-900 dark:bg-opacity-80">
                      <label htmlFor="">{label}</label>
                      <div className="text-xs mt-1 space-y-1">
                        <div>{`www: ${data.payload.www}`}</div>
                        <div>{`Study: ${data.payload.study}`}</div>
                        <div>{`classroom: ${data.payload.classroom}`}</div>
                        <div>{`Registrations: ${data.payload.registrations}`}</div>
                        <div>{`subscription: ${data.payload.subscription}`}</div>
                      </div>
                    </div>
                  );
                }

                return null;
              }}
            />
            <Legend />
            <Line
              yAxisId="1"
              dataKey="www"
              stroke={interpolateCool(0.3)}
              dot={false}
              type="monotone"
            />
            <Line
              yAxisId="1"
              dataKey="study"
              stroke={interpolateCool(0.6)}
              dot={false}
              type="monotone"
            />
            <Line
              yAxisId="1"
              dataKey="classroom"
              stroke={interpolateCool(0.9)}
              dot={false}
              type="monotone"
            />
            <Line
              yAxisId="2"
              dataKey="registrations"
              stroke={interpolateWarm(0.4)}
              dot={false}
              type="monotone"
            />
            <Line
              yAxisId="2"
              dataKey="subscription"
              stroke={interpolateWarm(0.8)}
              dot={false}
              type="monotone"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="p-6 rounded-2xl shadow-sm bg-white dark:bg-gray-800">
        <div className="flex items-center space-x-2 mb-4">
          <h4>Subscriptions</h4>
          <Button
            leftIcon={<BsArrowDownCircle />}
            onClick={() => exportXlxs("subscriptions", subscriptionData)}></Button>
        </div>

        <ResponsiveContainer height={400}>
          <ComposedChart
            data={subscriptionData}
            margin={{
              top: 30,
              right: 30,
              bottom: 0,
              left: 0
            }}>
            <XAxis dataKey="date" />
            <YAxis type="number" yAxisId="1" />
            <YAxis orientation="right" type="number" yAxisId="2" />
            <Tooltip content={CustomTooltip} />
            <Legend />
            <Line
              type="monotone"
              dataKey="active"
              stroke={interpolateWarm(0.3)}
              dot={false}
              yAxisId="1"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="created"
              stroke={interpolateWarm(0.6)}
              yAxisId="2"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="cancelled"
              stroke={interpolateWarm(0.9)}
              yAxisId="2"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div className="p-6 rounded-2xl shadow-sm bg-white dark:bg-gray-800">
        <div className="flex items-center space-x-2 mb-4">
          <h4>Transactions</h4>
          <Button
            leftIcon={<BsArrowDownCircle />}
            onClick={() => exportXlxs("Transactions", transactions)}></Button>
        </div>

        <ResponsiveContainer height={400}>
          <BarChart
            data={transactions}
            margin={{
              top: 30,
              right: 30,
              bottom: 0,
              left: 0
            }}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const data = payload[0];
                  return (
                    <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-900 dark:bg-opacity-80">
                      <label htmlFor="">{label}</label>
                      <div className="text-xs mt-1 space-y-1">
                        {allPlans.map((plan, index) => (
                          <div key={index}>{`${plan.title}: ${
                            data.payload[plan.key] ? data.payload[plan.key].toFixed(1) : "0"
                          }`}</div>
                        ))}
                      </div>
                    </div>
                  );
                }

                return null;
              }}
            />
            <Legend />
            {allPlans.map((plan, index) => (
              <Bar key={index} dataKey={plan.key} stackId="stackId" fill={plan.color} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="p-6 rounded-2xl shadow-sm bg-white dark:bg-gray-800">
        <div className="flex items-center space-x-2 mb-4">
          <h4>User Locations</h4>
          <Button
            leftIcon={<BsArrowDownCircle />}
            onClick={() => exportXlxs("User Locations", locations)}></Button>
        </div>

        <LocationCharts locations={locations} />
      </div>
    </div>
  );
};

const LocationCharts = ({ locations }) => {
  const knowLocations = locations.filter(i => i.country !== "Unknown");

  return (
    <div className="flex">
      <ResponsiveContainer height={400}>
        <PieChart>
          <Pie nameKey="country" dataKey="total" data={locations}>
            <LabelList dataKey="country" position="outside" offset={20} clockWise={0.1} />
            {locations.map((_, index) => (
              <Cell key={index} fill={interpolateWarm((index + 1) / (locations.length + 1))} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      <ResponsiveContainer height={400}>
        <PieChart>
          <Pie nameKey="country" dataKey="total" data={knowLocations}>
            <LabelList dataKey="country" position="outside" offset={20} />
            {knowLocations.map((_, index) => (
              <Cell key={index} fill={interpolateWarm((index + 1) / (knowLocations.length + 1))} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0];

    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-900 dark:bg-opacity-80">
        <label htmlFor="">{label}</label>
        <div className="text-xs mt-1 space-y-1">
          <div>{`Active Subscribers: ${data.payload.active}`}</div>
          <div>{`New Subscribers: ${data.payload.created}`}</div>
          <div>{`Cancelled Subscribers: ${data.payload.cancelled}`}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default AnalyticsPage;
