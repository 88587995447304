import Spinner from "newComponents/Spinner";
import { useGet } from "Services";

const Achievements = ({ bg = "bg-white dark:bg-gray-800", userAchievements = [] }) => {
  const { loading, data } = useGet(`/achievements`);

  if (loading) return <Spinner />;
  if (!data) return null;

  return (
    <div>
      <h4 className="px-2">Achievements</h4>
      <div className="flex flex-wrap text-xs mt-4">
        {data.map((a, index) => (
          <div
            key={index}
            className={`${bg} px-4 py-2 2xl:py-3 2xl:px-6 mr-4 mb-4 rounded-full
              ${userAchievements.includes(a.id) ? "" : "opacity-30"}
            `}>
            {a.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
