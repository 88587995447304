import { Button } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import { useState } from "react";
import { AiTwotoneHeart } from "react-icons/ai";
import { IoIosTrash } from "react-icons/io";
import { toast } from "react-toastify";
import { useGet, useDelete } from "Services";

const RemoveFavouriteButton = ({ id, onRemoved }) => {
  const [toggleFavourte, toggleFavourteRes] = useDelete(`/favourites/${id}`, {
    onComplete: () => {
      toast.success("Remove favourte.");
      onRemoved(id);
    },
    onError: error => {
      console.log(error);
      toast.error(error.message);
    }
  });

  return (
    <Button
      red
      onClick={() => toggleFavourte()}
      disabled={toggleFavourteRes.loading}
      loading={toggleFavourteRes.loading}>
      <IoIosTrash size={18} />
    </Button>
  );
};

const FavouriteVideos = () => {
  const { loading, data } = useGet("/favourites");
  const [justDeleted, setJustDeleted] = useState([]);

  function onRemoved(id) {
    setJustDeleted(prev => [...prev, id]);
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
      <div className="flex items-center space-x-4 text-pink-500">
        <AiTwotoneHeart size={27} />
        <h4>Favourite Videos</h4>
      </div>
      {loading ? (
        <Spinner />
      ) : data ? (
        <div className="mt-4 px-1">
          {data.filter(i => !justDeleted.includes(i.video_id)).length > 0 ? (
            data
              .filter(i => !justDeleted.includes(i.video_id))
              .map((i, index) => (
                <div
                  key={index}
                  className="border-b dark:border-gray-700 py-2 flex justify-between">
                  <Button className="font-normal" next to={`/study/video/${i.video_id}`}>
                    {i.video_name}
                  </Button>
                  <RemoveFavouriteButton id={i.video_id} onRemoved={onRemoved} />
                </div>
              ))
          ) : (
            <div className="text-gray-400">Your favourite videos will be list here.</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FavouriteVideos;
