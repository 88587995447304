import { ApiContext } from "contexts/ApiContext";
import Spinner from "newComponents/Spinner";
import { useContext } from "react";
import { BsFillBellFill } from "react-icons/bs";
import { timeSince } from "utils/date";

const NotificationList = () => {
  const { loadingNotifications, notifications } = useContext(ApiContext);

  if (loadingNotifications) return <Spinner center />;

  return (
    <div className="p-8">
      {notifications
        .filter(i => new Date(i.published_at) <= new Date())
        .map(i => (
          <NotificationItem key={i.id} notification={i} />
        ))}
    </div>
  );
};

const NotificationItem = ({ notification }) => {
  return (
    <div className="border-b dark:border-gray-700 mb-4 relative">
      {notification.read_at ? null : (
        <div className="absolute -left-7 w-2 h-2 rounded-full bg-red-500 ml-2 top-3"></div>
      )}
      <h4>{notification.title}</h4>
      <div className="text-gray-500">{timeSince(new Date(notification.published_at))} ago</div>
      <div
        className="my-4 text-gray-700 dark:text-gray-400 text-sm"
        dangerouslySetInnerHTML={{ __html: notification.body }}
      />
    </div>
  );
};

const Notifications = ({ show, hide }) => {
  return (
    <div
      className={`fixed inset-0 z-50 shadow-xl overflow-hidden
        ${show ? "visible" : "invisible"}
        `}
      style={{ transition: "all 0.5s ease" }}>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50
            ${show ? "visible opacity-100" : "invisible opacity-0"}
            `}
        onClick={hide}
        style={{ transition: "all 0.5s ease" }}></div>
      <div
        className={`fixed top-0 bottom-0 w-100 overflow-auto flex flex-col
        ${show ? "right-0 visible" : "-right-100 invisible"}
      `}
        style={{ transition: "all 0.3s cubic-bezier(0.35, 1.2, 0.64, 1) 0s" }}>
        <div className="flex justify-between items-center sticky top-0 px-8 py-4 bg-opacity-75 backdrop-filter backdrop-blur-lg dark:bg-opacity-90 bg-white dark:bg-gray-800 border-b dark:border-gray-700 z-40">
          <div className="flex py-2 items-center space-x-2">
            <BsFillBellFill size={27} />
            <h2>Notifications</h2>
          </div>
          <button
            className="outline-none focus:outline-none text-3xl text-gray-200 dark:text-gray-400 hover:text-gray-900"
            onClick={hide}>
            &times;
          </button>
        </div>

        <div className="bg-white dark:bg-gray-800 break-words flex-1">
          <NotificationList />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
