import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";

const AnswerResult = ({ result }) => (
  <div
    className={`font-semibold
        ${result === "PASS" ? "text-green-500" : "text-red-500"}
        `}>
    <div className="flex items-center space-x-4"></div>
    {result === "PASS" ? (
      <div className="flex items-center space-x-4 text-3xl sm:text-5xl">
        <IoIosCheckmarkCircle />
        <div className="text-2xl sm:text-3xl">CORRECT</div>
      </div>
    ) : (
      <div className="flex items-center space-x-4 text-3xl sm:text-5xl">
        <IoIosCloseCircle />
        <div className="text-2xl sm:text-3xl">WRONG</div>
      </div>
    )}
  </div>
);

export default AnswerResult;
