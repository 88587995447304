import ReactDOM from "react-dom";
import App from "./App";
import { ApiProvider } from "./contexts/ApiContext";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import SentryFallbackComponent from "pages/SentryFallbackComponent";
import buildNumber from "buildNumber";
import { devLog } from "./helpers";
import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Router } from "react-router-dom";
import history from "newComponents/history";

devLog(
  "log",
  "%cBooting App",
  "color: #fff; font-weight: bold; background: #3490dc; padding: 2px 4px; border-radius: 2px;",
  `domino-frontend@${process.env.REACT_APP_VERSION} [${process.env.NODE_ENV}]`
);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://07988d84a3014a4ab4e668a63b17b7aa@o213124.ingest.sentry.io/5707724",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: `website@${process.env.REACT_APP_VERSION}.${buildNumber}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "timeout of 0ms exceeded",
      "Network Error",
      "Request aborted",
      "Ke.a.request is not a function.*"
    ],
    blacklistUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i
    ]
  });
}

ReactDOM.render(
  <ApiProvider>
    <Sentry.ErrorBoundary fallback={<SentryFallbackComponent />}>
      <Router history={history}>
        <App />
      </Router>
    </Sentry.ErrorBoundary>
  </ApiProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
