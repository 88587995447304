import { useEffect, useState } from "react";
import SearchBar from "newComponents/SearchBar";
import { useDebouncedCallback } from "use-debounce";
import { http } from "Services";
import axios from "axios";
import Spinner from "newComponents/Spinner";
import ResultItem from "./ResultItem";
import SimpleVideo from "newComponents/SimpleVideo";
import { useModals } from "contexts/ModalProvider";

const FILTERS_OPTIONS = [
  { title: "English", slug: "definition" },
  { title: "Pinyin", slug: "pinyin" },
  { title: "Chinese", slug: "text" }
];

const DictionaryPage = () => {
  const [searchText, setSearchText] = useState("");
  const [filterLang, setFilerLang] = useState(
    localStorage.getItem("dictionary:filter") || "definition"
  );
  const [loading, setLoading] = useState(false);
  const debounced = useDebouncedCallback(search, 800);
  const [searched, setSearched] = useState(false);

  const CancelToken = axios.CancelToken;
  const [source, setSource] = useState(null);

  const { present } = useModals();

  useEffect(() => {
    if (searchText) search(searchText);
    localStorage.setItem("dictionary:filter", filterLang);
  }, [filterLang]);

  async function search(query) {
    setLoading(true);
    try {
      const newSource = CancelToken.source();
      setSource(newSource);
      const res = await http.get(`/dictionary?query=${query}&type=${filterLang}&page=1`, {
        cancelToken: newSource.token
      });
      setResults(res.data.data);
    } catch (error) {}
    setLoading(false);
    setSearched(true);
  }

  const [results, setResults] = useState([]);

  return (
    <div className="container mx-auto p-6 max-w-6xl">
      <div className="md:flex justify-between items-end">
        <div>
          <h1>Domino Dictionary</h1>
          <div className="mt-4 text-gray-500">
            Enter a word in English, Chinese or Pinyin, see translation and links to videos.
          </div>
        </div>
        <div className="mt-6">
          <div className="flex space-x-4 justify-end">
            {FILTERS_OPTIONS.map((i, index) => (
              <div
                key={index}
                className={`px-6 py-2 bg-white dark:bg-gray-800 border dark:border-gray-700 rounded-full cursor-pointer hover:bg-sky-400 dark:hover:bg-sky-600 active:bg-sky-500 dark:active:bg-sky-700 active:text-white transition-all
              ${i.slug === filterLang ? "bg-sky-500 text-white dark:bg-sky-600" : ""}
            `}
                onClick={() => setFilerLang(i.slug)}>
                {i.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-6">
        <SearchBar
          autoFocus
          value={searchText}
          onChange={text => {
            setSearchText(text);
            setSearched(false);
            setResults([]);
            if (source) source.cancel();
            if (text) debounced.callback(text);
          }}
          placeholder="Search"
        />
      </div>

      {loading ? (
        <Spinner />
      ) : results.length > 0 ? (
        <div>
          {results.map(word => (
            <div
              key={word.id}
              className="bg-white dark:bg-gray-800 p-6 rounded-2xl mb-6 shadow-sm transition-all duration-200 hover:shadow-lg">
              <ResultItem
                word={word}
                showSource={true}
                videosInline={false}
                showSpinner={false}
                watchVideo={video =>
                  present({ maxWidth: "max-w-6xl", children: <SimpleVideo video={video} /> })
                }
              />
            </div>
          ))}
        </div>
      ) : searched ? (
        <div className="text-gray-500">No results found.</div>
      ) : null}
    </div>
  );
};

export default DictionaryPage;
