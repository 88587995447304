import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { http, useGet } from "Services";
import { useState, useRef, useEffect, useContext } from "react";
import { VscRefresh } from "react-icons/vsc";
import { Button, NavigationBar } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import QuizChoiceQuestion from "./Choice";
import QuizTextQuestion from "./Text";
import QuizSentenceQuestion from "./Sentence";
import AnswerResult from "./AnswerResult";
import { shuffleArray, shuffledArray } from "utils/array";
import { PassLessonResult, PassLevelResult } from "./PassMultipleQuiz";
import { ApiContext } from "contexts/ApiContext";
import { toABC } from "utils/string";
import { correctAudio, errorAudio } from "./VideoQuiz";
import { LessonReviewContext } from "contexts/LessonReviewProvider";
import { useModals } from "contexts/ModalProvider";
import { SimpleVideoById } from "newComponents/SimpleVideo";
import { FaPlay } from "react-icons/fa";

const MultipleQuiz = () => {
  const { completedItems, setApiState, lastLocation, db } = useContext(ApiContext);
  const { refresh } = useContext(LessonReviewContext);
  const { type, id } = useParams();
  const { loading, data } = useGet(`frontend/${type}/${id}/quiz`);
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);
  const slider = useRef();

  let title = "Level";
  let modalCenter = true;
  if (type === "lesson") {
    const lesson = db.lessons.find(i => i.id === id);
    title = `Lesson ${toABC(lesson.ordering)}`;
    modalCenter = lesson.chapter_id !== "7bf8afe2-16b2-4820-8260-85311aba8e7a";
  } else if (type === "level") {
    const level = db.levels.find(i => i.id === id);
    const course = db.courses.find(i => i.id === level.course_id);
    title = `${course.name} ${level.name}`;
  }

  useEffect(() => {
    if (data) {
      let questions = [...data.questions];
      if (process.env.REACT_APP_STAGE !== "production") questions = questions.slice(1, 4 + 1);
      shuffleArray(questions);
      setQuestions(questions);
      setResults([]);
      setCurrentIndex(0);
    }
  }, [data]);

  useEffect(() => {
    function spaceHandler(e) {
      if (e.code === "Space") {
        if (results[currentIndex]) {
          e.preventDefault();
          goNextQuestion();
        }
      }
    }

    document.addEventListener("keydown", spaceHandler);

    return () => {
      document.removeEventListener("keydown", spaceHandler);
    };
  }, [results, currentIndex]);

  const videoModal = useModals();
  const resultModal = useModals();

  if (loading) return <Spinner center />;
  if (!data) return null;

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    easing: "cubic-bezier(0.25, 0.1, 0.25, 1)",
    dots: false,
    adaptiveHeight: true,
    infinite: false,
    draggable: false,
    swipe: false,
    className: "focus:outline-none"
  };

  const correctCount = results.filter(i => i.result === "PASS").length;

  function showFinalResult(result = "PASS", results = []) {
    resultModal.present({
      center: modalCenter,
      isBeingPresented: true,
      children: (
        <FinalResult
          results={results}
          finalResult={result}
          id={id}
          type={type}
          tryAgain={tryAgain}
        />
      )
    });
  }

  function hideFinalResult() {
    resultModal.hide();
  }

  function didAnswer(quiz, result) {
    const newResults = [...results, { quiz, result }];
    setResults(newResults);

    if (!localStorage.getItem("disableQuizSound"))
      result === "PASS" ? correctAudio.play() : errorAudio.play();

    const inCorrectCount = newResults.filter(i => i.result === "FAIL").length;
    if (parseFloat(inCorrectCount) / parseFloat(questions.length) > 0.25) {
      showFinalResult("FAIL", newResults);
      http.post(`/frontend/${type}/${id}/quiz`, { status: "FAIL" });
    } else if (currentIndex + 1 === questions.length) {
      showFinalResult("PASS");
      http.post(`/frontend/${type}/${id}/quiz`, { status: "PASS" }).then(refresh);
      if (completedItems) {
        let payload = {};
        if (type === "lesson") {
          const lessonVideoIds = db.getLessonVideos(id).map(i => i.id);
          payload["lessonIds"] = completedItems.lessonIds
            ? [...completedItems.lessonIds, id]
            : [id];
          payload["videoIds"] = completedItems.videoIds
            ? [...completedItems.videoIds, ...lessonVideoIds]
            : lessonVideoIds;
        } else if (type === "level") {
          payload["levelIds"] = completedItems.levelIds ? [...completedItems.levelIds, id] : [id];
        }
        setApiState("completedItems", { ...completedItems, ...payload });
      }
    }
  }

  const quizResult = quizId => results.find(i => i.quiz.id === quizId);

  function goNextQuestion() {
    slider.current.slickNext();
  }

  function tryAgain() {
    hideFinalResult();
    setResults([]);
    setCurrentIndex(0);
    setQuestions(shuffledArray(questions));
    slider.current.slickGoTo(0, true);
  }

  return (
    <div>
      <div className="container mx-auto px-6">
        <NavigationBar
          backTo={lastLocation && lastLocation.from ? lastLocation.from.pathname : "/study"}
        />
      </div>

      <div>
        <h1 className="capitalize text-center sm:my-12">{title} Quiz</h1>

        <div className="flex justify-center">
          <Button onClick={() => showFinalResult("PASS")}>Test Pass</Button>
        </div>

        <div
          className={`max-w-3xl mx-auto my-6 px-6
          ${results.length > 0 ? "" : "invisible"}
        `}>
          <div className={`h-2 relative`}>
            <div
              className="absolute h-full rounded-full animate-ease-3 bg-lime-500 dark:bg-lime-600"
              style={{
                width: `${(parseFloat(correctCount) * 100) / parseFloat(questions.length)}%`
              }}></div>
            <div className="h-full rounded-full w-full bg-gray-300 dark:bg-gray-600"></div>
          </div>
        </div>

        {questions.length > 0 ? (
          <Slider
            ref={slider}
            {...settings}
            beforeChange={(_, newIndex) => setCurrentIndex(newIndex)}>
            {questions.map((quiz, index) => (
              <div key={index} className="px-6">
                <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-3xl shadow-sm overflow-hidden">
                  <div className="p-6 sm:p-10">
                    <div className="mb-6 text-xl lg:text-2xl 2xl:lg:text-4xl">{quiz.text}</div>
                    {quiz.type === "text" ? (
                      <QuizTextQuestion
                        result={results[index]}
                        answers={quiz.answers}
                        didAnswer={result => didAnswer(quiz, result)}
                      />
                    ) : quiz.type === "choice" ? (
                      <QuizChoiceQuestion
                        result={results[index]}
                        answers={quiz.answers}
                        didAnswer={result => didAnswer(quiz, result)}
                      />
                    ) : (
                      <QuizSentenceQuestion
                        result={results[index]}
                        correctAnswer={quiz.answers[0].text}
                        didAnswer={result => didAnswer(quiz, result)}
                      />
                    )}
                  </div>

                  {quizResult(quiz.id) ? (
                    <QuizResult
                      quiz={quiz}
                      results={results}
                      result={quizResult(quiz.id)}
                      goNextQuestion={currentIndex + 1 < questions.length ? goNextQuestion : null}
                      watchVideo={() =>
                        videoModal.present({
                          maxWidth: "max-w-6xl",
                          children: <SimpleVideoById id={quiz.video_id} />
                        })
                      }
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </Slider>
        ) : null}

        <div className="mt-8 flex justify-center text-gray-400">
          Get at least 75% right to pass the quiz.
        </div>
        <div className="text-center mt-4 text-xs text-gray-400">Press space bar to next quiz.</div>
      </div>
    </div>
  );
};

const FinalResult = ({ results, type, id, finalResult, tryAgain }) => {
  const { db } = useContext(ApiContext);

  const videoModal = useModals();

  return (
    <div className="py-8">
      {finalResult === "PASS" ? (
        type === "lesson" ? (
          <PassLessonResult id={id} />
        ) : (
          <PassLevelResult id={id} />
        )
      ) : (
        <div className="text-center">
          <h1>OOPS!</h1>
          <div className="mt-8 text-2xl text-center leading-relaxed">
            Too many incorrect answers, have another go! 😄
          </div>

          <div className="py-8">
            <div>
              Make sure you check out the following video(s) to refresh your knowledge on what you
              got wrong.
            </div>
            <div className="mt-4 space-y-3">
              {results.map(({ quiz }) => {
                const video = db.videos.find(i => i.id === quiz.video_id);
                return (
                  <div key={quiz.id} className="flex justify-center items-center space-x-4">
                    <div>
                      <label htmlFor="">Question: </label>
                      {quiz.text}
                    </div>
                    <div className="flex justify-center">
                      <Button
                        rightIcon={<FaPlay />}
                        onClick={() =>
                          videoModal.present({
                            maxWidth: "max-w-6xl",
                            children: <SimpleVideoById id={quiz.video_id} />
                          })
                        }>
                        {video.name}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center justify-center space-x-8 mt-12 text-lg">
            <Button to="/study" back>
              Back to Lesson Overview
            </Button>
            <Button rightIcon={<VscRefresh size={21} />} onClick={tryAgain}>
              Try Again
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const QuizResult = ({ result, goNextQuestion, watchVideo }) => {
  const correct = result.result === "PASS";
  return (
    <div
      className={`p-6 sm:p-10
          ${correct ? "bg-green-200" : "bg-red-100"}
          `}>
      <div className="flex justify-between">
        <AnswerResult result={result.result} />

        {goNextQuestion ? (
          <div className="flex space-x-6 items-center justify-center">
            <Button
              className="text-2xl font-bold"
              next
              green={correct}
              red={!correct}
              onClick={goNextQuestion}>
              Next
            </Button>
          </div>
        ) : null}
      </div>

      {correct ? null : (
        <Button className="text-sm mt-2 ml-2" onClick={watchVideo}>
          Check out this video if you want to learn why.
        </Button>
      )}
    </div>
  );
};

export default MultipleQuiz;
