import { http } from "Services";

export function track(type, description, data = {}) {
  http
    .post("/aa/", {
      log_name: type,
      description,
      properties: { ...data, version: process.env.REACT_APP_VERSION }
    })
    .catch(error => {
      console.log("error", error);
    });
}
