import { ApiContext } from "contexts/ApiContext";
import { Button, Input } from "newComponents/base";
import { useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import { usePatch } from "Services";

const UserFullName = () => {
  const { user, setApiState } = useContext(ApiContext);

  const [editing, setEditing] = useState(false);

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  const [updateProfile, updateProfileRes] = usePatch(`/profile/${user.id}`, {
    onComplete: () => {
      toast.success("Updated.");
      setEditing(false);
      setApiState("user", {
        ...user,
        first_name: firstName,
        last_name: lastName,
        full_name: `${firstName} ${lastName}`
      });
    },
    onError: error => toast.error(error.message)
  });

  return (
    <div>
      {editing ? (
        <div className="pb-6 pt-8 mb-6 border-t border-b dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <label htmlFor="">First Name: </label>
            <Input value={firstName} onChange={e => setFirstName(e.target.value)} />
          </div>

          <div className="flex items-center space-x-2 mt-3">
            <label htmlFor="">Last Name: </label>
            <Input value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>

          <div className="flex justify-center space-x-4 mt-6">
            <Button
              onClick={() =>
                updateProfile({
                  first_name: firstName,
                  last_name: lastName,
                  locale: user.locale,
                  video_quality: user.video_quality
                })
              }
              loading={updateProfileRes.loading}
              disabled={updateProfileRes.loading}>
              Save
            </Button>
            <Button gray onClick={() => setEditing(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <h3 className="text-center relative editable-fullname cursor-pointer">
            {user.full_name}
            <FiEdit
              className="absolute cursor-pointer hover:text-blue-500 left-full top-1 transform ml-2 edit-pencil"
              style={{ transform: "translateY(2px)" }}
              size={21}
              onClick={() => setEditing(true)}
            />
          </h3>
        </div>
      )}
    </div>
  );
};

export default UserFullName;
