import Errors from "newComponents/Errors";
import { useGet } from "Services";
import moment from "moment";
import { BiChevronRight } from "react-icons/bi";
import Spinner from "newComponents/Spinner";
import dominoClassroomImage from "assets/images/domino-classroom-1.png";
import { BsFillBarChartFill } from "react-icons/bs";
import SimpleVideoPlayer from "newComponents/SimpleVideoPlayer";

const Classrooms = () => {
  const { loading, error, data } = useGet("/landing/classroom");

  if (loading)
    return (
      <div className="relative flex-1">
        <Spinner center />
      </div>
    );
  if (error) return <Errors error={error} />;
  if (!data) return null;

  const description = `Domino Classroom is a Digital Online Classroom with Classmates and Teachers that can take you all the way from beginner to absolute master of Chinese.

You get full access to our Domino Platform with 1000's of videos, quizzes, excercises and flashcards along with your own teacher and up to 3 live classes every week.

Domino Classroom is perfect for those who are serious about learning Chinese fast and is tailored for people who have limited time to spend in class. Below you can see our active and upcomming classes - if you feel unsusre about what class would suit you do not hesitate: Set up a meeting with us and we will guide you right.`;

  return (
    <div>
      <div
        className="bg-no-repeat bg-cover bg-top"
        style={{
          backgroundImage: `url(https://materials.dominochinese.com/classroom_static/header.jpg)`
        }}>
        <div className="w-full h-full flex flex-col items-center justify-center backdrop-filter backdrop-blur-2xl bg-opacity-30 dark:bg-opacity-70 bg-gray-900 py-32 px-6">
          <div className="text-white text-7xl font-semibold filter drop-shadow">
            Domino Classroom
          </div>
          <div className="mt-10 max-w-2xl text-center text-gray-200 filter drop-shadow text-lg">
            Join our Chinese teachers and your classmates in an interactive 3-month online classroom
            that will put you on the path to Chinese language fluency.
          </div>
          <div className="rounded-2xl overflow-hidden max-w-screen-lg mt-12 relative w-full shadow">
            <div className="w-full" style={{ paddingBottom: "56.25%" }}></div>
            <div className="absolute inset-0 z-10">
              <SimpleVideoPlayer
                src="https://materials.dominochinese.com/classroom_static/Domino_Classroom_Walkthrough_1.0_Final.m4v"
                thumb="https://materials.dominochinese.com/classroom_static/Domino_Classroom_Walkthrough.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-12 container mx-auto px-6 xl:px-20 pt-20">
        {/* <div className="rounded-3xl overflow-auto">
          <video className="w-full" controls>
            <source src="https://materials.dominochinese.com/classroom_static/Domino_Classroom_Walkthrough_1.0_Final.m4v" />
          </video>
        </div> */}

        <div className="rounded-3xl overflow-auto grid grid-cols-3 items-stretch bg-white dark:bg-gray-800 shadow-sm">
          <div className="whitespace-pre-wrap p-8 col-span-2 mr-4">
            <h1>Classroom Intro</h1>
            <div className="mt-6">{description}</div>
          </div>
          <div
            className="shadow-sm rounded-3xl h-full col-span-1 bg-cover bg-center"
            style={{
              backgroundImage: `url(${dominoClassroomImage})`
            }}></div>
        </div>

        <div className="grid grid-cols-3">
          {data.next_beginner_class ? (
            <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-sm col-span-2 p-8 mr-12">
              <div className="bg-white dark:bg-gray-800 rounded-3xl">
                <ClassView item={data.next_beginner_class} title="Next Beginner Class" />
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-sm col-span-2 p-8 mr-12">
              <div className="bg-white dark:bg-gray-800 rounded-3xl">
                <h2 className="mb-6">Next Beginner Class</h2>
                <div>Comming Soon.</div>
              </div>
            </div>
          )}

          <a
            className="text-white hover:text-white bg-blue-500 hover:bg-blue-400 dark:bg-blue-900 dark:hover:bg-blue-800 rounded-3xl overflow-auto shadow-sm transform-gpu bounce scale-100 hover:scale-105 cursor-pointer col-span-1 flex flex-1 justify-center items-center"
            style={{
              backgroundImage: "url(https://materials.dominochinese.com/classroom_static/study.jpg)"
            }}
            href="https://app.greminders.com/c/felixlattman/"
            target="_blank"
            rel="noreferrer">
            <div className="flex flex-col space-y-3 w-full h-full bg-gray-900 bg-opacity-50 items-center justify-center py-24">
              <BsFillBarChartFill size={54} />
              <span className="inline-block text-2xl mt-3">
                Find my Level from Felix&apos;s schedule .
              </span>
            </div>
          </a>
        </div>

        <div>
          <div className="uppercase text-2xl opacity-70">Upcoming Classes</div>
          <div className="mt-10 grid grid-cols-2 gap-10">
            {data.upcoming_classes.map((i, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 p-8 rounded-3xl shadow-sm">
                <ClassView item={i} />
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="uppercase text-2xl opacity-70">Ongoing Classes</div>
          <div className="mt-10 grid grid-cols-2 gap-10">
            {data.ongoing_classes.map((i, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 p-8 rounded-3xl shadow-sm">
                <ClassView item={i} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function getWeekDaysByWeekNumber(weeknumber) {
  let date = moment().startOf("isoWeek").day(weeknumber);
  return date.toDate();
}

const ClassView = ({ item, title }) => {
  const [hours, minites, seconds] = item.lesson_start_time.split(":");
  let date = getWeekDaysByWeekNumber(item.lesson_day_of_week);
  date.setUTCFullYear(date.getFullYear());
  date.setUTCMonth(date.getMonth());
  date.setUTCDate(date.getDate());
  date.setUTCHours(hours);
  date.setUTCMinutes(minites);
  date.setUTCSeconds(seconds);

  const mainClassTime =
    moment.utc(date).format("dddd") + "s " + moment.utc(date).format("hh:mm:ss");

  return (
    <div className="flex flex-col justify-between h-full space-y-6">
      <div>
        {title ? (
          <h2 className="mb-6">{title}</h2>
        ) : (
          <div className="font-semibold text-2xl">{item.level_name}</div>
        )}

        <div
          className="w-20 h-20 bg-no-repeat bg-cover rounded-full p-2 border dark:border-gray-700 float-right ml-4 mb-4"
          style={{ backgroundImage: `url(${item.teacher_avatar})` }}></div>

        <div className="mt-4">
          <label htmlFor="">Teacher: </label>
          <span className="pl-2">{item.teacher_name}</span>
        </div>

        <div className="mt-2 opacity-70">{item.description}</div>

        <div className="mt-4">
          <label htmlFor="">Orientation: </label>
          {moment.utc(item.start_date).local().format("YYYY-MM-DD HH:mm:ss")}
        </div>

        <div className="mt-2">
          <label htmlFor="">Main Class Time: </label> {mainClassTime}
        </div>
      </div>

      <div className="mt-4 flex">
        <a
          className="text-xl font-semibold flex items-center space-x-1"
          href={item.monthly_payment_url}
          target="_blank"
          rel="noreferrer">
          <span>Enroll</span>
          <BiChevronRight size={34} />
        </a>
      </div>
    </div>
  );
};

export default Classrooms;
