import { Button, PrimaryLink } from "newComponents/base";
import { TiArrowBackOutline } from "react-icons/ti";
import { getScoreTextColor } from "./colors";

const FlashcardResults = ({ result }) => {
  return (
    <div className="px-6 sm:px-10 pt-12 sm:pt-16 pb-12 text-center">
      <h1 className="">Nice Work! 🎉🎉🎉</h1>
      <div className="py-16 space-y-10 text-lg">
        <div className="flex justify-center">
          <div
            className={`font-serif italic w-52 sm:w-60 h-52 sm:h-60 flex justify-center items-center text-7xl sm:text-9xl rounded-full text-white ${getScoreTextColor(
              result.score
            )}`}>
            {result.score}
          </div>
        </div>
        <div>Score for this lesson review.</div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center  space-y-6 md:space-y-0 md:space-x-16">
        <Button to="/review" leftIcon={<TiArrowBackOutline />}>
          Back to Review
        </Button>

        <PrimaryLink next to="/">
          Go to Dashboard
        </PrimaryLink>
      </div>
    </div>
  );
};

export default FlashcardResults;
