import { ApiContext } from "contexts/ApiContext";
import { useContext, useEffect, useState } from "react";

const Modal = ({
  className = "",
  title = null,
  subtitle = null,
  titleClassName = "",
  maxWidth = "max-w-4xl",
  show = false,
  onHide,
  isBeingPresented = false,
  children,
  center = true,
  hideCloseButton
}) => {
  const [inited, setInited] = useState(false);
  const { setModals } = useContext(ApiContext);

  useEffect(() => {
    setInited(true);
  });

  useEffect(() => {
    if (!inited) return;

    setModals(prev => {
      if (show) {
        return prev + 1;
      } else {
        if (prev > 1) return prev - 1;
        return 0;
      }
    });
  }, [show]);

  return (
    <div
      className={`
        fixed inset-0 z-50 animate-ease-5 backdrop-filter backdrop-blur-xl bg-gray-300 dark:bg-gray-900 bg-opacity-90 dark:bg-opacity-80
        ${show ? "opacity-100 visiable" : "opacity-0 invisible"}
        ${className}
        `}>
      <div
        className={`w-full h-full overflow-auto flex flex-col lg:items-center
            ${center ? "lg:justify-center" : ""}
            `}>
        <div
          className={`w-full flex flex-col relative animate-cuber-5
            ${center ? "flex-1 sm:flex-none sm:overflow-auto" : "flex-1"}
            ${
              show
                ? `opacity-1 mt-12 sm:mt-24 ${center ? "lg:-mt-10" : ""}`
                : `opacity-0 mt-24 sm:mt-36 ${center ? "lg:mt-0" : ""}`
            }
            `}
          style={{
            transition: "margin 0.4s cubic-bezier(0.3, 2.1, 0.55, 1) 0s, opacity 0.5s ease"
          }}>
          {isBeingPresented ? null : (
            <div className={`${center ? "fixed" : "absolute"} inset-0`} onClick={onHide}></div>
          )}
          <div
            className={`
              relative mx-auto w-full bg-gray-50 dark:bg-gray-800 ${maxWidth}
              ${center ? "rounded-2xl lg:rounded-3xl" : "rounded-t-2xl lg:rounded-t-3xl"}
              ${center ? "flex-1 sm:flex-none sm:overflow-auto" : "flex-1"}
              ${center ? "" : "pb-10"}

            `}>
            {hideCloseButton || !onHide ? null : (
              <div
                className="absolute top-0 right-0 px-6 py-4 z-40 opacity-60 cursor-pointer hover:opacity-100 text-4xl"
                onClick={onHide}>
                &times;
              </div>
            )}

            {title ? (
              <div className="py-6 sm:py-9 pl-6 pr-20">
                <h1 className={`sm:px-4 ${titleClassName}`}>{title}</h1>
                {subtitle ? <div className="sm:px-4 mt-2 text-lg">{subtitle}</div> : null}
              </div>
            ) : null}

            <div className="relative" style={{ minHeight: 200 }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
