import { Route, Switch } from "react-router-dom";
import ReviewLesson from "./ReviewLesson";
import Dashboard from "./Dashboard";
import UpcomingReviews from "./UpComingReviews";
import LessonSummaries from "./LessonSummaries";

const ReviewPage = () => {
  return (
    <Switch>
      <Route path="/review/lesson/:id" component={ReviewLesson} />
      <Route path="/review/upcoming" component={UpcomingReviews} />
      <Route path="/review/lesson-summaries" component={LessonSummaries} />
      <Route component={Dashboard} />
    </Switch>
  );
};

export default ReviewPage;
