import { Trans } from "@lingui/macro";
import { RiErrorWarningFill } from "react-icons/ri";
import { Button } from "newComponents/base";
import { AiOutlineReload } from "react-icons/ai";

const SentryFallbackComponent = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-red-500 flex flex-col items-center justify-between">
        <RiErrorWarningFill className="text-8xl" />
        <div className="mt-6 text-lg space-y-2 text-center">
          <div>
            <Trans>We&apos;re really sorry, it looks like the app crashed.</Trans>
          </div>
          <div>
            <Trans>We are notified and will fix it asap.</Trans>
          </div>
        </div>

        <Button className="mt-6 text-xl font-bold" onClick={() => window.location.reload()}>
          <div className="flex space-x-3 items-center ">
            <div>
              Try <Trans>Reload</Trans>
            </div>
            <AiOutlineReload />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SentryFallbackComponent;
