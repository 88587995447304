import { ApiContext } from "contexts/ApiContext";
import { useContext } from "react";

const Streak = () => {
  const { user, showStreak, setShowStreak } = useContext(ApiContext);

  if (!user) return null;

  return (
    <div
      className={`fixed inset-0 z-30 transition-all duration-300
        ${showStreak ? "visible opacity-100" : "invisible opacity-0"}
        `}>
      <div className="fixed inset-0" onClick={() => setShowStreak(false)}></div>
      <div
        className={`fixed right-6 py-4 px-12 text-center rounded-xl whitespace-nowrap text-white bg-amber-500 bg-opacity-80 backdrop-blur backdrop-filter z-30 bounce italic
        ${
          showStreak
            ? "top-20 visiable opacity-100 scale-100"
            : "top-16 invisible opacity-0 scale-90"
        }
      `}>
        <h2>Streak {user.current_streak} days</h2>
        <div>Study every day to keep your streak going! </div>
        {user.current_streak !== user.longest_streak ? (
          <>
            <div className="mt-2">
              <div className="text-lg font-semibold">Current: {user.current_streak} days</div>
            </div>
            <div>
              <div className="text-lg font-semibold">Longest: {user.longest_streak} days</div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Streak;
