import { Link } from "react-router-dom";
import { Button } from "newComponents/base";

const Sentence = ({ sentence, selectedWord, wordTarget = "_self" }) => (
  <div className="border-b py-3 dark:border-gray-700 md:flex justify-between items-end">
    <div>
      <div>{sentence.translation}</div>
      <div className="flex flex-wrap text-xl mt-2 space-x-1">
        {sentence.words.map((j, jIndex) => (
          <Link
            className={`whitespace-nowrap hover:underline cursor-pointer text-gray-700 dark:text-gray-300
                ${selectedWord.id === j.id ? "text-green-500" : ""}`}
            key={jIndex}
            target={wordTarget}
            to={`/dictionary/${j.id}`}>
            {j.text}
          </Link>
        ))}
      </div>
    </div>
    <div className="flex-shrink-0 mt-4 md:mt-0">
      <Button className="text-base" next to={`/dialogs/${sentence.dialog_id}`} target="_blank">
        View Dialog
      </Button>
    </div>
  </div>
);

export default Sentence;
