import { IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";

const VideoVolumeControls = ({ handleClick, muted, setVolume, volume }) => (
  <div className={`md:flex items-center hidden ml-2`} style={{ maxWidth: 100 }}>
    <span className="mr-2 cursor-pointer" onClick={handleClick}>
      {/* <Icon icon={muted ? "muted" : "volume"} size="16" /> */}
      {muted ? <IoIosVolumeOff size={21} /> : <IoIosVolumeHigh size={21} />}
    </span>

    <input
      className="video-range w-full"
      type="range"
      min={0}
      max={1}
      step="any"
      value={volume}
      onChange={e => setVolume(e.target.value)}
    />
  </div>
);

export default VideoVolumeControls;
