import { Trans } from "@lingui/macro";

export const hideBeamer = () => {
  let hideBeamer = setInterval(() => {
    if (document.getElementById("beamerSelector")) {
      clearInterval(hideBeamer);
      document.getElementById("beamerSelector").style.display = "none";
    }
  }, 250);
};

export const parseLineChart = ({ labels, datasets }) => {
  return {
    datasets: datasets.map(({ label, data }) => ({
      backgroundColor: "rgb(52, 144, 220, .1)",
      borderColor: "rgb(52, 144, 220, .3)",
      fill: true,
      label,
      data,
      lineTension: 0,
      pointBackgroundColor: "rgb(52, 144, 220, .3)"
    })),
    labels
  };
};

export const toneColors = ["gray", "yellow", "green", "blue", "red"];

export const getDomainName = str => {
  const pathArray = str.split("/");
  const protocol = pathArray[0];
  const host = pathArray[2];
  return protocol + "//" + host;
};
export const devLog = (level, ...args) => {
  process.env.NODE_ENV === "development" && console[level](...args);
};

// https://stackoverflow.com/questions/53801121/vue-spa-and-laravel-socialite
export const openWindow = (url, title, options = {}) => {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
  const height =
    window.innerHeight || document.documentElement.clientHeight || window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
};

export const getNumberFromLocalStorage = (key, defaultValue = 1) => {
  let value = parseFloat(localStorage.getItem(key));

  if (isNaN(value)) {
    localStorage.setItem(key, defaultValue.toString());
    return defaultValue;
  }

  return value;
};

export const DominoPermissions = {
  EDIT_BLOG: <Trans>You can create and edit blog posts.</Trans>,
  EDIT_FORUM: <Trans>You can moderate forum threads.</Trans>,
  VIEW_FORUM: <Trans>You can create and edit your own forum threads.</Trans>,
  EDIT_SUBSCRIPTION: <Trans>You can create and edit subscriptions.</Trans>,
  EDIT_COUPON: <Trans>You can create and edit coupons.</Trans>,
  EDIT_WEBSITE: <Trans>You can create and edit website content.</Trans>,
  EDIT_COURSE: <Trans>You can create and edit course content.</Trans>,
  EDIT_PERMISSIONS: <Trans>You can create and edit permissions.</Trans>,
  VIEW_FEEDBACK: <Trans>You can view user feedback.</Trans>,
  VIEW_SUBSCRIPTIONS: <Trans>You can view subscriptions.</Trans>,
  VIEW_PAYMENTS: <Trans>You can view payments.</Trans>,
  VIEW_USERS: <Trans>You can view users.</Trans>,
  VIEW_ANALYTICS: <Trans>You can view analytics.</Trans>,
  VIEW_ADMIN: <Trans>You can view the admin site.</Trans>,
  VIEW_CUSTOM_STUDY: <Trans>You can view custom study programs.</Trans>,
  DOWNLOAD_CERTIFICATE: <Trans>You can download certificates.</Trans>,
  DOWNLOAD_VIDEOS: <Trans>You can download videos.</Trans>,
  VIEW_DICTIONARY: <Trans>You can view the dictionary.</Trans>,
  VIEW_TRANSCRIPTIONS: <Trans>You can view video transcriptions.</Trans>,
  VIEW_COURSE_BOOK: <Trans>You can view the course book.</Trans>,
  VIEW_PREMIUM_CONTENT: <Trans>You can view premium content.</Trans>,
  VIEW_FLASHCARDS: <Trans>You can view flashcards.</Trans>,
  EDIT_NOTIFICATIONS: <Trans>You can view and edit notifications.</Trans>,
  VIEW_BASIC_CONTENT: <Trans>You can view basic content.</Trans>,
  IMPERSONATE_USER: <Trans>You can impersonate users.</Trans>,
  VIEW_DICTIONARY_VIDEOS: <Trans>You can view dictionary videos.</Trans>,
  VIEW_DIALOGS: <Trans>You can view dialogs.</Trans>,
  EDIT_COMMENTS: <Trans>You can edit comments.</Trans>,
  DOWNLOAD_VOCAB_PDF: <Trans>You can view download vocab PDFs.</Trans>,
  VIEW_WORD_LISTS: <Trans>You can view word lists.</Trans>
};

export const dominoSvg =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='126' height='84' viewBox='0 0 126 84'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%230058a3' fill-opacity='0.4'%3E%3Cpath d='M126 83v1H0v-2h40V42H0v-2h40V0h2v40h40V0h2v40h40V0h2v83zm-2-1V42H84v40h40zM82 42H42v40h40V42zm-50-6a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm96 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm-42 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm30-12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM20 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 24a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM8 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm54 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM50 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM50 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm54-12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm12 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM92 54a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM92 78a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm24-42a4 4 0 1 1 0-8 4 4 0 0 1 0 8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
export const calculateLevelColor = (language_level, showLevel) => {
  if (!showLevel) {
    return "transparent";
  }

  switch (language_level) {
    case 1:
      return "red-500";
    case 2:
      return "green-500";
    case 3:
      return "orange-500";
    case 4:
      return "blue-500";
    case 5:
      return "indigo-500";
    case 6:
      return "brown-500";
    default:
      return "gray-500";
  }
};
export const appendJwtToUrl = url => {
  if (url.includes(process.env.REACT_APP_API_BASE_URL)) {
    const jwt = localStorage.getItem("DOMINO_JWT");

    if (jwt) {
      return `${url}?token=${jwt}`;
    }
  }

  return url;
};

export const trimPunctuation = string => {
  return string
    .trim()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?!？！。；，]/g, "");
};
