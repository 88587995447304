import { Button, Switcher, Text } from "newComponents/base";
import { useState } from "react";
import { IoMdHeart } from "react-icons/io";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { usePost } from "Services";
import parseErrorMessage from "utils/errors";

const Testimonial = () => {
  const [content, setContent] = useState("");
  const [isClassroom, setIsClassroom] = useState(false);
  const [agree, setAgree] = useState(false);
  const [submit, submitRes] = usePost("/frontend/testimonials", {
    onComplete() {
      toast.success("We have received your testimonial, thank you!");
      setContent("");
    },
    onError(error) {
      toast.error(parseErrorMessage(error));
    }
  });

  function submitHandler() {
    submit({ is_classroom: isClassroom, text: content });
  }

  return (
    <div className="container mx-auto p-6 max-w-4xl my-12 sm:text-center flex-1 sm:flex sm:flex-col justify-center">
      <div className="flex sm:justify-center space-x-8">
        <h1>Submit a Testimonial</h1>
        <IoMdHeart className="text-pink-500 dark:text-pink-600 text-4xl" />
      </div>

      <div className="mt-8 space-y-4">
        <div className="">
          <div className="flex-1 mt-4">
            <Text
              placeholder="Write your testimonial here."
              rows={12}
              value={content}
              onChange={e => setContent(e.target.value)}
            />
          </div>
          <label
            className={`block text-xs opacity-50 mt-2 sm:pt-4 duration-300 transition-all
              ${content.length < 100 ? "visible opacity-100" : "invisible opacity-0"}`}>
            {Math.max(0, 100 - content.length)} or more charaters required.
          </label>
        </div>
        <div className="sm:flex items-center justify-center sm:space-x-4 ">
          <label htmlFor="">Is this a Domino Classroom Testimonial?</label>
          <div className="flex items-center space-x-4 mt-4 sm:mt-0">
            <Switcher isOn={isClassroom} onChange={() => setIsClassroom(prev => !prev)} />
            <div className="opacity-50 text-sm">(If unsure leave this off)</div>
          </div>
        </div>
      </div>

      <div className="mt-6 opacity-70">
        <div>
          Your testimonial will be displayed next to all the others on our homepage. Please make
          sure
        </div>
        <ul>
          <li>Your profile picture is representable and shows your face.</li>
          <li>
            Your name on your profile is your full or first name.{" "}
            <Link to="/" target="_blank" className="text-sm underline">
              Update your profile here
            </Link>
          </li>
        </ul>

        <div className="flex mt-4 items-center">
          <label htmlFor="agreement" className="cursor-pointer">
            <input
              className="mr-3 transform scale-125"
              id="agreement"
              type="checkbox"
              value={agree}
              onChange={() => setAgree(prev => !prev)}
            />
            By submitting your testimonial you agree to let us show your name, image and testimonial
            on our website
          </label>
        </div>
      </div>

      <div className="sm:flex justify-center">
        <Button
          loading={submitRes.loading}
          disabled={submitRes.loading || !agree || content.length < 100}
          className="text-2xl mt-6 flex items-center"
          onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Testimonial;
