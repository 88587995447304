import { Redirect, Route, Switch, useParams } from "react-router-dom";
import FlashcardsPages from "pages/flashcards";
import DialogsPage from "pages/dialogs";
import DictionaryPage from "pages/dictionary";
import WordListsPage from "pages/wordLists";
import DashboardPage from "pages/dashboard";
import LeaderboardPage from "pages/Leaderboard";
import FeedbackPage from "pages/feedback/Page";
import DictionaryItemDetail from "pages/dictionary/DictionaryItemDetail";
import Testimonial from "pages/feedback/Testimonial";
import MainDashboardPage from "pages/mainDashboard";
import ReviewPage from "pages/review";
import StudyIndex from "pages/study";
import AnalyticsPage from "pages/analytics";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import CoursesPage from "pages/study/courses";
import Certificate from "pages/Certificate";

const RoutesContainer = () => {
  const { user } = useContext(ApiContext);

  return (
    <Switch>
      <Route exact path="/" component={MainDashboardPage} />
      <Route path="/study" component={StudyIndex} />

      <Route path="/review" component={ReviewPage} />
      <Route path="/flashcards" component={FlashcardsPages} />
      <Route path="/dialogs" component={DialogsPage} />
      <Route path="/courses" component={CoursesPage} />
      <Route path="/dictionary" exact component={DictionaryPage} />
      <Route path="/dictionary/:id" exact component={DictionaryItemDetail} />
      <Route path="/certificate/:id" exact component={Certificate} />
      <Route path="/word-lists" component={WordListsPage} />
      <Route path="/me" component={DashboardPage} />
      <Route path="/leaderboard" component={LeaderboardPage} />
      <Route exact path="/feedback" component={FeedbackPage} />
      <Route exact path="/testimonials/submit" component={Testimonial} />
      {user.permissions.includes("VIEW_ANALYTICS") ? <Route path="/analytics" component={AnalyticsPage} /> : null}
      <Route path="/video/:id" component={OldVideoRedirect} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

const OldVideoRedirect = () => {
  const { id } = useParams();
  return <Redirect to={`/study/video/${id}`} />;
};

export default RoutesContainer;
