import { useGet } from "Services";
import Spinner from "newComponents/Spinner";
import Detail from "./Detail";

const WordListsPage = () => {
  const { loading, error, data } = useGet("/word-lists");

  if (loading) return <Spinner center />;
  if (error) return <div>{error}</div>;
  if (!data) return null;

  return (
    <div className="container mx-auto px-6 sm:px-8 max-w-6xl">
      <div className="relative">
        <Detail categories={data} />
      </div>
    </div>
  );
};

export default WordListsPage;
