import { ApiContext } from "contexts/ApiContext";
import { useContext, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { http } from "Services";
import { Button } from "./base";
import Spinner from "./Spinner";
import onboardslide1 from "assets/images/onboardslide1.jpg";

const indexes = [0, 1, 2, 3, 4, 5];

const OnboardSlider = () => {
  const { next, user, setApiState } = useContext(ApiContext);
  const slider = useRef();
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  async function onboard(name) {
    setLoading(true);
    try {
      const res = await http.post("onboard");
      setApiState("user", { ...user, onboarded_at: res.data.data });
      if (name === "dashboard") {
        history.push("/");
      } else if (name === "course") {
        history.push("/courses");
      } else {
        history.push(`/study/video/${next.video_id}`);
      }
    } catch (e) {
      console.log("Fail onboard...", e);
    }
    setLoading(false);
  }

  if (user.onboarded_at) return null;

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    adaptiveHeight: false,
    infinite: false
  };

  function nextHandler() {
    if (slider.current) slider.current.slickNext();
  }

  return (
    <div
      id="OnboardSlider"
      className="fixed inset-0 z-50 bg-gray-100 bg-opacity-95 dark:bg-gray-900 text-base">
      <div style={{ animationDuration: "0.7s", animationName: "slidein" }}>
        <Slider
          className="focus:outline-none h-full"
          ref={slider}
          {...settings}
          afterChange={setCurrentIndex}>
          {indexes.map((card, index) => (
            <GuideSlide
              key={index}
              index={index}
              data={card}
              next={nextHandler}
              isShowing={index === currentIndex}
              onNext={() => slider.current.slickNext()}
              onPrev={() => slider.current.slickPrev()}
              onboard={onboard}
            />
          ))}
        </Slider>
      </div>

      {loading ? (
        <div className="absolute z-10 inset-0 bg-gray-100 bg-opacity-80 backdrop-blur">
          <Spinner center label="Onboarding..." />
        </div>
      ) : null}
    </div>
  );
};

const GuideSlide = ({ index, onNext, onPrev, onboard }) => {
  const slides = () => {
    switch (index) {
      case 0:
        return (
          <div>
            <img src={onboardslide1} alt="domino method" className="w-full max-w-4xl" />
            {/* <h1>Welcome to Domino Chinese!</h1>
            <div className="mt-4 text-lg">Let us show you what we&apos;re all about.</div>
            <div className="mt-8 py-12 flex justify-center">
              <img src={onboardslide1} alt="domino method" className="w-full" />
            </div> */}
            {/* <div className="mt-2 opacity-60">Domino Blocks.</div> */}
          </div>
        );
      case 1:
        return (
          <div>
            <h1>Step 1</h1>
            <h3 className="mt-4 flex space-x-2 items-center">
              <span>Choose a course</span>
              <BsArrowRight />
              <span>Start a lesson</span>
            </h3>
            <div className="mt-4">We have 3000+ videos from real-life in China.</div>
            <div className="mt-2">
              Courses are divided into chapters and lessons. Every lesson is ~ 20 mins (10 short
              videos).
            </div>
            <div className="mt-6 rounded-xl overflow-hidden">
              <img
                src="https://materials.dominochinese.com/web-v4/guide-video-2.png"
                alt="guide video"
              />
            </div>
            <div className="mt-2 text-xs opacity-60">A video in Beginner Chinese Course.</div>
          </div>
        );

      case 2:
        return (
          <div>
            <h1>Step 2</h1>
            <h3 className="mt-4">Quiz</h3>
            <div className="mt-4">
              The best learning is interactive, so after every video you&apos;ll get quizzed based
              on what you&apos;ve just learned.
            </div>
            <div className="mt-2">
              Later on, you&apos;ll have course mastery tests, pass them to earn a certificate.
            </div>
            <div className="mt-6 rounded-xl overflow-hidden p-6 border bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700">
              <div className="block dark:hidden">
                <img
                  src="https://materials.dominochinese.com/web-v4/quiz.png"
                  alt="guide quizzes"
                />
              </div>
              <div className="dark:block hidden">
                <img
                  src="https://materials.dominochinese.com/web-v4/quiz-dark.png"
                  alt="guide quizzes"
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h1>Step 3</h1>
            <h3 className="mt-4">Review</h3>
            <div className="mt-4">
              The words you learn in every video get added to your flashcard deck. Our flashcards
              are based on an algorithm to optimize your retention, so just make sure you come here
              a few minutes each session, and we&apos;ll take care of the rest.
            </div>
            <div className="mt-6 rounded-xl overflow-hidden">
              <div className="py-4 bg-gray-100 dark:bg-gray-900 rounded-xl shadow-xl dark:shadow-none border border-gray-200 dark:border-gray-700 overflow-hidden max-w-3xl mx-auto">
                <video
                  className="hidden dark:block"
                  src="http://materials.dominochinese.com/web-v4/flashcards-dark.mp4"
                  autoPlay
                  muted
                  loop
                />
                <video
                  className="block dark:hidden"
                  src="http://materials.dominochinese.com/web-v4/flashcards.mp4"
                  autoPlay
                  muted
                  loop
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h1>Step 4</h1>
            <h3 className="mt-4">Practice</h3>
            <div className="mt-4">
              <p>We have 1000s of exercises to help you practice including:</p>
              <ul className="list-disc list-inside">
                <li>1000+ Dialogs</li>
                <li>Speech recognition</li>
                <li>Interactive videos</li>
                <li>Character breakdowns</li>
                <li>And much, much more…</li>
              </ul>
            </div>
            <div className="mt-6 rounded-xl overflow-hidden">
              <div className="mx-auto max-w-xl 2xl:max-w-2xl relative">
                <div className="block dark:hidden">
                  <img src="http://materials.dominochinese.com/web-v4/dialogs.jpeg" alt="dialogs" />
                </div>
                <div className="dark:block hidden">
                  <img
                    src="http://materials.dominochinese.com/web-v4/dialogs-dark.jpeg"
                    alt="dialogs"
                  />
                </div>

                <div className="absolute bottom-0 w-full h-32 bg-gradient-to-b from-transparent to-gray-100 dark:to-gray-900"></div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <h1>Are You Ready?</h1>

            <div className="mt-8">
              We&apos;ve already placed you in our{" "}
              <span className="font-semibold">Beginner Chinese course</span>.
            </div>

            <div className="mt-4 relative">
              <img
                className="w-full"
                src="https://materials.dominochinese.com/static/beginner_chinese.jpg"
                alt="Beginner Chinese"
              />

              {/* <div className="absolute inset-0 flex flex-col items-center justify-center space-y-4 text-sky-600 drop-shadow-xl">
                <button
                  className="rounded-full px-6 py-2 bg-sky-600 text-white text-lg flex items-center space-x-4 cursor-pointer bounce scale-100 hover:scale-105"
                  onClick={onboard}>
                  <span>Now try our first lesson for FREE</span>
                  <FaPlay size={21} />
                </button>
              </div> */}
            </div>

            {/* <hr className="my-8" /> */}

            <div className="mt-8 flex justify-between items-center">
              <Button bold={false} onClick={() => onboard("course")}>
                Check out our more advanced courses
              </Button>
              <button
                className="rounded-full px-6 py-2 bg-sky-600 text-white flex items-center space-x-4 cursor-pointer bounce scale-100 hover:scale-105"
                onClick={onboard}>
                <span>Try Lesson 1 for FREE</span>
              </button>
            </div>

            {/* <div className="mt-8">
              <div>Want to learn Chinese with a teacher and get guaranteed success?</div>
              <div>
                <a href="https://classroom.dominochinese.com/" target="_blank" rel="noreferer">
                  Learn more about Domino Classroom
                </a>
              </div>
            </div> */}
          </div>
        );

      default:
        return <div>???</div>;
    }
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="bg-white dark:bg-gray-800 p-12 rounded-3xl max-w-4xl m-8 relative">
        {slides(index)}

        <div className="text-lg flex justify-between items-center mt-8">
          <div>
            {index !== indexes.length - 1 ? (
              <Button onClick={onboard} gray bold={false}>
                Skip
              </Button>
            ) : null}
          </div>
          <div className="flex space-x-8">
            {index > 0 ? (
              <Button onClick={onPrev} back gray bold={false}>
                Prev
              </Button>
            ) : null}

            {index === indexes.length - 1 ? (
              <Button next bold={false} onClick={() => onboard("dashboard")}>
                Go to Dashboard
              </Button>
            ) : (
              <Button onClick={onNext} next>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardSlider;
