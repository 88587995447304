import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import { hasLessonPermission } from "utils/permissions";
import { PermissionLink } from "newComponents/base";
import moment from "moment";
import { usePost } from "Services";
import { toast } from "react-toastify";
import Spinner from "newComponents/Spinner";

const LessonVideos = ({ label = "Videos", lesson_id, video_id, setShowNotAllow }) => {
  const { db } = useContext(ApiContext);
  const lesson = db.lessons.find(i => i.id === lesson_id);
  const lessonVideos = db.getLessonVideos(lesson_id);

  return (
    <div className="p-4 sm:p-6 rounded-xl bg-white dark:bg-gray-800 w-full mb-8 shadow-sm">
      <label>{label}</label>
      <div className="mt-4">
        {lessonVideos.map(video => (
          <Video
            key={video.id}
            lesson={lesson}
            video={video}
            isSelected={video_id === video.id}
            setShowNotAllow={setShowNotAllow}
          />
        ))}
      </div>
    </div>
  );
};

const Video = ({ lesson, video, isSelected, setShowNotAllow }) => {
  const { completedItems, user, setApiState } = useContext(ApiContext);

  const [completeVideo, completeVideoRes] = usePost(`/videos/${video.id}/manual-complete`, {
    onComplete: () => {
      setApiState("completedItems", {
        ...completedItems,
        videoIds: [...completedItems.videoIds, video.id]
      });
    },
    onError: error => toast.error(error.message)
  });

  function markComplete() {
    if (hasLessonPermission(user, lesson)) {
      completeVideo();
    } else {
      setShowNotAllow(true);
    }
  }

  const completed = completedItems.videoIds.includes(video.id);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center truncate py-2 w-72">
        {completeVideoRes.loading ? (
          <div className="mr-3">
            <Spinner size={16} />
          </div>
        ) : (
          <div className="mr-3 text-yellow-500 text-lg cursor-pointer transition-all transform scale-100 hover:scale-125 duration-200">
            {completed ? (
              <AiFillStar className="text-yellow-400" />
            ) : (
              <AiOutlineStar
                className="text-yellow-400"
                onClick={markComplete}
                title="Mark Complete"
              />
            )}
          </div>
        )}

        <PermissionLink
          className={`transition-all hover:font-bold outline-none
                      ${isSelected ? "font-black tracking-wide" : ""}
                    `}
          hasPermission={hasLessonPermission(user, lesson)}
          notAllowHandler={() => setShowNotAllow(true)}
          to={`/study/video/${video.id}`}>
          {video.name}
        </PermissionLink>
      </div>

      <span className="text-gray-400 dark:text-gray-600 text-xs">
        {moment.utc(video.duration * 1000).format("mm:ss")}
      </span>
    </div>
  );
};

export default LessonVideos;
