import PropTypes from "prop-types";
import { parseDuration } from "@iforwms/helpers-js";

const Duration = ({ classes, seconds }) => (
  <time dateTime={`P${Math.round(seconds)}S`} className={classes}>
    {parseDuration(seconds)}
  </time>
);

Duration.propTypes = {
  classes: PropTypes.string.isRequired,
  seconds: PropTypes.number.isRequired
};

export default Duration;
