import { useContext, useEffect, useState } from "react";
import { http, usePost } from "Services";
import { Text, Button, FileSelector } from "newComponents/base";
import { toast } from "react-toastify";
import { ApiContext } from "contexts/ApiContext";
import { uuidv4 } from "utils/string";
import ProgressRing from "newComponents/ProgressRing";
import { RiCloseCircleFill } from "react-icons/ri";
import parseErrorMessage from "utils/errors";
import { isMobile } from "./utils";
import { Footer } from "./Footer";

const Feedback = ({
  placeholder = "Input your thoughts here.",
  itemType,
  itemId,
  hide,
  data = {}
}) => {
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const { setApiState, showFeedbackForm } = useContext(ApiContext);

  useEffect(() => {
    const parseHandler = res => {
      if (!showFeedbackForm) return;
      const data = res.clipboardData;
      const files = [...data.files].map(file => ({
        id: uuidv4(),
        file,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: null
      }));
      files.forEach(uploadFile);
      setFiles(prev => [...prev, ...files]);
    };

    window.addEventListener("paste", parseHandler);

    return () => {
      window.removeEventListener("paste", parseHandler);
    };
  }, [showFeedbackForm]);

  const url = "/feedback";
  let payload = {
    title: `${isMobile() ? "(Mobile) " : ""}${content.substring(0, 30)}`,
    description: content,
    platform: "study",
    images
  };

  if (itemType === "video") {
    payload = {
      video_id: itemId,
      title: data.title || "Video Feedback",
      description: content,
      feedbackable_type: "VIDEO",
      feedbackable_id: itemId,
      priority: "MEDIUM",
      platform: "CONTENT",
      type: "VIDEO_BUG"
    };
  } else if (itemType === "quiz") {
    payload = {
      title: data.title || "Quiz Feedback",
      description: content,
      feedbackable_type: "QUIZ",
      feedbackable_id: itemId,
      priority: "MEDIUM",
      platform: "CONTENT",
      type: "BUG"
    };
  } else if (itemType === "flashcard") {
    if (data && data.word) {
      payload = {
        title: `[Flashcard] ${data.word.characters.map(i => i.text).join("")}`,
        description: content,
        feedbackable_type: data.word.type || "WORD",
        feedbackable_id: data.word.id,
        priority: "MEDIUM",
        platform: "CONTENT",
        type: "BUG",
        images
      };
    }
  }

  payload.description = `${payload.description}

------

${window.location.href}

${navigator.userAgent}

Page Size: ${window.innerWidth} x ${window.innerHeight}`;

  const [submit, { loading }] = usePost(url, {
    onComplete: () => {
      toast.success("Thank you! We have received your feedback.");
      setContent("");
      setImages([]);
      setFiles([]);
      setApiState("showFeedbackForm", false);
      if (hide) hide();
    },
    onError: error => toast.error(parseErrorMessage(error))
  });

  function uploadFile({ id, file }) {
    var formData = new FormData();
    formData.append("file", file);
    http
      .post("/media", formData, {
        onUploadProgress: function (e) {
          const progress = Math.round((e.loaded * 100) / e.total);
          setFiles(prev => prev.map(i => (i.id !== id ? i : { ...i, progress })));
        }
      })
      .then(res => {
        setImages(prev => [...prev, res.data.data.name]);
        setFiles(prev => prev.map(i => (i.id === id ? { ...i, uploaded: true } : i)));
      })
      .catch(error => toast.error(error));
  }

  const uploadingImages = files.filter(i => i.progress < 100 && i.error === null).length > 0;

  return (
    <div>
      <Text
        rows={8}
        value={content}
        onChange={e => setContent(e.target.value)}
        placeholder={placeholder}
      />

      <div className="opacity-60 text-xs px-2">
        You can copy and paste images into the text area.
      </div>

      <div className="flex flex-wrap mt-4">
        {files.map(i => (
          <div key={i.id} className="mr-2 mb-2 h-24 relative">
            <div
              className="cursor-pointer text-red-500 absolute p-2 right-0"
              onClick={() => setFiles(prev => prev.filter(prevItem => prevItem.id !== i.id))}>
              <RiCloseCircleFill className="text-xl" />
            </div>
            <img className="h-full" src={i.preview} alt="upload preview" />
            {i.error ? (
              <div>error</div>
            ) : i.progress < 100 ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <ProgressRing progress={i.progress} radius={30} stroke={3} showPercentage={true} />
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div className="px-2">
        <FileSelector
          accept="image/*"
          title="+ Add Images"
          className="mt-4"
          onChange={e => {
            const files = [...e.target.files].map(file => ({
              id: uuidv4(),
              file,
              preview: URL.createObjectURL(file),
              progress: 0,
              uploaded: false,
              error: null
            }));
            files.forEach(uploadFile);
            setFiles(prev => [...prev, ...files]);
            e.target.value = null;
          }}
        />
      </div>

      <div className="px-2">
        <Button
          loading={loading}
          disabled={loading || uploadingImages}
          className="text-xl mt-6 flex items-center"
          onClick={() => submit(payload)}>
          Submit
        </Button>
      </div>

      <Footer />
    </div>
  );
};

export default Feedback;
