import { useContext, useEffect } from "react";
import Spinner from "newComponents/Spinner";
import { useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useGet, usePost } from "Services";
import ProgressRing from "newComponents/ProgressRing";
import { FiChevronRight } from "react-icons/fi";
import { Button, NavigationBar } from "newComponents/base";
import ResultItem from "pages/dictionary/ResultItem";
import { toast } from "react-toastify";
import { ApiContext } from "contexts/ApiContext";
import { useModals } from "contexts/ModalProvider";
import SimpleVideo from "newComponents/SimpleVideo";

const Detail = ({ categories }) => {
  let initialList = localStorage.getItem("vocabList:selectedList");
  if (!initialList) initialList = categories[0].slug;

  const { screenSize } = useContext(ApiContext);
  const [selectedList, setSelectedList] = useState(initialList);
  const { loading, error, data, meta } = useGet(
    selectedList ? `/word-lists/${selectedList}` : `/word-lists/${categories[0].slug}`
  );
  const { present } = useModals();
  const videoModal = useModals();

  const [addToDeck, addToDeckRes] = usePost(
    selectedList
      ? `/word-lists/${selectedList}/add-to-deck`
      : `/word-lists/${categories[0].slug}/add-to-deck`,
    {
      onComplete: () => toast.success("Added to flashcards."),
      onError: () => toast.error("Failed to add, please try again later.")
    }
  );

  useEffect(() => {
    if (selectedList) localStorage.setItem("vocabList:selectedList", selectedList);
  }, [selectedList]);

  if (loading) return <Spinner className="py-20" center />;
  if (error) return <div>{error.message}</div>;
  if (!data) return null;

  return (
    <div style={{ minHeight: 250 }}>
      <div className="sm:flex justify-between items-end">
        <div className="w-full">
          <NavigationBar title="Vocab Lists" />

          <div className="float-right -mr-3 -mt-2">
            <ProgressRing
              stroke={screenSize.width < 400 ? 6 : 8}
              radius={screenSize.width < 400 ? screenSize.width / 10 : 50}
              progress={(meta.total_complete * 100) / meta.total}
              showPercentage={true}
            />
          </div>

          <div className="text-blue-500 cursor-pointer flex items-center space-x-2">
            <select
              id="listSelector"
              className="appearance-none bg-transparent font-bold  text-xl cursor-pointer"
              value={selectedList}
              onChange={e => setSelectedList(e.target.value)}>
              {categories.map(i => (
                <option value={i.slug} key={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
            <FiChevronRight size={21} />
          </div>

          <div className="text-gray-600">{meta.total} Words</div>
        </div>
      </div>

      <div className="my-8 flex flex-wrap justify-between -mx-2">
        {data.map(i => (
          <div
            className={`rounded-xl text-xl 2xl:text-2xl flex items-center justify-center mx-2 mb-4 cursor-pointer relative px-4 py-3 sm:px-8 sm:py-4 2xl:px-10 2xl:py-6 shadow-sm hover:shadow-lg transform bounce scale-100 hover:scale-110
              ${
                i.is_complete
                  ? "bg-green-400 text-white hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 dark:active:bg-green-900"
                  : "bg-white active:bg-gray-100 dark:bg-gray-800"
              }
            `}
            key={i.id}
            onClick={() =>
              present({
                title: i.text,
                maxWidth: "max-w-5xl",
                children: (
                  <ResultItem
                    word={i}
                    videosInline={false}
                    wordTarget="_blank"
                    watchVideo={video =>
                      videoModal.present({
                        maxWidth: "max-w-6xl",
                        children: <SimpleVideo video={video} />
                      })
                    }
                  />
                )
              })
            }>
            <div>{i.text}</div>
            {i.is_complete ? (
              <IoIosCheckmarkCircle className="absolute bottom-2 right-2 text-base sm:text-3xl 2xl:text-4xl" />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Detail;
