import { createContext, useEffect, useState } from "react";

export const FlashcardsContext = createContext({});

const FlashcardsProvider = ({ children }) => {
  const [size, setSize] = useState(localStorage.getItem("flashcards:size") || 20);

  const [type, setType] = useState(
    localStorage.getItem("flashcards:type") ? parseInt(localStorage.getItem("flashcards:type")) : 0
  );
  const [cardFront, setCardFront] = useState(
    localStorage.getItem("flashcards:cardFront") || "Random"
  );
  const [autoPlayAudio, setAutoPlayAudio] = useState(
    localStorage.getItem("flashcards:autoPlayAudio") == "1" ? true : false
  );
  const [enableAnimation, setEnableAnimation] = useState(
    localStorage.getItem("flashcards:enableAnimation") == "0" ? false : true
  );
  const [font, setFont] = useState(localStorage.getItem("flashcards:font"));

  useEffect(() => {
    localStorage.setItem("flashcards:type", type);
    localStorage.setItem("flashcards:cardFront", cardFront);
    localStorage.setItem("flashcards:autoPlayAudio", autoPlayAudio ? "1" : "0");
    localStorage.setItem("flashcards:font", font);
    localStorage.setItem("flashcards:enableAnimation", enableAnimation ? "1" : "0");
  }, [type, cardFront, autoPlayAudio, enableAnimation, font]);

  return (
    <FlashcardsContext.Provider
      value={{
        size,
        setSize,
        type,
        setType,
        cardFront,
        setCardFront,
        autoPlayAudio,
        setAutoPlayAudio,
        enableAnimation,
        setEnableAnimation,
        font,
        setFont
      }}>
      {children}
    </FlashcardsContext.Provider>
  );
};

export default FlashcardsProvider;
