import { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { Button } from "newComponents/base";
import { shuffledArray } from "utils/array";

const QuizSentenceQuestion = ({ result = null, correctAnswer, didAnswer }) => {
  const [sentence, setSentence] = useState([]);
  const [shuffledChars, setShuffledChars] = useState([]);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    setSubmited(false);
    setSentence([]);
    setShuffledChars(
      shuffledArray(correctAnswer.split("")).map((i, index) => ({
        id: index,
        text: i
      }))
    );
  }, [correctAnswer]);

  useEffect(() => {
    if (!result) {
      setSubmited(false);
      setSentence([]);
      setShuffledChars(
        shuffledArray(correctAnswer.split("")).map((i, index) => ({
          id: index,
          text: i
        }))
      );
    }
  }, [result]);

  function check() {
    if (submited) return;
    const isCorrect = sentence.map(i => i.text).join("") === correctAnswer;
    didAnswer(isCorrect ? "PASS" : "FAIL");
    setSubmited(true);
  }

  return (
    <div>
      <div className="text-gray-500 text-xs">Select in correct order.</div>

      <div className="flex my-4 flex-wrap" style={{ minHeight: 38 }}>
        {sentence.map(i => (
          <button
            className="px-2 py-1 rounded-xl border text-xl mr-3 cursor-pointer hover:bg-gray-200 active:bg-gray-300 outline-none focus:outline-none mb-4"
            disabled={submited}
            key={i.id}
            onClick={() => {
              if (!submited) setSentence(prev => prev.filter(j => j.id !== i.id));
            }}>
            {i.text}
          </button>
        ))}
      </div>

      <hr />

      <div className="flex my-4 flex-wrap">
        {shuffledChars.map(i => (
          <button
            className="px-2 py-1 rounded-xl border text-xl mr-3 cursor-pointer hover:bg-gray-200 active:bg-gray-300 dark:hover:bg-gray-900 dark:active:bg-gray-700 dark:border-gray-700 outline-none focus:outline-none disabled:bg-gray-300 dark:disabled:bg-gray-900 disabled:cursor-not-allowed mb-4"
            key={i.id}
            disabled={submited || sentence.find(j => j.id === i.id)}
            onClick={() => {
              if (!submited) setSentence(prev => [...prev, i]);
            }}>
            <span
              className={`transition-all
                ${sentence.find(j => j.id === i.id) ? "opacity-0" : "opacity-100"}`}>
              {i.text}
            </span>
          </button>
        ))}
      </div>

      <div className="flex mt-6 space-x-8">
        <Button
          className="text-lg"
          type="button"
          onClick={check}
          disabled={submited || sentence.length !== shuffledChars.length}>
          <Trans>Check</Trans>
        </Button>
      </div>

      {submited ? (
        <div className="flex items-center p-4 rounded-xl bg-gray-100 dark:bg-gray-700 mt-4">
          <div>
            <label>Correct Answers:</label>
          </div>
          <div className="flex ml-4 tracking-widest">{correctAnswer}</div>
        </div>
      ) : null}
    </div>
  );
};

export default QuizSentenceQuestion;
