import { NavigationBar } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";
import Page from "newComponents/Page";
import { LessonReview } from "./Dashboard";
import { useContext } from "react";
import { LessonReviewContext } from "contexts/LessonReviewProvider";

function inDays(date, from, to) {
  const diff = Math.ceil((date - new Date()) / (3600 * 24 * 1000));
  if (!to) return diff > from;
  return diff > from && diff <= to;
}

const AllReviews = () => {
  const { loading, error, notReview, overdue, upcoming } = useContext(LessonReviewContext);

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;

  const totalCount = [...notReview, ...overdue, ...upcoming].length;

  return (
    <Page>
      <NavigationBar title="All Reviews" backTo="/review" backLabel="Back to Recommended Reviews" />

      {totalCount > 0 ? (
        <div className="space-y-12">
          <Reviews title="Not Yet Reviewed" items={notReview} />

          <Reviews title="Overdue" items={overdue} />

          <Reviews
            title="In 1 Day"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 0, 1))}
          />
          <Reviews
            title="In 3 Days"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 2, 3))}
          />
          <Reviews
            title="In 7 Days"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 4, 7))}
          />
          <Reviews
            title="In 2 Weeks"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 8, 14))}
          />
          <Reviews
            title="In 1 Month"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 15, 30))}
          />
          <Reviews
            title="Mastered/1 month+"
            items={upcoming.filter(i => inDays(new Date(i.due_at), 31))}
          />
        </div>
      ) : (
        <div>Nothing to review for now. Come back later…</div>
      )}
    </Page>
  );
};

const Reviews = ({ title, items }) => {
  if (items.length === 0) return null;

  return (
    <div>
      <h3>{title}</h3>

      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 text-white">
        {items.map((lesson, index) => (
          <LessonReview lesson={lesson} key={index} />
        ))}
      </div>
    </div>
  );
};

export default AllReviews;
