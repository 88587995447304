import { useState } from "react";

export const videoQualities = ["1080p (China)", "720p (China)", "480p (China)", "Outside China"];

const VideoQualityControls = ({
  setVideoQuality,
  videoQuality,
  cloudflare_src,
  sd_src,
  sq_src,
  hd_src
}) => {
  const [showControls, setShowControlls] = useState(false);

  function toggleControls() {
    setShowControlls(prev => !prev);
  }

  if (videoQuality === null) return null;

  return (
    <div className="relative text-xs whitespace-nowrap">
      {showControls ? (
        <div className="absolute flex flex-col items-end right-0 bottom-8 space-y-2">
          {hd_src && (
            <span
              className={`video-control-menu-item ${videoQuality === 0 && "text-teal-600"}`}
              onClick={() => {
                setVideoQuality(0);
                toggleControls();
              }}>
              1080p (China)
            </span>
          )}
          {sd_src && (
            <span
              className={`video-control-menu-item ${videoQuality === 1 && "text-teal-600"}`}
              onClick={() => {
                setVideoQuality(1);
                toggleControls();
              }}>
              720p (China)
            </span>
          )}
          {sq_src && (
            <span
              className={`video-control-menu-item ${videoQuality === 2 && "text-teal-600"}`}
              onClick={() => {
                setVideoQuality(2);
                toggleControls();
              }}>
              480p (China)
            </span>
          )}
          {cloudflare_src && (
            <span
              className={`video-control-menu-item ${videoQuality === 3 && "text-teal-600"}`}
              onClick={() => {
                setVideoQuality(3);
                toggleControls();
              }}>
              Outside China
            </span>
          )}
        </div>
      ) : null}

      <span className="inline-block cursor-pointer" onClick={toggleControls}>
        {videoQualities[videoQuality]}
      </span>
    </div>
  );
};

export default VideoQualityControls;
