import moment from "moment";
import { useEffect, useState } from "react";

const withTwoNumbers = value => (value < 10 ? `0${value}` : value);

const Countdown = ({ date, inline }) => {
  const now = moment(new Date());
  const end = moment(date);
  const [duration, setDuration] = useState(moment.duration(end.diff(now)));

  useEffect(() => {
    const id = setInterval(() => {
      const now = moment(new Date());
      const end = moment(date);
      setDuration(moment.duration(end.diff(now)));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  if (new Date() > date) return null;

  if (inline)
    return (
      <div className="flex text-center space-x-2">
        <span>{Math.floor(duration.asDays())}</span>
        <span>DAYS</span>
        <span>{duration.hours()}</span>
        <span>HOURS</span>
        <span>{withTwoNumbers(duration.minutes())}</span>
        <span>MINS</span>
        <span>{withTwoNumbers(duration.seconds())}</span>
        <span>SECS</span>
      </div>
    );

  return (
    <div className="flex text-center space-x-6">
      <div className="w-8 lg:w-12">
        <div className="text-2xl lg:text-4xl font-bold">{Math.floor(duration.asDays())}</div>
        <div className="text-xs opacity-80 font-extralight">DAYS</div>
      </div>
      <div className="w-8 lg:w-12">
        <div className="text-2xl lg:text-4xl font-bold">{duration.hours()}</div>
        <div className="text-xs opacity-80 font-extralight">HOURS</div>
      </div>
      <div className="w-8 lg:w-12">
        <div className="text-2xl lg:text-4xl font-bold">{withTwoNumbers(duration.minutes())}</div>
        <div className="text-xs opacity-80 font-extralight">MINS</div>
      </div>
      <div className="w-8 lg:w-12">
        <div className="text-2xl lg:text-4xl font-bold">{withTwoNumbers(duration.seconds())}</div>
        <div className="text-xs opacity-80 font-extralight">SECS</div>
      </div>
    </div>
  );
};

export default Countdown;
