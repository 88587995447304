import ProgressRing from "newComponents/ProgressRing";
import { BsCardText, BsChatFill } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { IoIosVideocam } from "react-icons/io";
import { Link } from "react-router-dom";
import ProgressBar from "newComponents/ProgressBar";
import { useContext } from "react";
import { LessonReviewContext } from "contexts/LessonReviewProvider";
import { ApiContext } from "contexts/ApiContext";
import { ClassroomsContext } from "contexts/ClassroomProvider";
import { toABC } from "utils/string";
import { useGet } from "Services";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";
import ClassroomSideBar from "./ClassroomSideBar";
import AnimatedNumber from "react-animated-numbers";
import { useModals } from "contexts/ModalProvider";
import { Button } from "newComponents/base";
import MoodleCourseSelector from "newComponents/MoodleCourses";

export const animateNumbersConfigs = [
  { mass: 1, tension: 130, friction: 40 },
  { mass: 2, tension: 140, friction: 40 },
  { mass: 3, tension: 150, friction: 40 },
];

function computeCourseProgress(db, courseId, completedItems) {
  const levelIds = db.levels.filter((i) => i.course_id === courseId).map((i) => i.id);
  const chapterIds = db.chapters.filter((i) => levelIds.includes(i.level_id)).map((i) => i.id);
  const lessonIds = db.lessons.filter((i) => chapterIds.includes(i.chapter_id)).map((i) => i.id);
  const videoIds = db.videos.filter((i) => i.videoable_type === "App\\Lesson" && lessonIds.includes(i.videoable_id)).map((i) => i.id);
  const completedVideoIds = completedItems.videoIds.filter((id) => videoIds.includes(id));
  const progress = completedVideoIds.length / videoIds.length;
  return Math.round(progress * 1000) / 10;
}

const COURSE_HSK_LEVELS = [
  {
    courses: ["Beginner Chinese", "Conversational Chinese"],
    hsk: "1",
  },
  {
    courses: ["Intermediate Chinese"],
    hsk: "2",
  },
  {
    courses: ["Fluent Chinese", "Advanced Chinese"],
    hsk: "3",
  },
];

const MainDashboardPage = () => {
  const { db, user } = useContext(ApiContext);
  const { loading, error, data } = useGet("/next");

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;
  if (!data || !db) return null;

  return <Content next={data} />;
};

const Content = ({ next }) => {
  const { db, completedItems, user, setApiState } = useContext(ApiContext);
  const { loading, nextReview } = useContext(LessonReviewContext);
  const nextReviewTitle = nextReview ? nextReview.full_name.split("-").slice(0, 3).join("-") : "";
  const fetchSidebar = useContext(ClassroomsContext);
  const moodleCourseModal = useModals();

  const course = db.courses.find((i) => i.id === next.course_id);
  const chapter = db.chapters.find((i) => i.id === next.chapter_id);
  const lesson = db.lessons.find((i) => i.id === next.lesson_id);

  const courseProgress = computeCourseProgress(db, course.id, completedItems);
  const hskLevel = COURSE_HSK_LEVELS.find((i) => i.courses.includes(course.name));

  const video = db.videos.find((i) => i.id === next.video_id);
  const videoLink = video.videoable_type === "App\\Lesson" ? `/study/video/${next.video_id}` : `/study/levels/${next.level_id}?storyTime=${video.id}`;

  return (
    <div className="container mx-auto px-6">
      <div className="xl:flex xl:space-x-12">
        <div className="xl:space-y-12 py-8 sm:py-12 xl:py-16 flex-1">
          <section>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-12 lg:gap-12">
              {/* <div className="md:col-span-2 p-8 sm:p-10 2xl:p-12 text-white hover:text-white bg-cyan-500 dark:bg-cyan-700 rounded-3xl shadow-sm">
                <h2>Dashboard Unlocked</h2>
                <div className="mt-4 opacity-70">
                  Let's check out the Dashboard and learn how to navigate around the different
                  Domino Tools.
                </div>
                <div className="mt-4">
                  <video
                    className="w-full"
                    src="https://materials.dominochinese.com/web-v4/v5-onboarding-videos/1.+Dashboard+Walkthrough+1.0+Final.mp4"
                    controls
                  />
                </div>
              </div> */}

              <Link
                className="md:col-span-2 p-8 sm:p-10 2xl:p-12 text-white hover:text-white bg-teal-500 dark:bg-teal-700 rounded-3xl shadow-sm cursor-pointer bounce-5 transform-gpu duration-200 scale-100 hover:scale-102"
                to={videoLink}
              >
                <IoIosVideocam size={70} className="float-right block sm:hidden" />
                <IoIosVideocam size={90} className="float-right hidden sm:block" />

                <div className="flex items-center space-x-8">
                  <h1 className="font-extrabold text-5xl md:text-6xl font-serif tracking-wide">Study</h1>
                </div>

                <div className="mt-6 opacity-90 dark:opacity-70">
                  <h3>{course.name}</h3>
                  <div className="text-sm mt-4">{course.description}</div>
                  <div className="sm:flex sm:space-x-4 text-sm mt-4">
                    <div>
                      <label className="text-gray-200">Words: </label>
                      <span>{course.word_count}</span>
                    </div>
                    <div>
                      <label className="text-gray-200">Grammar Points: </label>
                      <span>{course.grammar_count}</span>
                    </div>
                    <div>
                      <label className="text-gray-200">Videos: </label>
                      <span>{course.video_count}</span>
                    </div>
                    <div>
                      <label className="text-gray-200">Duration: </label>
                      <span>{(course.duration / 3600).toFixed(1)} hours</span>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <ProgressBar progress={courseProgress} />
                </div>

                <div className="flex justify-between items-end">
                  <div className="flex items-center space-x-3 mt-6">
                    <div className="lg:text-xl leading-loose">
                      <span className="opacity-70 pr-3">Continue</span>{" "}
                      <b>
                        {chapter.name} / {video.videoable_type === "App\\Lesson" ? `Lesson ${toABC(lesson.ordering)} - ${lesson.name}` : "Story Time"}
                      </b>
                    </div>
                    <FaChevronRight className="flex-shrink-0" />
                  </div>
                </div>
              </Link>

              <Link
                className="p-8 sm:p-10 2xl:p-12 2xl:py-16 text-white hover:text-white bg-orange-500 dark:bg-orange-700 rounded-3xl shadow-sm cursor-pointer bounce transform-gpu duration-200 scale-100 hover:scale-102"
                to={nextReview ? `/review/lesson/${nextReview.id}` : "/review"}
              >
                <BsCardText size={50} className="float-right block sm:hidden" />
                <BsCardText size={64} className="float-right hidden sm:block" />
                <h1 className="font-extrabold font-serif tracking-wide mb-4">Review</h1>

                {loading ? (
                  <div>...</div>
                ) : nextReview ? (
                  <div>
                    <div className="opacity-70">{nextReviewTitle}</div>
                    <div
                      className="flex items-center space-x-3 text-base sm:text-xl"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div className="">{nextReview.name}</div>
                      <FaChevronRight className="flex-shrink-0" />
                    </div>
                  </div>
                ) : (
                  <div>All good now, no need to review! </div>
                )}
              </Link>

              <div
                className="p-8 sm:p-10 2xl:p-12 2xl:py-16 text-white hover:text-white bg-purple-500 dark:bg-purple-900 rounded-3xl shadow-sm cursor-pointer bounce transform-gpu duration-200 scale-100 hover:scale-102"
                href={process.env.REACT_APP_MOODLE_URL}
                target="_blank"
                rel="noreferer"
                onClick={() =>
                  moodleCourseModal.present({
                    title: "Practice",
                    subtitle: "Choose your current course and explore our many different exercises.",
                    maxWidth: "max-w-4xl 2xl:max-w-6xl",
                    noPadding: true,
                    children: <MoodleCourseSelector />,
                  })
                }
              >
                <BsChatFill size={50} className="float-right block sm:hidden" />
                <BsChatFill size={64} className="float-right hidden sm:block" />
                <h1 className="font-extrabold font-serif tracking-wide">Practice</h1>
                <div className="flex mt-4 items-center space-x-3">
                  <div className="text-xl">Explore thousands of exercise</div>
                  <FaChevronRight />
                </div>
              </div>
            </div>
          </section>

          <section className="mt-10 lg:grid grid-cols-2 gap-8 items-stretch">
            {hskLevel ? <HSKProgress hsk={hskLevel.hsk} /> : null}

            <UserCompletion />
          </section>
        </div>

        <div className="w-full xl:w-1/3 2xl:w-1/4 mb-16 xl:my-16 flex-shrink-0 space-y-8">
          <div>
            <UserXP />
          </div>

          {process.env.REACT_APP_STAGE === "production" ? null : (
            <div>
              <Button onClick={() => setApiState("user", { ...user, onboarded_at: null })}>Test Onboard Slide</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HSKProgress = ({ hsk }) => {
  const { meta } = useGet(`/word-lists/hsk-${hsk}?stats`);

  const progress = meta ? (parseFloat(meta.total_complete) * 100) / parseFloat(meta.total) : 0;

  return (
    <div className="px-6 sm:px-12 py-12 rounded-3xl bg-white shadow-sm dark:bg-gray-800 flex items-center justify-center">
      <div>
        <h2 className="text-3xl sm:text-4xl font-serif">HSK {hsk}</h2>
        <div className="mt-2 opacity-70">HSK is the Official Chinese Proficiency Test. You will know here when you’re ready for it.</div>
      </div>
      <div className="ml-4">
        <ProgressRing progress={progress} showPercentage={true} radius={50} />
      </div>
    </div>
  );
};

const UserXP = () => {
  const { user } = useContext(ApiContext);
  const { data } = useGet(`profile/${user.id}`);

  if (!data) return null;

  return (
    <div className="py-8 rounded-3xl bg-white shadow-sm dark:bg-gray-800 flex items-center justify-center flex-col">
      <div className="text-4xl font-semibold opacity-80 my-3">
        <AnimatedNumber animateToNumber={data.total_experience} configs={animateNumbersConfigs} />
      </div>

      <div className="opacity-60">Total XP</div>
    </div>
  );
};

const UserCompletion = () => {
  const { user } = useContext(ApiContext);
  const { data } = useGet(`profile/${user.id}`);

  if (!data) return null;

  return (
    <div className="grid mt-8 lg:mt-0 grid-cols-2 gap-8 text-center items-stretch">
      <div className="py-12 rounded-3xl bg-white shadow-sm dark:bg-gray-800 flex flex-col justify-center items-center">
        <div className="text-4xl font-semibold opacity-80 my-3">
          <AnimatedNumber animateToNumber={data.videos_completed} configs={animateNumbersConfigs} />
        </div>
        <div className="opacity-60">Videos Completed</div>
      </div>

      <div className="py-12 rounded-3xl bg-white shadow-sm dark:bg-gray-800 flex flex-col justify-center items-center">
        <div className="text-4xl font-semibold opacity-80 my-3">
          <AnimatedNumber animateToNumber={data.total_flashcards} configs={animateNumbersConfigs} />
        </div>
        <div className="opacity-60">Words Mastered</div>
      </div>
    </div>
  );
};

export default MainDashboardPage;
