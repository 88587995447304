import { Pinyin } from "newComponents/Word";
import { useGet } from "Services";
import Spinner from "newComponents/Spinner";
import { useParams } from "react-router-dom";
import { NavigationBar } from "newComponents/base";
import { useContext } from "react";
import SimpleVideo, { VideoPreview } from "newComponents/SimpleVideo";
import Sentence from "newComponents/Sentence";
import NoDictionaryVideoPermission from "./NoDictionaryVideoPermission";
import { hasPermission } from "utils/permissions";
import { ApiContext } from "contexts/ApiContext";
import StrokeDiagram from "newComponents/StrokeDiagram";
import WordClasses from "./WordClasses";
import { useModals } from "contexts/ModalProvider";

export const WordDetail = ({ data, onClickVideo }) => {
  return (
    <div>
      <Pinyin word={data} />

      <WordClasses classes={data ? data.class : null} />

      <div className="mt-4">
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="flex items-center">
            {data.characters
              .filter(char => char.stroke)
              .map((char, index) => (
                <StrokeDiagram index={index} key={`${data.id}${index}`} {...char} />
              ))}
          </div>
        </div>
        <div className="text-xs opacity-50 mt-2">Click to see how to write these characters.</div>
      </div>

      <div className="lg:flex lg:justify-between mt-6 lg:mt-12 space-y-6 lg:space-y-0">
        <div className="rounded-xl bg-white dark:bg-gray-800 p-6 flex-1">
          <label>Definition</label>

          <div className="mt-2 flex flex-wrap text-xl">{data.definitions.join(", ")}</div>
          <div className="clear-both"></div>
        </div>
      </div>

      {data && data.sentences.length > 0 ? (
        <div className="mt-6 sm:mt-12 rounded-xl bg-white dark:bg-gray-800 p-6">
          <label>Example Sentences</label>
          <div className="text-lg">
            {data.sentences.map((i, index) => (
              <Sentence sentence={i} selectedWord={data} key={index} wordTarget="_self" />
            ))}
          </div>
          <div className="mt-4 text-xs text-gray-500">
            Click words in sentence to see definition, example sentence and videos in dictinoary.
          </div>
        </div>
      ) : null}

      {data && data.videos.length > 0 ? (
        <div className="mt-6 sm:mt-12">
          <label>Videos</label>
          <div className="mt-4 sm:mt-8 space-y-6 md:space-y-0 md:flex flex-wrap md:-mx-4 ">
            {data.videos.map((video, index) => (
              <div className="w-full md:w-1/2 md:px-4 md:mb-8" key={index}>
                <VideoPreview video={video} onClick={() => onClickVideo(video)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const DictionaryItemDetail = () => {
  const { user } = useContext(ApiContext);
  const { id } = useParams();
  const { loading, error, data } = useGet(`/dictionary/${id}`);

  const { present } = useModals();

  if (loading) return <Spinner center />;
  if (error) return "Error";
  if (!data) return null;

  function watchVideo(video) {
    if (hasPermission(user, "VIEW_DICTIONARY_VIDEOS")) {
      present({
        maxWidth: "max-w-6xl",
        children: <SimpleVideo video={video} />
      });
    } else {
      present({
        title: "HOLD ON",
        children: <NoDictionaryVideoPermission />
      });
    }
  }

  return (
    <div className="container mx-auto px-6 max-w-6xl">
      <NavigationBar title={data.text} backTo="/dictionary" backLabel="Back to Search" />
      <WordDetail data={data} onClickVideo={watchVideo} />
    </div>
  );
};

export default DictionaryItemDetail;
