const { Button } = require("newComponents/base");

const FlashcardResults = ({ overdue, tryMore }) => {
  return (
    <div className="px-10 pt-20 pb-12 text-center">
      <h1 className="">Nice Work! 🎉🎉🎉</h1>
      <div className="mt-6">
        {overdue ? (
          <>
            <div className="text-xl">You still have some cards due though.</div>
            <div className="flex justify-center mt-10">
              <Button className="text-2xl" onClick={tryMore}>
                Try more!
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="text-xl">Now go get an ice cream🍦!</div>
            <div className="flex justify-center mt-10">
              <Button className="text-2xl" onClick={tryMore}>
                OK!
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FlashcardResults;
