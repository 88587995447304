import { ApiContext } from "contexts/ApiContext";
import { useContext } from "react";
import { bestCoupon } from "utils/coupons";

const usePromo = () => {
  const { user } = useContext(ApiContext);

  if (!user) return {};
  if (user.subscription) return {};

  const campaign = null;

  if (campaign && new Date() <= campaign.endDate) return { campaign };

  return { coupon: bestCoupon(user) };
};

export default usePromo;
