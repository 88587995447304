import { createContext, useEffect, useState } from "react";
import { http } from "Services";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    user: null,
    next: null,
    lang: null,
    hasCoupon: false,
    studying: null,
    showNotifications: false,
    showFeedbackForm: false,
    hasFetchedCompletedItems: false,

    completedItems: {
      courseIds: [],
      levelIds: [],
      lessonIds: [],
      videoIds: []
    }
  });

  const [db, setDb] = useState(null);
  const [showHeaderMore, setShowHeaderMore] = useState(0);
  const [modals, setModals] = useState(0);
  const [showStreak, setShowStreak] = useState(false);
  const [showNoPermissionModal, setShowNoPermissionModal] = useState(null);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [lastLocation, setLastLocation] = useState(null);
  const [flashcardsMeta, setFlashcardsMeta] = useState(null);

  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const [userMeta, setUserMeta] = useState({});

  useEffect(() => {
    if (user && userMeta) {
      const key = `userMeta:${user.id}`;
      localStorage.setItem(key, JSON.stringify(userMeta));
    }
  }, [userMeta]);

  function markNotificationRead() {
    const ids = notifications.filter(i => i.read_at === null).map(i => i.id);
    if (ids.length > 0)
      http
        .post("/notifications/read", { ids })
        .then(() => {
          setNotifications(prev => prev.map(i => ({ ...i, read_at: new Date() })));
        })
        .catch(() => {});
  }

  useEffect(() => {
    if (state.showNotifications) {
      markNotificationRead();
    }
  }, [state.showNotifications]);

  useEffect(() => {
    const resizeHandler = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (!state.user) return;
    getCompltedItems();
  }, [state.user]);

  async function getCompltedItems() {
    try {
      const { data } = await http.get("/completed-items");
      setState(prev => ({
        ...prev,
        completedItems: { ...prev.completedItems, ...data },
        hasFetchedCompletedItems: true
      }));
    } catch {
      console.log("error", error);
    }
  }

  const setApiState = (key, value) => {
    setState(prev => ({ ...prev, [key]: value }));
  };

  const setLanguage = lang => {
    localStorage.setItem("DOMINO_LANG", lang);
    setApiState("lang", lang);
  };

  const {
    isLoading,
    error,
    user,
    next,
    studying,
    lang,
    showNotifications,
    showFeedbackForm,
    hasFetchedCompletedItems,
    completedItems
  } = state;

  return (
    <ApiContext.Provider
      value={{
        db,
        setDb,
        isLoading,
        error,
        user,
        studying,
        next,
        showNotifications,
        showFeedbackForm,
        setLanguage,
        lang,
        setApiState,
        hasFetchedCompletedItems,
        completedItems,
        modals,
        setModals,
        showHeaderMore,
        setShowHeaderMore,
        showNoPermissionModal,
        setShowNoPermissionModal,
        screenSize,
        lastLocation,
        setLastLocation,
        flashcardsMeta,
        setFlashcardsMeta,
        notifications,
        setNotifications,
        loadingNotifications,
        setLoadingNotifications,
        userMeta,
        setUserMeta,
        showStreak,
        setShowStreak
      }}>
      {children}
    </ApiContext.Provider>
  );
};
