import { Route, Switch } from "react-router-dom";
import { Button } from "newComponents/base";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import DialogDetail from "./DialogDetail";
import DialogList from "./DialogList";
import { useGet } from "Services";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";

const DialogsPage = () => {
  const { user } = useContext(ApiContext);

  if (user.permissions.includes("VIEW_DIALOGS")) return <DialogsApp />;

  return (
    <div className="container mx-auto px-6 max-w-6xl text-center ">
      <div className="py-16">
        <h1>Dialogs</h1>
        <div className="mt-2">We have 1000 dialogs waiting for you here.</div>
      </div>

      <div className="mx-auto max-w-4xl relative">
        <img className="hidden dark:block" src="http://materials.dominochinese.com/web-v4/dialogs-dark.jpeg" alt="dialogs" />
        <img className="block dark:hidden" src="http://materials.dominochinese.com/web-v4/dialogs.jpeg" alt="dialogs" />

        <div className="absolute bottom-0 w-full h-32 bg-gradient-to-b from-transparent to-gray-100 dark:to-gray-900"></div>
      </div>

      <div className="text-2xl mt-12">
        Domino Dialogs requires a <b className="text-green-500">Professional</b> Plan.
      </div>

      <div className="my-6 flex justify-center">
        <a href={process.env.REACT_APP_SUBSCRIBE_PAGE}>Check our Plans</a>
      </div>
    </div>
  );
};

const DialogsApp = () => {
  const { loading, error, meta } = useGet("/dialogs?limit=1");

  if (loading) return <Spinner label="Gathering dialogs, this will take a few seconds." center />;
  if (error) return <Errors error={error} />;
  if (!meta) return null;

  return (
    <Switch>
      <Route path="/dialogs/:id" component={DialogDetail} />
      <Route render={() => <DialogList meta={meta} />} />
    </Switch>
  );
};

export default DialogsPage;
