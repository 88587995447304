import { ApiContext } from "contexts/ApiContext";
import { Button, Select } from "newComponents/base";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { usePatch } from "Services";
import parseErrorMessage from "utils/errors";

const colourSchemes = {
  anki: ["#000000", "#ff0000", "#d89000", "#00a000", "#0000ff"],
  pleco: ["#777777", "#e30000", "#02b31c", "#1510f0", "#8900bf"],
  hanping: ["#a0a0a0", "#64b4ff", "#30b030", "#f08000", "#d00020"]
};

const ToneColors = () => {
  const { user, setApiState } = useContext(ApiContext);
  const [value, setValue] = useState(user.tone_colours);
  const [hasChanged, setHasChanged] = useState(false);
  const [submit, { loading }] = usePatch("tone-colours", {
    onComplete: () => {
      toast.success("Tone colors updated.");
      setApiState("user", { ...user, tone_colours: value });
    },
    onError: error => toast.error(parseErrorMessage(error))
  });

  return (
    <div>
      <div className="flex justify-between items-center">
        <label htmlFor="">Tone Colors:</label>
        <Select
          className="opacity-50"
          defaultValue="0"
          style={{ textAlignLast: "center" }}
          onChange={e => {
            setValue(colourSchemes[e.target.value]);
            setHasChanged(true);
          }}>
          <option disabled value="0">
            Select a Platform
          </option>
          <option value="anki">Anki/MDBG</option>
          <option value="hanping">Hanping</option>
          <option value="pleco">Pleco</option>
        </Select>
      </div>

      <div className="mt-3 flex space-x-4">
        <div className="flex flex-col items-center">
          <label className="text-xs font-normal">First</label>
          <input
            className="cursor-pointer bg-transparent border-0"
            type="color"
            value={value[1]}
            onChange={e => {
              setValue(prev => prev.map((i, index) => (index === 1 ? e.target.value : i)));
              setHasChanged(true);
            }}
          />
        </div>

        <div className="flex flex-col items-center">
          <label className="text-xs font-normal">Second</label>
          <input
            className="cursor-pointer bg-transparent border-0"
            type="color"
            value={value[2]}
            onChange={e => {
              setValue(prev => prev.map((i, index) => (index === 2 ? e.target.value : i)));
              setHasChanged(true);
            }}
          />
        </div>

        <div className="flex flex-col items-center">
          <label className="text-xs font-normal">Third</label>
          <input
            className="cursor-pointer bg-transparent border-0"
            type="color"
            value={value[3]}
            onChange={e =>
              setValue(prev => prev.map((i, index) => (index === 3 ? e.target.value : i)))
            }
          />
        </div>

        <div className="flex flex-col items-center">
          <label className="text-xs font-normal">Four</label>
          <input
            className="cursor-pointer bg-transparent border-0"
            type="color"
            value={value[4]}
            onChange={e => {
              setValue(prev => prev.map((i, index) => (index === 4 ? e.target.value : i)));
              setHasChanged(true);
            }}
          />
        </div>

        <div className="flex flex-col items-center">
          <label className="text-xs font-normal">Neutral</label>
          <input
            className="cursor-pointer bg-transparent border-0"
            type="color"
            value={value[0]}
            onChange={e => {
              setValue(prev => prev.map((i, index) => (index === 0 ? e.target.value : i)));
              setHasChanged(true);
            }}
          />
        </div>
      </div>

      {hasChanged ? (
        <div className="mt-4 flex space-x-4">
          <Button
            loading={loading}
            disabled={loading}
            onClick={() =>
              submit({
                tone_0: value[0],
                tone_1: value[1],
                tone_2: value[2],
                tone_3: value[3],
                tone_4: value[4]
              })
            }>
            Save
          </Button>
          <Button
            gray
            onClick={() => {
              setValue(user.tone_colours);
              setHasChanged(false);
            }}>
            Cancel
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ToneColors;
