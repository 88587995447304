import PropTypes from "prop-types";
import { useState } from "react";

const VideoSpeedControls = ({ playbackRate, setPlaybackRate }) => {
  const [showControls, setShowControls] = useState(false);

  const options = [0.75, 1, 1.25, 1.5, 1.75, 2];

  return (
    <div className="video-playback ml-2 relative text-xs">
      {showControls ? (
        <div
          className={`absolute flex flex-col items-end right-0 whitespace-nowrap bottom-8 space-y-2`}>
          {options.map((i, index) => (
            <span
              key={index}
              className={`video-control-menu-item
                  ${playbackRate === i ? "text-teal-500" : ""}
                  `}
              onClick={() => {
                setPlaybackRate(i);
                setShowControls(prev => !prev);
              }}>
              &times; {i}
            </span>
          ))}
        </div>
      ) : null}

      <span
        className="cursor-pointer whitespace-nowrap"
        onClick={() => setShowControls(prev => !prev)}>
        &times; {playbackRate}
      </span>
    </div>
  );
};

VideoSpeedControls.propTypes = {
  setPlaybackRate: PropTypes.func.isRequired,
  playbackRate: PropTypes.number.isRequired
};

export default VideoSpeedControls;
