import PropTypes from "prop-types";

const getFileType = mime => {
  switch (mime) {
    case "application/pdf":
      return "pdf";
    case "application/zip":
      return "zip";
    case "audio/mpeg":
      return "mp3";
    default:
      return "doc";
  }
};

const Attachment = ({ file: { name, mime_type, src } }) => (
  <div className="flex items-center text-center" href={src} target="_blank" rel="noreferrer">
    <div className={`fi fi-size-xs fi-${getFileType(mime_type)}`}>
      <div className="fi-content">{getFileType(mime_type)}</div>
    </div>
    <div className="ml-4">{name}</div>
  </div>
);

Attachment.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mime_type: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
  }).isRequired
};

export default Attachment;
