import { useGet } from "Services";
import { Button, PrimaryLink } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import { IoIosCheckmarkCircle } from "react-icons/io";
import ProgressRing from "newComponents/ProgressRing";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import { useHistory } from "react-router";
import { toABC } from "utils/string";
import AnimatedNumber from "react-animated-numbers";

const onBoardingVideos = {
  "a66fd42b-51ce-4a67-ad70-5d9cb21f8555": {
    title: "Dashboard Unlocked",
    description:
      "Let's check out the Dashboard and learn how to navigate around the different Domino Tools.",
    src: "https://materials.dominochinese.com/web-v4/v5-onboarding-videos/1.+Dashboard+Walkthrough+1.0+Final.mp4"
  },
  "0e749a2b-6faa-44d1-93a2-e3b281b00973": {
    title: "Flashcard Review",
    description:
      "Review Flashcards is a great way to memorize what you have learned - Find out how to get the most out of them here!",
    src: "https://materials.dominochinese.com/web-v4/v5-onboarding-videos/2.+Review+Page+1.0+Final.mp4"
  },
  "811a705c-5db2-438e-805f-05096272b313": {
    title: "Course Overview",
    description: "Time to get a 360 view of the Domino Courses - Felix explains how.",
    src: "https://materials.dominochinese.com/web-v4/v5-onboarding-videos/3.+Course+Overview+Page+1.0+Final.mp4"
  },
  "fc07e3ee-2cd1-4348-91f9-b22df1971797": {
    title: "Domino Open and Domino Classroom",
    description:
      "Great job on completing the first 4 lessons of Chapter 1. Do you want to study with classmates and teacher or on your own? Lets talk a bit about how you can continue on your path to fluency!",
    src: "https://materials.dominochinese.com/web-v4/v5-onboarding-videos/4.+What+To+Do+Next+Hb.mp4"
  }
};

const animateNumberOptions = [
  { mass: 1, tension: 130, friction: 40 },
  { mass: 2, tension: 140, friction: 40 },
  { mass: 3, tension: 150, friction: 40 }
];

export const PassLessonResult = ({ id }) => {
  const { db } = useContext(ApiContext);
  const { loading, data } = useGet(`frontend/lessons/${id}`);

  const lesson = db.lessons.find(i => i.id === id);
  const chapter = db.chapters.find(i => i.id === lesson.chapter_id);
  const level = db.levels.find(i => i.id === chapter.level_id);
  const course = db.courses.find(i => i.id === level.course_id);

  const fetchLevel = useGet(`/levels/${level.id}`);

  const onboardVideo = onBoardingVideos[id];

  const chapterLessons = db.getChapterLessons(chapter.id);
  const currentLessonIndex = chapterLessons.findIndex(i => i.id === id);
  let storyTimeVideo = null;
  if (currentLessonIndex + 1 === chapterLessons.length) {
    const chapterVideos = db.getChapterVideos(chapter.id);
    if (chapterVideos.length > 0) storyTimeVideo = chapterVideos[0];
  }

  if (loading) return <Spinner center />;
  if (!data) return null;

  return (
    <div>
      <h1>Fantastic 🎉🎉🎉</h1>
      <div className="mt-4 text-2xl leading-relaxed text-green-600">
        <div className="flex items-center">
          <span className="font-semibold">
            You have passed {course.name} / {chapter.name} / Lesson {toABC(lesson.ordering)}
          </span>
          <IoIosCheckmarkCircle className="flex-shrink-0 text-4xl ml-4" />
        </div>
        <div>{data.name}</div>
      </div>

      <div className="flex justify-between items-end">
        <div className="space-y-2">
          <div className="flex space-x-2 items-baseline">
            <AnimatedNumber
              animateToNumber={data.words.length}
              fontStyle={{ fontSize: 21, fontWeight: "bold" }}
              configs={animateNumberOptions}
            />
            <span>new words learned.</span>
          </div>

          <div className="flex space-x-2 items-baseline">
            <AnimatedNumber
              animateToNumber={data.grammars.length}
              fontStyle={{ fontSize: 21, fontWeight: "bold" }}
              configs={animateNumberOptions}
            />
            <span>new grammar points learned.</span>
          </div>
        </div>

        {fetchLevel.loading ? null : (
          <div>
            <ProgressRing
              stroke={10}
              progress={fetchLevel.data.progress * 100}
              showPercentage={true}
            />
            <div className="text-gray-600">{level.name} Progress</div>
          </div>
        )}
      </div>

      <div className="flex items-center space-x-8 mt-12 justify-between">
        <Button to="/study" back>
          Go to Lesson Overview
        </Button>
        {storyTimeVideo ? (
          <PrimaryLink to={`/study/levels/${level.id}?storyTime=${storyTimeVideo.id}`}>
            Go to Storytime
          </PrimaryLink>
        ) : (
          <PrimaryLink to="/">Go to Dashboard</PrimaryLink>
        )}
      </div>

      {onboardVideo ? (
        <div className="mt-8 bg-green-600 dark:bg-green-800 text-white p-8 rounded-3xl">
          <div className="text-2xl leading-relaxed font-bold">{onboardVideo.title}</div>
          <div className="opacity-80 text-base mt-2">{onboardVideo.description}</div>

          <div className="w-full aspect-w-16 aspect-h-9 bg-gray-800 mt-4">
            <video className="w-ull" src={onboardVideo.src} controls></video>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const PassLevelResult = ({ id }) => {
  const { studying, setApiState, db } = useContext(ApiContext);
  const history = useHistory();

  const level = db.levels.find(i => i.id === id);
  const course = db.courses.find(i => i.id === level.course_id);
  const courseLevels = db.getCourseLevels(course.id);
  const index = courseLevels.findIndex(i => i.id === id);
  const nextLevel = courseLevels[index + 1];

  function selectNextLevel() {
    const firstChapter = db.getLevelChapters(nextLevel.id)[0];
    const firstLesson = db.getChapterLessons(firstChapter.id)[0];
    const firstVideo = db.getLessonVideos(firstLesson.id)[0];
    const newStudying = {
      ...studying,
      level_id: nextLevel.id,
      chapter_id: firstChapter.id,
      lesson_id: firstLesson.id,
      video_id: firstVideo.id
    };
    setApiState("studying", newStudying);
    history.push(
      `/study/${newStudying.course_id}/${newStudying.level_id}/${newStudying.chapter_id}/${newStudying.lesson_id}`
    );
  }

  function reviewLevelFlashcards() {
    history.push("/review");
  }

  return (
    <div className="">
      <h1>Fantastic 🎉🎉🎉</h1>
      <div className="mt-4 text-2xl leading-relaxed text-green-600">
        <div className="flex items-center">
          <span className="font-bold">You have passed {level.name}!</span>
          <IoIosCheckmarkCircle className="flex-shrink-0 text-4xl ml-4" />
        </div>
      </div>

      <div className="flex justify-between items-end mt-8">
        <div>
          <div>
            <span className="text-lg font-bold">{level.word_count}</span> new words learned.
          </div>
          <div>
            <span className="text-lg font-bold">{level.grammar_count}</span> new grammar points
            learned.
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-8 mt-12">
        <PrimaryLink to="/">Go to Dashboard</PrimaryLink>
        {nextLevel ? (
          <Button next onClick={selectNextLevel}>
            Go to next Level - {nextLevel.name}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
