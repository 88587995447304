import { createContext, useContext, useEffect, useState } from "react";
import { v4 } from "uuid";

export const ModalContext = createContext({});

export const useModals = (modal = {}) => {
  const { prepare, present, hide, clear } = useContext(ModalContext);
  const [modalId, setModalId] = useState(null);

  useEffect(() => {
    const id = prepare(modal);
    setModalId(id);

    return () => clear(id);
  }, []);

  return {
    present: props => present({ id: modalId, ...props }),
    hide: () => hide(modalId)
  };
};

const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    function keyboardHandler(e) {
      const showingModals = modals.filter(i => i.show);
      if (showingModals.length === 0) return;
      const modal = showingModals[showingModals.length - 1];
      if (e.key === "Escape" && !modal.isBeingPresented) hide(modal.id);
    }

    document.addEventListener("keyup", keyboardHandler);

    return () => {
      document.removeEventListener("keyup", keyboardHandler);
    };
  }, [modals]);

  useEffect(() => {
    if (modals.filter(i => i.show).length > 0) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modals]);

  function prepare(modal) {
    const id = v4();
    setModals(prev => [...prev, { ...modal, id, show: false }]);
    return id;
  }

  function present({ id, ...rest }) {
    setModals(prev => prev.map(i => (i.id === id ? { ...i, ...rest, show: true } : i)));
  }

  function hide(id) {
    setModals(prev =>
      prev.map(i => {
        if (i.id === id) {
          if (i.hideHandler) i.hideHandler();
          return { ...i, show: false };
        }
        return i;
      })
    );
  }

  function clear(id) {
    setModals(prev => prev.filter(i => i.id !== id));
  }

  function scale(id) {
    const showingModals = modals.filter(i => i.show);
    const showingModalsCount = showingModals.length;
    if (showingModalsCount <= 1) return 1;
    const index = showingModals.findIndex(i => i.id === id) + 1;
    const x = 1 - (showingModalsCount - index) / showingModalsCount / 20;
    return x;
  }

  const hasModals = modals.filter(i => i.show).length > 0;

  const showingModals = modals.filter(i => i.show);
  const topModal = showingModals.length > 0 ? showingModals[showingModals.length - 1] : null;

  return (
    <ModalContext.Provider value={{ hasModals, prepare, present, hide, clear }}>
      {children}

      {modals.map(modal => {
        return (
          <div
            key={modal.id}
            className={`fixed inset-0 bg-gray-200 dark:bg-gray-900 z-50 animate-ease-5 flex flex-col overflow-auto sm:px-8 lg:items-center
            ${topModal && modal.id === topModal.id ? "backdrop-blur-xl" : ""}
                     ${
                       modal.isBeingPresented
                         ? "bg-opacity-100"
                         : "bg-opacity-90 dark:bg-opacity-90"
                     }
                     ${modal.center ? "lg:justify-center" : ""}
                     ${modal.show ? "opacity-100 visiable" : "opacity-0 invisible"}
                     `}
            onClick={() => {
              if (!modal.isBeingPresented) {
                hide(modal.id);
                if (modal.hideHandler) modal.hideHandler();
              }
            }}>
            <div
              className={`w-full flex flex-col flex-1 relative bg-gray-50 dark:bg-gray-800
                        ${modal.maxWidth || "max-w-4xl"}
                        ${modal.center ? "sm:flex-none sm:overflow-auto" : ""}
                        ${modal.center && !modal.noPadding ? "" : ""}
                        ${
                          modal.show
                            ? `opacity-1 mt-16 md:mt-24 ${modal.center ? "lg:-mt-10" : ""}`
                            : `opacity-0 mt-36 ${modal.center ? "lg:mt-0" : ""}`
                        }
                        ${modal.center ? "rounded-t-xl lg:rounded-3xl" : "rounded-t-3xl"}
                        `}
              style={{
                top: `${(1 - scale(modal.id)) * 10})px`,
                transform: `scale(${scale(modal.id)})`,
                transition:
                  "margin 0.5s cubic-bezier(0.3, 2.1, 0.55, 1) 0s, opacity 0.5s ease, transform 0.5s cubic-bezier(0.3, 2.1, 0.55, 1) 0s"
              }}
              onClick={e => e.stopPropagation()}>
              {modal.isBeingPresented ? null : (
                <div
                  className="absolute top-0 right-0 px-6 py-4 z-40 opacity-60 cursor-pointer hover:opacity-100 text-4xl test-close-modal-button"
                  onClick={() => hide(modal.id)}>
                  &times;
                </div>
              )}

              {modal.title ? (
                <div className="px-6 py-10 pb-0 md:px-10">
                  <h2 className={modal.titleClassName || ""}>{modal.title}</h2>

                  {modal.subtitle ? (
                    <div className="text-lg opacity-60 mt-2">{modal.subtitle}</div>
                  ) : null}
                </div>
              ) : null}

              <div
                className={`relative ${
                  modal.noPadding ? (modal.title ? "pt-6 md:pt-10" : "") : "p-6 md:p-10"
                }`}
                style={{ minHeight: 200 }}>
                {modal.show || modal.keepChildren ? modal.children : null}
              </div>
            </div>
          </div>
        );
      })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
