import { ApiContext } from "contexts/ApiContext";
import { useModals } from "contexts/ModalProvider";
import usePromo from "hooks/usePromo";
import moment from "moment";
import { useContext } from "react";
import Countdown from "./Countdown";
import CouponModalContentNineDollars from "./CouponModalContentNineDollars";

const TopBanner = () => {
  const { user } = useContext(ApiContext);

  const { coupon, campaign } = usePromo();

  if (!user) return null;

  if (user.subscription) return null;

  if (campaign) return <CampaignView campaign={campaign} />;

  return <DefaultCouponMessage coupon={coupon} />;
};

const CampaignView = ({ campaign }) => {
  const modal = useModals();

  const daysLeft = moment.duration(moment(campaign.endDate).diff(moment())).asDays();

  let bgColor = "bg-blue-500 hover:bg-blue-600 dark:bg-blue-700";
  if (daysLeft <= 3) bgColor = "bg-orange-500 hover:bg-orange-600 dark:bg-orange-700";
  if (daysLeft <= 1) bgColor = "bg-red-500 hover:bg-red-600 dark:bg-red-700";

  return (
    <div
      className={`px-6 py-3 text-white hover:text-white flex flex-col justify-center items-center border-b dark:border-gray-700 relative cursor-pointer font-normal text-center space-y-1
        ${bgColor}
      `}
      onClick={() => window.open(process.env.REACT_APP_SUBSCRIBE_PAGE, "_blank")}
    >
      <div>
        <span>
          Grab the biggest discount yet! <b>Save 40%</b>. Forever.
        </span>{" "}
        <span
          className="underline font-semibold opacity-80 hover:opacity-100 ml-2"
          onClick={(e) => {
            e.stopPropagation();
            modal.present({
              noPadding: true,
              maxWidth: "max-w-6xl",
              children: (
                <CouponModalContentNineDollars
                  campaign={campaign}
                  checkout={() => {
                    modal.hide();
                    window.open(process.env.REACT_APP_SUBSCRIBE_PAGE, "_blank");
                  }}
                />
              ),
            });
          }}
        >
          Watch announcement!
        </span>
      </div>
      <div className="flex justify-center space-x-2">
        <Countdown inline date={campaign.endDate} />
        <span>left.</span>
      </div>
    </div>
  );
};

const DefaultCouponMessage = () => (
  <a
    className="px-6 py-3 bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 text-white hover:text-white flex justify-center items-center space-x-8 border-b dark:border-gray-700 relative cursor-pointer font-normal text-center"
    href={process.env.REACT_APP_SUBSCRIBE_PAGE}
  >
    <span className="text-sm">
      Psst, you can currently only view Lesson A in the Beginner Chinese Course. This is only 1% of our content. Unlock the{" "}
      <span className="font-semibold">WHOLE platform</span>!
    </span>
  </a>
);

export default TopBanner;
