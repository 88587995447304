import { useContext, useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { FaPlay } from "react-icons/fa";
import { CgScreen, CgScreenWide } from "react-icons/cg";
import screenfull from "screenfull";
import { ApiContext } from "contexts/ApiContext";
import { post } from "api";
import { parseErrorMessage } from "@iforwms/helpers-js";
import VideoVolumeControls from "./VideoVolumeControls";
import VideoQualityControls from "./VideoQualityControls";
import VideoSpeedControls from "./VideoSpeedControls";
import VideoPlaybackControls from "./VideoPlaybackControls";
import { getNumberFromLocalStorage } from "../../helpers";
import Spinner from "newComponents/Spinner";
import { AiOutlineFullscreen } from "react-icons/ai";
import { http } from "Services";

const generateVideoPlayerConfig = video => {
  if (video.subtitle_src) {
    return {
      file: {
        attributes: {
          crossOrigin: "true"
          // preload: "none"
        },
        tracks: [
          {
            kind: "subtitles",
            src: video.subtitle_src,
            srcLang: video.subtitle_lang,
            default: true
          }
        ]
      }
    };
  }
  return {
    file: {
      attributes: {
        // preload: "none"
      }
    }
  };
};

const VideoPlayer = ({
  video,
  onEnded,
  wideMode,
  toggleWideMode,
  forcePlaying = true,
  forceReplay = 0,
  didPlayPause,
  enableKeyboard = true,
  alwaysWide = false
}) => {
  const { user, setApiState, screenSize } = useContext(ApiContext);
  const [wasPlaying, setWasPlaying] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [isSwitchingVideoQuality, setIsSwitchingVideoQuality] = useState(false);
  const [duration, setDuration] = useState(0);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(
    getNumberFromLocalStorage("DOMINO_PLAYBACK_RATE")
  );
  const [volume, setVolume] = useState(getNumberFromLocalStorage("DOMINO_VOLUME"));
  const [muted, setMuted] = useState(false);
  const [preferredSrc, setPreferredSrc] = useState(video.preferred_src);
  const [showSubtitles, setShowSubtitles] = useState(
    Boolean(parseInt(localStorage.getItem("video.showCC")))
  );

  const player = useRef();

  useEffect(() => {
    if (showSubtitles) {
      localStorage.setItem("video.showCC", 1);
    } else {
      localStorage.removeItem("video.showCC");
    }
    if (
      player &&
      player.current &&
      player.current.getInternalPlayer() &&
      player.current.getInternalPlayer().textTracks.length > 0
    )
      player.current.getInternalPlayer().textTracks[0].mode = showSubtitles ? "showing" : "hidden";
  }, [player, showSubtitles]);

  useEffect(() => {
    const currentProgress = getNumberFromLocalStorage(`DOMINO_DURATION_${video.id}`, 0);
    if (currentProgress && currentProgress < 0.95) {
      player.current.seekTo(currentProgress);
    }
  }, [video]);

  const [keyboardCounter, setKeyboardCounter] = useState(0);
  const [justPressKey, setJustPressKey] = useState(null);
  useEffect(() => {
    const spaceHandler = e => {
      if (enableKeyboard) {
        if (e.code === "Space") {
          e.preventDefault();
        }
        setJustPressKey(e.code);
        setKeyboardCounter(prev => prev + 1);
      }
    };

    document.addEventListener("keydown", spaceHandler);
    return () => {
      document.removeEventListener("keydown", spaceHandler);
    };
  }, [enableKeyboard, video]);

  useEffect(() => {
    switch (justPressKey) {
      case "Space":
        togglePlay();
        break;
      case "ArrowRight":
        if (player.current && player.current.getDuration()) {
          const currentTime = player.current.getCurrentTime();
          player.current.seekTo(Math.min(currentTime + 5, player.current.getDuration()));
        }
        break;
      case "ArrowLeft":
        if (player.current && player.current.getDuration()) {
          const currentTime = player.current.getCurrentTime();
          player.current.seekTo(Math.max(currentTime - 5, 0));
        }
        break;
    }
    if (keyboardCounter > 0) sethasShownKeyboardTips(1);
  }, [keyboardCounter]);

  const [hasShownKeyboardTips, sethasShownKeyboardTips] = useState(
    localStorage.getItem("video.hasShownKeyboardTips")
  );
  useEffect(() => {
    if (hasShownKeyboardTips) {
      localStorage.setItem("video.hasShownKeyboardTips", "1");
    }
  }, [hasShownKeyboardTips]);

  useEffect(() => {
    if (didPlayPause) didPlayPause(playing, forcePlaying);
    setPlaying(forcePlaying);
  }, [forcePlaying]);

  useEffect(() => {
    if (forceReplay > 0) {
      player.current.seekTo(0);
    }
  }, [forceReplay]);

  function onReady() {
    if (
      player &&
      player.current &&
      player.current.getInternalPlayer() &&
      player.current.getInternalPlayer().textTracks.length > 0
    )
      player.current.getInternalPlayer().textTracks[0].mode = showSubtitles ? "showing" : "hidden";
  }

  const togglePlay = () => {
    setWasPlaying(playing);
    if (didPlayPause) {
      didPlayPause(!playing, !playing);
    }
    setPlaying(prev => !prev);
  };

  const setVideoQuality = quality => {
    setIsSwitchingVideoQuality(true);

    post("video-quality", { quality, video_id: video.id })
      .then(({ data }) => {
        setPreferredSrc(data.preferred_src);
        setIsSwitchingVideoQuality(false);
        if (user) {
          setApiState("user", {
            ...user,
            video_quality: quality
          });
        }
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to update video quality</Trans>));
        setIsSwitchingVideoQuality(false);
      });
  };

  const onSeekChange = played => {
    setPlayed(played);
    player.current.seekTo(played);
    localStorage.setItem(`DOMINO_DURATION_${video.id}`, played);
  };

  const onProgress = progress => {
    setLoaded(progress.loaded);
    setPlayed(progress.played);

    if (!isSwitchingVideoQuality) {
      localStorage.setItem(`DOMINO_DURATION_${video.id}`, progress.played);

      if (progress.played === 1) {
        localStorage.removeItem(`DOMINO_DURATION_${video.id}`);
      }
    }
  };

  const onClickFullscreen = () => {
    if (player) screenfull.request(player.current.getInternalPlayer());
  };

  return (
    <>
      <div
        className={`text-white relative bg-gray-900 rounded-xl overflow-hidden w-full
        video-player-container
        ${playing ? "is-playing" : "is-pause"}
      `}>
        <div className="w-full" style={{ paddingTop: "56.25%" }}></div>
        <ReactPlayer
          ref={player}
          playsinline={true}
          className="absolute inset-0"
          width="100%"
          height="100%"
          config={generateVideoPlayerConfig(video)}
          url={preferredSrc}
          playing={playing}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={onReady}
          onProgress={onProgress}
          onDuration={setDuration}
          controls={screenSize.width <= 640}
          onEnded={() => {
            setPlaying(false);
            if (onEnded) onEnded();
            http.post(`videos/${video.id}/watch`);
          }}
          pip={true}
        />

        <div
          className="video-full-play-pause-button absolute inset-0 z-20 items-center justify-center cursor-pointer hidden sm:flex"
          onClick={togglePlay}>
          <FaPlay
            size={41}
            className={`animate-ease-2
              ${playing ? "opacity-0" : "opacity-100"}
              `}
          />
        </div>

        <div className="video-bottom-control py-1 z-30 bottom-0 left-0 right-0 absolute hidden sm:block">
          <div className="px-4 sm:px-8 flex space-x-2 lg:space-x-6 items-center py-2 text-xs">
            <VideoPlaybackControls
              duration={duration}
              playing={playing}
              played={played}
              loaded={loaded}
              togglePlay={togglePlay}
              onSeekChange={onSeekChange}
              onSeekBeforeChange={() => setWasPlaying(playing)}
              onSeekAfterChange={() => setPlaying(wasPlaying)}
              onBuffer={() => setBuffering(true)}
              onBufferEnd={() => setBuffering(false)}
            />

            <VideoSpeedControls
              playbackRate={playbackRate}
              setPlaybackRate={rate => {
                setPlaybackRate(parseFloat(rate));
                localStorage.setItem("DOMINO_PLAYBACK_RATE", rate);
              }}
            />

            {video.subtitle_src ? (
              <div>
                <span
                  className={`cursor-pointer font-sans hover:text-teal-700
                    ${showSubtitles ? "text-teal-600" : ""}`}
                  onClick={() => setShowSubtitles(prev => !prev)}>
                  CC
                </span>
              </div>
            ) : null}

            <VideoQualityControls
              setVideoQuality={setVideoQuality}
              videoQuality={user.video_quality}
              cloudflare_src={video.cloudflare_src}
              hd_src={video.hd_src}
              sd_src={video.sd_src}
              sq_src={video.sq_src}
            />

            <VideoVolumeControls
              volume={volume}
              showSpeedControls={true}
              handleClick={() => setMuted(prev => !prev)}
              muted={muted}
              setVolume={value => {
                setVolume(parseFloat(value));
                localStorage.setItem("DOMINO_VOLUME", value);
              }}
            />

            {alwaysWide ? null : (
              <div className="hidden xl:block">
                {wideMode ? (
                  <CgScreen
                    title="Default View"
                    className="cursor-pointer"
                    size={21}
                    onClick={toggleWideMode}
                  />
                ) : (
                  <CgScreenWide
                    title="Theater Mode"
                    className="cursor-pointer"
                    size={21}
                    onClick={toggleWideMode}
                  />
                )}
              </div>
            )}

            <div className="cursor-pointer" onClick={onClickFullscreen}>
              <AiOutlineFullscreen size={21} />
            </div>
          </div>
        </div>

        {buffering ? <Spinner className="z-50" center /> : null}
      </div>

      {hasShownKeyboardTips ? null : (
        <div className="mt-4 text-sm text-gray-500">
          Press space bar to pause or play video, press arrow left or right to backward or forward.
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
