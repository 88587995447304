import { ApiContext } from "contexts/ApiContext";
import { Button, NavigationBar } from "newComponents/base";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toABC } from "utils/string";
import FlashcardsContainer from "./FlashcardsContainer";
import { useGet, usePost } from "Services";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";
import { RiSettings5Fill } from "react-icons/ri";
import FlashcardSettings from "newComponents/FlashcardSettings";
import parseErrorMessage from "utils/errors";
import FlashcardResults from "./FlashcardResults";
import { toast } from "react-toastify";
import { LessonReviewContext } from "contexts/LessonReviewProvider";
import { useModals } from "contexts/ModalProvider";
import { FlashcardsContext } from "contexts/FlashcardsProvider";

export const ReviewLessonContext = createContext({});

const ReviewLesson = () => {
  const { id } = useParams();
  const { db } = useContext(ApiContext);
  const { refresh, notReview, overdue, setCompletedNotReviewTime, setCompletedOverdueTime } =
    useContext(LessonReviewContext);
  const {
    cardFront,
    font,
    autoPlayAudio,
    enableAnimation,
    setCardFront,
    setFont,
    setAutoPlayAudio,
    setEnableAnimation
  } = useContext(FlashcardsContext);

  const lesson = db.lessons.find(i => i.id === id);
  const chapter = db.chapters.find(i => i.id === lesson.chapter_id);
  const level = db.levels.find(i => i.id === chapter.level_id);
  const course = db.courses.find(i => i.id === level.course_id);

  const fullName = `${course.name} - ${chapter.name} - ${toABC(lesson.ordering)} - ${lesson.name}`;

  const url = `/frontend/lessons/${id}`;
  const { loading, error, data } = useGet(url);
  const [totalScore, setTotalScore] = useState(0);

  const settingsModal = useModals();
  const completeModal = useModals();

  const [saveLessonReviewScore, saveLessonReviewScoreRes] = usePost(`/lesson-reviews/${id}`, {
    onComplete() {
      refresh();
    },
    onError(error) {
      toast.error(parseErrorMessage(error));
    }
  });

  function onCompleted(score) {
    if (saveLessonReviewScoreRes.loaded) return;

    saveLessonReviewScore({ score });
    completeModal.present({
      center: true,
      isBeingPresented: true,
      children: <FlashcardResults result={{ score }} />
    });

    if (notReview.find(i => i.id === id)) setCompletedNotReviewTime(prev => [...prev, +new Date()]);
    if (overdue.find(i => i.id === id)) setCompletedOverdueTime(prev => [...prev, +new Date()]);
  }

  return (
    <ReviewLessonContext.Provider value={{ totalScore, setTotalScore }}>
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between container mx-auto px-6 2xl:px-12">
          <NavigationBar
            title="Flashcards"
            subtitle={fullName}
            backTo={"/review"}
            backLabel="Review"
          />
          <Button
            leftIcon={
              <RiSettings5Fill
                size={27}
                onClick={() => {
                  settingsModal.present({
                    title: "Lesson Review Flashcards Settings",
                    center: true,
                    children: (
                      <FlashcardSettings
                        lessonReview={true}
                        onSave={config => {
                          settingsModal.hide();
                          setCardFront(config.cardFront);
                          setFont(config.font);
                          setAutoPlayAudio(config.autoPlayAudio);
                          setEnableAnimation(config.enableAnimation);
                        }}
                        initialValues={{ cardFront, font, autoPlayAudio, enableAnimation }}
                      />
                    )
                  });
                }}
              />
            }
          />
        </div>

        <div className="flex-1 relative">
          {loading ? (
            <Spinner center />
          ) : error ? (
            <Errors error={error} />
          ) : data ? (
            <div className="relative">
              <FlashcardsContainer data={data} onCompleted={onCompleted} />
              {saveLessonReviewScoreRes.loading ? <Spinner center label="Loading.." /> : null}
            </div>
          ) : null}
        </div>
      </div>
    </ReviewLessonContext.Provider>
  );
};

export default ReviewLesson;
