import { useState, useEffect } from "react";
import { useGet, usePost } from "Services";
import moment from "moment";
import { FaComments } from "react-icons/fa";
import { Button, Text } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import { useModals } from "contexts/ModalProvider";
import { toast } from "react-toastify";
import parseErrorMessage from "utils/errors";

const Comment = ({
  videoId,
  comment: { id, is_moderator, avatar, username, text, created_at, replies = [] },
  reply
}) => {
  return (
    <div className="md:flex py-4 border-t border-gray-100 dark:border-gray-700">
      <div
        className="w-12 h-12 rounded-full border border-gray-100 dark:border-gray-700 bg-no-repeat bg-cover mr-4 flex-grow-0 flex-shrink-0 hidden md:block"
        style={{ backgroundImage: `url(${avatar})` }}
      />
      <div className="flex-grow">
        <div className="flex">
          <div
            className="w-8 h-8 rounded-full border border-gray-100 dark:border-gray-700 bg-no-repeat bg-cover mr-4 flex-grow-0 flex-shrink-0 md:hidden"
            style={{ backgroundImage: `url(${avatar})` }}
          />
          <div>
            <div className="sm:flex">
              <label className={is_moderator ? "text-green-500" : ""}>{username}</label>
              <div className="text-gray-500 sm:ml-4">{moment(created_at).fromNow()}</div>
            </div>

            <div className={`mb-2`} dangerouslySetInnerHTML={{ __html: text }} />

            <div>
              <Button
                className="text-xs"
                rightIcon={<FaComments />}
                onClick={() => reply({ parentId: id, id: videoId })}>
                Reply
              </Button>
            </div>
          </div>
        </div>

        {replies.length > 0 ? (
          <div className="mt-4 ml-4 md:ml-0">
            {replies.map(i => (
              <Comment key={i.id} videoId={videoId} comment={i} reply={reply} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ReplyForm = ({ replying, hide, didReply }) => {
  const [text, setText] = useState("");

  const [submit, { loading }] = usePost("/comments", {
    onComplete: () => {
      didReply();
      hide();
    },
    onError: error => {
      toast.error(parseErrorMessage(error));
    }
  });

  useEffect(() => {
    setText("");
  }, [replying]);

  return (
    <div className="flex-1">
      <div className="mt-2 flex-1">
        <Text value={text} rows={12} onChange={e => setText(e.target.value)} />
      </div>

      <div className="mt-4">
        <Button
          loading={loading}
          disabled={loading}
          className="text-xl"
          onClick={() =>
            submit({
              text,
              parent_id: replying.parentId,
              commentable_id: replying.id,
              commentable_type: "video"
            })
          }>
          Submit
        </Button>
      </div>
    </div>
  );
};

const VideoComments = ({ videoId, onShowModal, onHideModal }) => {
  const [fetchComments, { loading, data }] = useGet(`comments/video/${videoId}`, true);

  const { present, hide } = useModals();

  useEffect(fetchComments, []);

  if (!data) return null;

  const hasComments = data.length > 0;

  return (
    <div className="bg-white dark:bg-gray-800 my-8 rounded-xl p-6 shadow-sm">
      <div className="flex justify-between">
        <div className="flex items-center">
          <h3>Comments</h3>
          {loading ? <Spinner className="ml-4" size={18} /> : null}
        </div>
        <Button
          className="text-sm"
          onClick={() => {
            present({
              title: "Reply",
              center: true,
              hideHandler: onHideModal,
              children: (
                <ReplyForm
                  hide={hide}
                  replying={{ id: videoId }}
                  didReply={() => fetchComments()}
                />
              )
            });
            onShowModal();
          }}>
          Leave a Comment
        </Button>
      </div>

      {hasComments ? null : <div className="mt-2 opacity-70">Leave your first comment here!</div>}

      {hasComments ? (
        <div className="mt-4 break-all">
          {data.map(comment => (
            <div key={comment.id} className="">
              <Comment
                videoId={videoId}
                comment={comment}
                reply={i => {
                  present({
                    title: "Reply",
                    center: true,
                    hideHandler: onHideModal,
                    children: (
                      <ReplyForm hide={hide} replying={i} didReply={() => fetchComments()} />
                    )
                  });
                  onShowModal();
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default VideoComments;
