import { Switch, Route, Redirect, useParams } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";
import { Button } from "newComponents/base";
import { ApiContext } from "contexts/ApiContext";
import Page from "newComponents/Page";
import FlashcardsPage from "./FlashcardPage";
import DeckList from "./decks";
import { http } from "Services";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";

const FlashcardsPages = () => {
  const { user } = useContext(ApiContext);
  if (user.permissions.includes("VIEW_FLASHCARDS")) return <UserFlashcardPages />;
  return <NoPermissionView />;
};

export const FlashcardsMetaContext = createContext();

const UserFlashcardPages = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { flashcardsMeta, setFlashcardsMeta } = useContext(ApiContext);

  useEffect(() => {
    if (flashcardsMeta === null) {
      setLoading(true);
      http
        .get("/flashcards/filter")
        .then((res) => {
          setLoading(false);
          console.log("res", res);
          setFlashcardsMeta({
            courses: res.data.meta.courses,
            wordLists: res.data.meta.word_lists,
          });
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;
  if (!flashcardsMeta) return null;

  return (
    <Switch>
      <Route path="/flashcards/decks" component={DeckList} />
      <Route path="/flashcards/:modelType/:modelId" exact component={FlashcardDeckPage} />
      <Route path="/flashcards/all" exact component={FlashcardsPage} />
      <Route path="/flashcards" exact component={AutoFlashcardsPage} />
      <Redirect to="/flashcards" />
    </Switch>
  );
};

const AutoFlashcardsPage = () => {
  const lastPage = localStorage.getItem("flashcards:lastPage");

  if (lastPage) return <Redirect to={lastPage} />;

  return <Redirect to="/flashcards/all" />;
};

const FlashcardDeckPage = () => {
  const { db } = useContext(ApiContext);
  const { flashcardsMeta } = useContext(ApiContext);
  const { modelType, modelId } = useParams();

  if (modelType && modelId) {
    if (modelType === "level") {
      const levelIds = flashcardsMeta.courses.flatMap((i) => i.levels).map((i) => i.id);
      if (!levelIds.includes(modelId)) return <Redirect to="/flashcards/all" />;
    } else if (modelType === "lesson") {
      if (!db.lessons.find((i) => i.id === modelId)) return <Redirect to="/flashcards/all" />;
    } else if (modelType === "word_list") {
      if (!flashcardsMeta.wordLists.find((i) => i.id === modelId || i.id === parseInt(modelId))) return <Redirect to="/flashcards/all" />;
    }
  }

  return <FlashcardsPage />;
};

const NoPermissionView = () => (
  <Page className="text-center">
    <div className="py-16">
      <h1>Flashcards</h1>
      <div className="mt-2">Reviews all the word you&apos;ve learned.</div>
    </div>

    <div className="py-8 rounded-xl shadow-xl dark:shadow-none border border-gray-200 dark:border-gray-700 overflow-hidden max-w-3xl mx-auto">
      <video className="hidden dark:block" src="http://materials.dominochinese.com/web-v4/flashcards-dark.mp4" autoPlay muted loop />
      <video className="block dark:hidden" src="http://materials.dominochinese.com/web-v4/flashcards.mp4" autoPlay muted loop />
    </div>

    <div className="text-2xl mt-12">
      Flashcards requires a <b className="text-green-500">Professional</b> Plan.
    </div>

    <div className="my-6 flex justify-center">
      <a href={process.env.REACT_APP_SUBSCRIBE_PAGE}>Check our Plans</a>
    </div>
  </Page>
);

export default FlashcardsPages;
