import { useContext, useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { Button } from "newComponents/base";
import ChapterVideo from "../ChapterVideo";
import { ApiContext } from "contexts/ApiContext";
import { LessonReviewContext } from "contexts/LessonReviewProvider";
import { useGet, useLazyPost, usePost } from "Services";
import ProgressRing from "newComponents/ProgressRing";
import Attachment from "newComponents/Attachment";
import { toABC } from "utils/string";
import { IoIosCheckmarkCircle, IoIosCheckmark, IoIosDownload } from "react-icons/io";
import { FaCertificate } from "react-icons/fa";
import { toast } from "react-toastify";
import Spinner from "newComponents/Spinner";
import { hasLessonPermission } from "utils/permissions";
import Page from "newComponents/Page";
import queryString from "query-string";
import { useModals } from "contexts/ModalProvider";

const LevelPage = () => {
  const { id } = useParams();
  const q = queryString.parse(location.search);
  const { completedItems, db } = useContext(ApiContext);
  const [shownStoryTime, setShownStoryTime] = useState(false);
  const { data } = useGet(`/levels/${id}`);
  const storytimeModal = useModals();

  useEffect(() => {
    const id = q && q.storyTime ? q.storyTime : null;
    if (id && !shownStoryTime) {
      setTimeout(() => {
        console.log("present");
        storytimeModal.present({
          maxWidth: "max-w-6xl",
          children: <ChapterVideo id={id} show={true} />
        });
        setShownStoryTime(true);
      }, 500);
    }
  }, [q, shownStoryTime]);

  const level = db.levels.find(i => i.id === id);
  const course = db.courses.find(i => i.id === level.course_id);
  let levelChapters = db.chapters.filter(i => i.level_id === id);
  levelChapters = levelChapters.sort((a, b) => a.ordering - b.ordering);

  const levelVideoIds = db.getLevelVideos(id).map(i => i.id);
  const completedLevelVideoIds = levelVideoIds.filter(i => completedItems.videoIds.includes(i));

  const progress = (completedLevelVideoIds.length * 100) / levelVideoIds.length;

  return (
    <Page>
      <LevelNavigation level={level} />

      <div className="lg:flex justify-between items-center">
        <div className="w-full">
          <h1>{level.name}</h1>

          <div className="float-right md:ml-10">
            {completedItems && completedItems.levelIds && completedItems.levelIds.includes(id) ? (
              <div className="flex justify-end items-center lg:space-x-2 text-lime-500">
                <div className="text-4xl text-right font-semibold hidden md:block">
                  <div>LEVEL</div>
                  <div>COMPLETED</div>
                </div>
                <div className="relative">
                  <ProgressRing progress={100} stroke={10} />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <IoIosCheckmark className="flex-shrink-0 text-8xl " />
                  </div>
                </div>
              </div>
            ) : (
              <ProgressRing
                className="mt-2"
                stroke={10}
                progress={progress}
                showPercentage={true}
              />
            )}
          </div>

          <div className="text-gray-500 dark:text-gray-400">
            <div className="mt-2">{level.description}</div>
            {level.extra_description ? <div className="mt-2">{level.extra_description}</div> : null}
          </div>

          <div className="mt-2 lg:flex lg:space-x-6">
            <div className="flex items-baseline">
              <label className="pr-2">Words: </label>
              <div className="">{level.word_count}</div>
            </div>
            <div className="flex items-baseline">
              <label className="pr-2">Grammar Points: </label>
              <div className="">{level.grammar_count}</div>
            </div>
            <div className="flex items-baseline">
              <label className="pr-2">Videos: </label>
              <div className="">{level.video_count}</div>
            </div>
            <div className="flex items-baseline">
              <label className="pr-2">Duration: </label>
              <div className="">{(level.duration / 3600).toFixed(1)} hours</div>
            </div>
          </div>

          {completedItems && completedItems.levelIds && completedItems.levelIds.includes(id) ? (
            <CertificateButton level={level} />
          ) : null}
        </div>
      </div>

      {level.notes ? (
        <div className="flex items-center space-x-3 mt-4 text-orange-400">
          <h3>{level.notes}</h3>
        </div>
      ) : null}

      <div className="flex flex-wrap mt-4 -mx-6">
        {levelChapters.map(chapter => (
          <ChapterComponent
            key={chapter.id}
            courseId={level.course_id}
            levelId={id}
            chapter={chapter}
            chapterVideo={db.videos.find(
              i => i.videoable_id === chapter.id && i.videoable_type === "App\\Chapter"
            )}
            playVideo={videoId =>
              storytimeModal.present({
                maxWidth: "max-w-6xl",
                children: <ChapterVideo id={videoId} />
              })
            }
          />
        ))}
      </div>

      <div className="mt-8 p-6 rounded-xl bg-white dark:bg-gray-800 shadow-sm space-y-2 sm:space-y-0 sm:flex sm:space-x-8">
        {course.has_quiz ? (
          <Button
            className="flex items-center space-x-4"
            to={`/study/level/${id}/quiz`}
            rightIcon={<BsPencil />}>
            <div>Take Level Quiz</div>
          </Button>
        ) : null}

        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_API_BASE_URL}/v3/pleco/${id}`}
          className="flex items-center font-bold">
          Download Pleco Deck <IoIosDownload className="ml-2" size={21} />
        </a>
      </div>

      {data ? (
        <div className="mt-8 p-6 rounded-xl bg-white dark:bg-gray-800 shadow-sm">
          <h4>Study Materials</h4>
          <div className="mt-4">
            {data.attachments.map((i, index) => (
              <div
                key={index}
                className="border-b dark:border-gray-700 flex py-4 cursor-pointer hover:text-blue-600"
                onClick={() => window.open(i.src)}>
                <Attachment file={i} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </Page>
  );
};

const LessonItem = ({ courseId, levelId, chapterId, lesson }) => {
  const history = useHistory();
  const { user, studying, completedItems, setApiState, db } = useContext(ApiContext);
  const { refresh } = useContext(LessonReviewContext);
  const [completeLesson, completeLessonRes] = usePost(`/lessons/${lesson.id}/complete`, {
    onComplete: () => {
      const lessonVideoIds = db.getLessonVideos(lesson.id).map(i => i.id);
      setApiState("completedItems", {
        ...completedItems,
        lessonIds: [...completedItems.lessonIds, lesson.id],
        videoIds: [...completedItems.videoIds, ...lessonVideoIds]
      });
      refresh();
    },
    onError: error => toast.error(error.message)
  });

  function selectLesson() {
    const lessonVideos = db.videos
      .filter(i => i.videoable_id === lesson.id && i.videoable_type === "App\\Lesson")
      .sort((a, b) => a.ordering - b.ordering);
    history.push(`/study/${courseId}/${levelId}/${chapterId}/${lesson.id}/${lessonVideos[0].id}`);
    setApiState("studying", {
      course_id: courseId,
      level_id: levelId,
      chapter_id: chapterId,
      lesson_id: lesson.id,
      video_id: lessonVideos[0].id
    });
  }

  function markComplete() {
    if (hasLessonPermission(user, lesson)) {
      if (window.confirm("Are you sure to mark this lesson as completed?")) completeLesson();
    } else {
      toast.error("You don't have access to this lesson yet.");
    }
  }

  const isStudyingLesson = studying.lesson_id === lesson.id;

  return (
    <div className="w-full flex-1 border-b dark:border-gray-700 py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center text-left">
          {completeLessonRes.loading ? (
            <div className="mr-3">
              <Spinner size={16} />
            </div>
          ) : (
            <div className="mr-3 text-yellow-500 text-lg cursor-pointer transition-all transform scale-100 hover:scale-125 duration-200">
              {completedItems &&
              completedItems.lessonIds &&
              completedItems.lessonIds.includes(lesson.id) ? (
                <AiFillStar title="You have completed this lesson." />
              ) : (
                <AiOutlineStar title="Mark this lesson completed." onClick={markComplete} />
              )}
            </div>
          )}

          <div
            className="flex space-x-8 justify-between cursor-pointer hover:text-blue-500 disabled:text-gray-600 disabled:cursor-not-allowed outline-none focus:outline-none transition-all font-normal hover:font-bold"
            onClick={selectLesson}>
            <div className={isStudyingLesson ? "font-bold text-blue-500" : ""}>
              Lesson {toABC(lesson.ordering)} - {lesson.name}
            </div>
          </div>
        </div>

        {isStudyingLesson ? (
          <div className="text-green-500 dark:text-green-600 ml-3 font-normal text-xl text-right flex justify-end">
            👨🏻‍💻
          </div>
        ) : null}
      </div>
    </div>
  );
};

const CertificateButton = ({ level }) => {
  const { data } = useGet(`/courses/${level.course_id}`);
  if (!data) return null;

  const levelData = data.levels.find(i => i.id === level.id);

  if (levelData && levelData.certificate_id)
    return (
      <Button
        className="mt-8"
        leftIcon={<FaCertificate />}
        to={`/certificate/${levelData.certificate_id}`}>
        My Certificate
      </Button>
    );

  return null;
};

const ChapterComponent = ({ courseId, levelId, chapter, chapterVideo, playVideo }) => {
  const { completedItems, setApiState, db } = useContext(ApiContext);

  const [completeVideo, completeVideoRes] = useLazyPost({
    onComplete: () => {
      setApiState("completedItems", {
        ...completedItems,
        videoIds: [...completedItems.videoIds, chapterVideo.id]
      });
    }
  });

  let chapterLessons = db.lessons.filter(i => i.chapter_id === chapter.id);
  chapterLessons = chapterLessons.sort((a, b) => a.ordering - b.ordering);

  let isChapterCompleted = false;
  if (completedItems && completedItems.videoIds && completedItems.lessonIds) {
    const hasCompletedChapterLessons = completedItems
      ? chapterLessons.every(i => completedItems.lessonIds.includes(i.id))
      : false;
    if (hasCompletedChapterLessons) {
      if (chapterVideo) {
        isChapterCompleted = completedItems.videoIds.includes(chapterVideo.id);
      }
    }
  }

  let progress = 0;
  const completedLessons = completedItems
    ? chapterLessons.filter(i => completedItems.lessonIds.includes(i.id))
    : [];
  if (chapterVideo) {
    const hasCompleteChapterVideo = completedItems.videoIds.includes(chapterVideo.id);
    if (hasCompleteChapterVideo) {
      progress =
        parseFloat((completedLessons.length + 1) * 100) / parseFloat(chapterLessons.length + 1);
    } else {
      progress = parseFloat(completedLessons.length * 100) / parseFloat(chapterLessons.length + 1);
    }
  } else {
    progress = parseFloat(completedLessons.length * 100) / parseFloat(chapterLessons.length);
  }

  console.log("chapter", chapter);

  return (
    <div className="p-4 sm:p-6 w-full lg:w-1/2 2xl:w-1/3">
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6 flex-1 shadow-sm">
        <div className="flex justify-between relative">
          <h3>{chapter.name}</h3>

          {isChapterCompleted ? (
            <IoIosCheckmarkCircle className="absolute right-0 transform -translate-y-2 translate-x-2 text-green-500 text-5xl " />
          ) : (
            <div className="absolute right-0 transform -translate-y-3 translate-x-2">
              <ProgressRing progress={progress} radius={27} stroke={5} showPercentage={true} />
            </div>
          )}
        </div>

        <div className="mt-4">
          {chapterLessons.map(lesson => (
            <LessonItem
              courseId={courseId}
              levelId={levelId}
              chapterId={chapter.id}
              lesson={lesson}
              key={lesson.id}
            />
          ))}

          {chapterVideo ? (
            <div className="border-b dark:border-gray-700 py-2 flex items-center">
              {completeVideoRes.loading ? (
                <div className="mr-3">
                  <Spinner size={16} />
                </div>
              ) : (
                <div className="mr-3 text-yellow-500 text-lg cursor-pointer transition-all transform scale-100 hover:scale-125 duration-200">
                  {completedItems && completedItems.videoIds.includes(chapterVideo.id) ? (
                    <AiFillStar />
                  ) : (
                    <AiOutlineStar
                      onClick={() => completeVideo(`/videos/${chapterVideo.id}/complete`)}
                    />
                  )}
                </div>
              )}

              <div
                className="hover:text-blue-500 flex items-center cursor-pointer"
                onClick={() => playVideo(chapterVideo.id)}>
                Chapter Story Time Video <FaPlay className="ml-3" size={12} />
              </div>
            </div>
          ) : null}
        </div>

        {chapter.dialog_count ? (
          <div className="mt-4">
            <Button
              to={`/dialogs?page=1&chapter_id=${chapter.id}&topic=all&difficulty=all&filterCompleted=all`}>
              Dialogs({chapter.dialog_count})
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LevelNavigation = ({ level }) => {
  const { db } = useContext(ApiContext);
  const course = db.courses.find(i => i.id === level.course_id);
  return (
    <div className="flex items-center justify-between overflow-auto whitespace-nowrap">
      <div className="flex items-center">
        <Button className="flex items-center space-x-2" to="/courses">
          <AiFillHome size={21} />
        </Button>
        <span className="px-3 text-gray-500">
          <FaChevronRight />
        </span>
        <Button
          className="flex items-center py-6"
          to={`/study/courses/${course.id}?ref=${location.pathname}`}>
          {course.name}
        </Button>
        <span className="px-3 text-gray-500">
          <FaChevronRight />
        </span>
        <div disabled className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
          {level.name}
        </div>
      </div>
    </div>
  );
};

export default LevelPage;
