import { useGet } from "Services";

function useFlashcards({ size = 20, type = 0, modelType, modelId, settingsUpdateCounter }) {
  let url = `/flashcards/filter?deck_size=${size}&deck_type=${type}&a=${settingsUpdateCounter}`;
  if (modelType && modelId) url += `&model_id=${modelId}&model_type=${modelType}`;

  return useGet(url);
}

export default useFlashcards;
