const WordClasses = ({ classes }) => {
  if (!classes) return null;

  return (
    <div className="mt-2 flex items-center space-x-4">
      {classes.map((i, index) => (
        <div key={index} className="font-bold text-xs opacity-50">
          {i}
        </div>
      ))}
    </div>
  );
};

export default WordClasses;
