import { ApiContext } from "contexts/ApiContext";
import { useModals } from "contexts/ModalProvider";
import moment from "moment";
import { useContext } from "react";

const otherPlatformMessages = {
  manual: "You have a manual plan.",
  ios: "You have a subscription through Apple, to manage please visit Apple Subscription.",
  android: "You have a subscription through Google Play, to manage please visit Google Play Subscription.",
};

const SubscriptionInfo = () => {
  const { user } = useContext(ApiContext);

  if (!user) return;

  console.log("user.subscription ", user.subscription);

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
      {user.subscription ? (
        <div>
          <h4>Subscription</h4>
          <div className="mt-4 text-sm">
            <label>You Current Plan: </label>

            <div className="mt-2">
              <div className="font-bold text-2xl text-green-500 uppercase">
                ${user.subscription.amount} USD / {user.subscription.billing_cycle}
              </div>

              {user.subscription.discount && user.subscription.discount.USD > 0 ? (
                <div className="italic">Discount: - ${user.subscription.discount.USD}</div>
              ) : null}
            </div>
          </div>

          {user.subscription.cancelled_at === null && user.subscription.billing_cycle !== "once" ? (
            <div className="mt-4">
              <label className="text-sm">Next Billing Date: </label>
              <div className="mt-2 text-2xl">{moment(user.subscription.ends_at).format("Do MMMM, YYYY")}</div>
            </div>
          ) : null}

          <div className="mt-4 text-sm">
            {user.subscription.platform === "web" ? (
              <div className="space-y-2">
                <a href={`${process.env.REACT_APP_SUBSCRIBE_PAGE}/summary`}>Manage Subscription</a>
              </div>
            ) : (
              <div className="text-xs text-gray-700 dark:text-gray-500">{otherPlatformMessages[user.subscription.platform]}</div>
            )}
          </div>
        </div>
      ) : (
        <div className=" space-y-2">
          <div>You don&lsquo;t have a subscription plan right now.</div>
          <div>
            <a href={process.env.REACT_APP_SUBSCRIBE_PAGE} className="text-lg">
              Subscribe Now
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionInfo;
