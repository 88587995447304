import Page from "newComponents/Page";
import { Button } from "newComponents/base";
import { useContext } from "react";
import { MetaContext } from ".";
import { FaChevronRight } from "react-icons/fa";

const DeckList = () => {
  const { meta } = useContext(MetaContext);
  const { courses, word_lists } = meta;

  return (
    <Page>
      <div className="flex items-center justify-between whitespace-nowrap overflow-auto">
        <div className="flex items-center">
          <Button className="flex items-center space-x-2" to="/flashcards">
            Flashcards
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <div
            disabled
            className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
            Decks
          </div>
        </div>
      </div>

      <h1>Flashcard Decks</h1>

      <div className="space-y-16 mt-8">
        {courses.map(course => (
          <div key={course.id}>
            <h4>{course.name}</h4>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-8 mt-8">
              {course.levels.map(level => (
                <div
                  key={level.id}
                  className="p-8 rounded-2xl bg-white dark:bg-gray-800 relative shadow transform-gpu bounce scale-100 hover:scale-105">
                  <div className="absolute text-9xl opacity-5 right-0 bottom-0 dark:text-gray-500 font-serif italic z-0">
                    {level.count}
                  </div>
                  <div className="text-3xl font-semibold opacity-80">{level.name}</div>
                  <div className="flex space-x-4 mt-4 relative z-10">
                    <Button next>Start</Button>
                    <Button next to={`/flashcards/decks/level/${level.id}`}>
                      Pick a Lesson
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div>
          <h4>Word Lists</h4>

          <div className="grid grid-cols-4 gap-4 sm:gap-8 mt-8">
            {word_lists.map(list => (
              <div
                key={list.id}
                className="p-4 sm:p-12 rounded-2xl bg-white dark:bg-gray-800 relative shadow transform-gpu bounce scale-100 hover:scale-105">
                <div className="absolute text-9xl opacity-5 right-0 bottom-0 dark:text-gray-500 font-serif italic z-0">
                  {list.count}
                </div>
                <div className="text-4xl font-semibold opacity-80">{list.name}</div>
                <div className="flex space-x-4 mt-4 relative z-10">
                  <Button next>Start</Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default DeckList;
