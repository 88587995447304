import { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { Input } from "./base";

function SearchBar({
  containerClassName = "",
  className = "",
  onChange,
  initialQuery = "",
  hide = false,
  ...rest
}) {
  const [text, setText] = useState(initialQuery);

  return (
    <div
      className={`${containerClassName} relative animate-ease-3 overflow-hidden search-bar-container
            ${hide ? "h-0 mt-0" : "h-10"}
         `}>
      <div className="relative">
        <Input
          value={text}
          onChange={e => {
            let text = e.target.value;
            setText(text);
            if (onChange) onChange(text);
          }}
          className={`
               ${className} relative pl-12 animate-ease-3 cursor-pointer w-full px-4
               ${hide ? "h-0" : "h-10"}
               `}
          style={{ minWidth: 120 }}
          {...rest}
          autoCorrect="none"
          autoCapitalize="none"
          spellCheck="none"
        />
        <div className="absolute left-0 top-0 bottom-0 text-3xl flex items-center justify-center px-2 text-gray-400 search-icon">
          <IoIosSearch />
        </div>
      </div>
      <div
        className={`absolute right-0 px-2 top-0 h-full items-center cursor-pointer animate-ease-2 text-gray-600 hover:text-gray-900 active:text-gray-800
               ${text.trim() === "" ? "opacity-0 invisible" : "opacity-100 visible"}
               ${hide ? "hidden" : "flex"}
               `}
        onClick={() => {
          setText("");
          if (onChange) onChange("");
        }}>
        <div className="text-xl px-2 opacity-50 hover:opacity-100">&times;</div>
      </div>
    </div>
  );
}

export default SearchBar;
