import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Trans } from "@lingui/macro";
import "css-file-icons";
import { ApiContext } from "../../contexts/ApiContext";
import Attachment from "newComponents/Attachment";
import { useGet } from "Services";
import { Pinyin } from "newComponents/Word";
import ResultItem from "pages/dictionary/ResultItem";
import SimpleVideo from "newComponents/SimpleVideo";
import WordClasses from "pages/dictionary/WordClasses";
import { useModals } from "contexts/ModalProvider";

const LessonWords = ({ words }) => {
  const { user } = useContext(ApiContext);

  const { present } = useModals();
  const videoModal = useModals();

  return (
    <div>
      {words && words.length ? (
        <div className="bg-white dark:bg-gray-800 p-6 rounded-xl mb-8 shadow-sm">
          <h3>
            <Trans>Dictionary</Trans>
          </h3>

          <p className="mt-2 text-gray-600">
            <Trans>
              Here are the words and characters for this lesson, click to navigate to the Domino
              Dictionary for that item.
            </Trans>
          </p>

          <div className="mt-2 items-center text-xs flex flex-wrap whitespace-nowrap">
            <div className="flex items-center mr-2 mb-2">
              <Trans>Tone Key: </Trans>
            </div>

            <div className="flex items-center mr-2 mb-2">
              <span
                style={{ backgroundColor: user.tone_colours[1] }}
                className="w-4 h-4 rounded-md mr-2"
              />
              <span className="tone-text">
                <Trans>First(high)</Trans>
              </span>
            </div>
            <div className="flex items-center mr-2 mb-2">
              <span
                style={{ backgroundColor: user.tone_colours[2] }}
                className="w-4 h-4 rounded-md mr-2"
              />
              <span className="tone-text">
                <Trans>Second (rising)</Trans>
              </span>
            </div>
            <div className="flex items-center mr-2 mb-2">
              <span
                style={{ backgroundColor: user.tone_colours[3] }}
                className="w-4 h-4 rounded-md mr-2"
              />
              <span className="tone-text">
                <Trans>Third (falling then rising)</Trans>
              </span>
            </div>
            <div className="flex items-center mr-2 mb-2">
              <span
                style={{ backgroundColor: user.tone_colours[4] }}
                className="w-4 h-4 rounded-md mr-2"
              />
              <span className="tone-text">
                <Trans>Fourth (falling)</Trans>
              </span>
            </div>
            <div className="flex items-center mr-2 mb-2">
              <span
                style={{ backgroundColor: user.tone_colours[0] }}
                className="w-4 h-4 rounded-md mr-2"
              />
              <span className="tone-text">
                <Trans>Neutral</Trans>
              </span>
            </div>
          </div>

          <div className="mt-2 flex flex-wrap">
            {words.map(word => (
              <div key={word.id} className="w-full xl:w-1/2 border-b dark:border-gray-700 py-3">
                <div className="mr-4">
                  <div className="flex items-baseline space-x-2 flex-wrap">
                    <div
                      className="flex items-baseline space-x-1 text-xl cursor-pointer border-b border-white hover:border-blue-300 dark:border-gray-800 dark:hover:border-gray-500 test-lesson-word"
                      onClick={() =>
                        present({
                          title: word.characters.map(i => i.text).join(""),
                          maxWidth: "max-w-5xl",
                          children: (
                            <ResultItem
                              word={word}
                              watchVideo={video =>
                                videoModal.present({
                                  maxWidth: "max-w-6xl",
                                  children: <SimpleVideo video={video} />
                                })
                              }
                              videosInline={false}
                              wordTarget="_blank"
                            />
                          )
                        })
                      }>
                      {word.characters.map((char, index) => (
                        <div key={index}>
                          <span style={{ color: user.tone_colours[char.tone ? char.tone : 0] }}>
                            {char.text}
                          </span>
                        </div>
                      ))}
                    </div>
                    <Pinyin word={word} />
                  </div>
                  <WordClasses classes={word.class.length > 0 ? word.class : null} />
                  <div className="mt-1 text-gray-500 dark:text-gray-400">
                    {word.definitions.join(", ")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const LessonGrammars = ({ grammars }) => (
  <div>
    {grammars && grammars.length ? (
      <div className="mb-4 md:my-8 p-6 rounded-xl bg-white dark:bg-gray-800 shadow-sm">
        <h3>
          <Trans>Grammar Point</Trans>
        </h3>
        <ol className="pt-2">
          {grammars.map((item, index) => (
            <li key={index} className="list-decimal py-2 border-b dark:border-gray-700 list-inside">
              {item}
            </li>
          ))}
        </ol>
      </div>
    ) : null}
  </div>
);

const LessonAttachments = ({ attachments }) => (
  <div className="p-6 rounded-xl bg-white dark:bg-gray-800 mt-8 shadow-sm">
    <h3>
      <Trans>Lesson Attachments</Trans>
    </h3>
    <div className="mt-2">
      {attachments.map((a, index) => (
        <div
          key={index}
          className="border-b dark:border-gray-700 flex py-4 cursor-pointer hover:text-blue-600"
          onClick={() => window.open(a.src)}>
          <Attachment key={index} file={a} />
        </div>
      ))}
    </div>
  </div>
);

const LessonDetail = () => {
  const { lesson_id } = useParams();
  const { data } = useGet(`frontend/lessons/${lesson_id}`);

  if (!data) return null;

  const { words, grammars, attachments } = data;

  return (
    <div>
      <LessonWords words={words} />

      <LessonGrammars grammars={grammars} />

      {words.length > 0 || attachments.length > 0 ? (
        <LessonAttachments attachments={attachments} />
      ) : null}
    </div>
  );
};

export default LessonDetail;
