import { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Button } from "newComponents/base";
import Flashcard from "newComponents/Flashcard";
import { ApiContext } from "contexts/ApiContext";
import AudioRecorder from "newComponents/AudioRecorder";
import { IoIosSend } from "react-icons/io";
import Feedback from "pages/feedback";
import { shuffledArray } from "utils/array";
import { FlashcardsContext } from "contexts/FlashcardsProvider";
import { ReviewLessonContext } from "./ReviewLesson";
import { ModalContext, useModals } from "contexts/ModalProvider";
import WordVideo from "newComponents/WordVideo";
import ResultItem from "pages/dictionary/ResultItem";
import SimpleVideo from "newComponents/SimpleVideo";

const randomCardFront = audioSrc => {
  const options = audioSrc ? ["Audio Only", "English", "Chinese"] : ["English", "Chinese"];
  return options[Math.floor(Math.random() * options.length)];
};

const FlashcardsContainer = ({ data, onCompleted }) => {
  const { showFeedbackForm, user } = useContext(ApiContext);
  const { cardFront, enableAnimation } = useContext(FlashcardsContext);
  const { totalScore, setTotalScore } = useContext(ReviewLessonContext);
  const { hasModals } = useContext(ModalContext);

  const [cards, setCards] = useState([]);

  const slider = useRef();
  const [correctCount, setCorrectCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasDoneIndex, setHasDoneIndex] = useState(null);
  const [pressKeyboard, setPressKeyboard] = useState(null);
  const [keyboardCounter, setKeyboardCounter] = useState(0);

  const { present } = useModals();

  let initialCards = data.words.map(i => ({
    id: i.id,
    word: i,
    cardFront: cardFront === "Random" ? randomCardFront(i.audio_src) : cardFront,
    type: "LESSON_REVIEW"
  }));
  // if (process.env.NODE_ENV === "development" || location.host.includes("dc.cors.tech"))
  //   initialCards = initialCards.slice(0, 6);

  const videoModal = useModals();
  const dictionaryModal = useModals();

  useEffect(() => {
    setCorrectCount(0);
    setCurrentIndex(0);
    setKeyboardCounter(0);
    setCards(initialCards);
  }, [data, cardFront]);

  useEffect(() => {
    const spaceHandler = e => {
      if (hasModals) return;
      if (e.code === "Space") e.preventDefault();
      setPressKeyboard(e.code);
      setKeyboardCounter(prev => prev + 1);
    };

    document.addEventListener("keydown", spaceHandler);
    return () => {
      document.removeEventListener("keydown", spaceHandler);
    };
  }, [showFeedbackForm, hasModals]);

  const initialCardsCount = initialCards.length;

  function next(repeatCardId, score, isCurrentCardRepeated) {
    let cardsLength = cards.length;
    let newTotalScore = totalScore;

    if (!isCurrentCardRepeated) {
      newTotalScore = totalScore + score;
      setTotalScore(newTotalScore);
    }

    if (repeatCardId) {
      cardsLength += 1;
      const repeatCard = cards.find(i => i.id === repeatCardId);
      const newCard = {
        ...repeatCard,
        cardFront: cardFront === "Random" ? randomCardFront(repeatCard.word.audio_src) : cardFront,
        repeated: true
      };
      const prevCards = cards.filter((_, index) => index <= currentIndex);
      const afterCards = cards.filter((_, index) => index > currentIndex);
      const repeatedAfterCards = [...afterCards.filter(i => i.repeated), newCard];
      const originalAfterCards = afterCards.filter(i => !i.repeated);
      const shuffledRepeatedAfterCards = shuffledArray(repeatedAfterCards);
      const newCards = [...prevCards, ...originalAfterCards, ...shuffledRepeatedAfterCards];
      setCards(newCards);

      // const repeatedCards = shuffledArray([...cards.filter(i => i.repeated), newCard]);
      // setCards(prev => [...prev.filter(i => !i.repeated), ...repeatedCards]);
    } else if (currentIndex < cardsLength - 1) {
      setCorrectCount(prev => prev + 1);
    }
    if (currentIndex + 1 === cardsLength) {
      if (hasDoneIndex !== currentIndex)
        onCompleted(Math.round((newTotalScore * 100) / (6 * initialCardsCount)), data.length);
    } else {
      slider.current.slickNext();
    }
    setPressKeyboard(null);
    setHasDoneIndex(currentIndex);
  }

  const settings = {
    dots: true,
    swipe: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    adaptiveHeight: false,
    infinite: false,
    draggable: false,
    fade: !enableAnimation
  };

  return (
    <div className="flex-1 flex flex-col min-h-screen pb-72">
      <div className="flex justify-end container mx-auto px-6 2xl:px-12">
        <div className="text-gray-600">
          {correctCount} / {initialCardsCount}
        </div>
      </div>

      <div className="relative flex-1 flex flex-col">
        {cards.length === 0 ? (
          <div className="flex-1 flex items-center text-center justify-center">
            <div className="p-8 text-gray-500 text-2xl">Earn new cards by completing videos!</div>
          </div>
        ) : (
          <div className="flex-1 flex flex-col">
            <div
              className="flex-1 flex flex-col justify-center"
              style={
                enableAnimation ? { animationDuration: "0.5s", animationName: "slidein" } : null
              }>
              <Slider
                className="focus:outline-none"
                ref={slider}
                {...settings}
                afterChange={setCurrentIndex}>
                {cards.map((card, index) => (
                  <Flashcard
                    key={index}
                    data={card}
                    next={next}
                    keyboardCounter={keyboardCounter}
                    pressKeyboard={pressKeyboard}
                    isShowing={index === currentIndex}
                    cardFront={card.cardFront}
                    showDictionary={() =>
                      dictionaryModal.present({
                        title: card.word.characters.map(i => i.text).join(""),
                        maxWidth: "max-w-6xl",
                        children: (
                          <ResultItem
                            word={card.word}
                            watchVideo={video =>
                              videoModal.present({
                                maxWidth: "max-w-6xl",
                                children: <SimpleVideo video={video} />
                              })
                            }
                            videosInline={false}
                            wordTarget="_blank"
                          />
                        )
                      })
                    }
                    showVideo={() =>
                      videoModal.present({
                        maxWidth: "max-w-7xl",
                        children: <WordVideo word={card.word} />
                      })
                    }
                  />
                ))}
              </Slider>
            </div>

            {user && user.permissions.includes("UPLOAD_AUDIO") ? (
              <div className="flex justify-center mb-6">
                <AudioRecorder card={cards[currentIndex]} />
              </div>
            ) : null}

            <div className="px-8 text-center text-sm text-gray-500">
              Press space bar to reveal the card and numbers 1-6 to mark your answer.{" "}
            </div>

            <div className="mt-4 px-8 text-center text-sm text-gray-500 flex justify-center">
              <Button
                bold={false}
                rightIcon={<IoIosSend />}
                onClick={() =>
                  present({
                    title: "Flashcard Feedback",
                    keepChildren: true,
                    children: <Feedback itemType="flashcard" data={cards[currentIndex]} />
                  })
                }>
                Submit feedback for the current flashcard.
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlashcardsContainer;
