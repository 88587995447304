export const Footer = () => (
  <div className="mt-2 text-xs px-2 text-gray-500">
    <p>
      You can follow your feedback live on this tracking list{" "}
      <a href="https://my.dominochinese.com/feedback" target="_blank" rel="noreferrer">
        here.
      </a>
    </p>
    <p>
      If we have any questions for you or when the report is complete/closed/fixed you&lsquo;ll be
      notified by email.
    </p>
  </div>
);
