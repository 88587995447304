import { Select, Button, Switcher } from "newComponents/base";
import { useState } from "react";

const FlashcardSettings = ({ onSave, lessonReview, initialValues }) => {
  const sizeOptions = [10, 20, 50, 100, 200];
  const typeOptions = [0, 1, 2];
  const typeStrings = ["Words and Sentences", "Words Only", "Sentences Only"];

  const cardFrontOptions = ["Audio Only", "English", "Chinese", "Random"];

  const [config, setConfig] = useState(initialValues);

  return (
    <div className="space-y-6">
      {lessonReview ? null : (
        <>
          <div>
            <label className="pr-4">Preferred Deck Size: </label>
            <Select
              value={config.size}
              onChange={e => setConfig(prev => ({ ...prev, size: e.target.value }))}
              style={{
                textAlignLast: "center"
              }}>
              {sizeOptions.map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Select>
          </div>

          <div className="flex items-center">
            <label className="pr-4">Type: </label>
            <Select
              value={config.type}
              onChange={e => setConfig(prev => ({ ...prev, type: e.target.value }))}>
              {typeOptions.map(i => (
                <option key={i} value={i}>
                  {typeStrings[i]}
                </option>
              ))}
            </Select>
          </div>
        </>
      )}

      <div>
        <div>
          <label className="pr-4">Card Front: </label>
          <Select
            value={config.cardFront}
            onChange={e => setConfig(prev => ({ ...prev, cardFront: e.target.value }))}>
            {cardFrontOptions.map(i => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </div>
        {config.cardFront === "Audio Only" ? (
          <div className="text-sm mt-1 opacity-70">
            * Note not all flashcards has audio, when there is no audio recorded it will revert to
            showing you the character.
          </div>
        ) : null}
      </div>

      <div className="flex items-center">
        <label className="pr-4">Auto Play Audio on Reveal: </label>
        <Switcher
          isOn={config.autoPlayAudio}
          onChange={() => setConfig(prev => ({ ...prev, autoPlayAudio: !prev.autoPlayAudio }))}
        />
      </div>

      <div className="flex items-center">
        <label className="pr-4">Chinese Font: </label>
        <Select
          value={config.font || "default"}
          onChange={e => setConfig(prev => ({ ...prev, font: e.target.value }))}>
          <option value="default">Default</option>
          <option value="SourceHanSerifSC">SourceHanSerifSC</option>
          <option value={`'Ma Shan Zheng', cursive`}>Ma Shan Zheng</option>
          <option value="'ZCOOL KuaiLe', cursive">KuaiLe</option>
          <option value={`'ZCOOL XiaoWei', serif`}>XiaoWei</option>
        </Select>
        <div className="ml-4 text-xl" style={{ fontFamily: config.font }}>
          中文字体
        </div>
      </div>

      <div className="flex items-center">
        <label className="pr-4">Enable Animation: </label>
        <Switcher
          isOn={config.enableAnimation}
          onChange={() => setConfig(prev => ({ ...prev, enableAnimation: !prev.enableAnimation }))}
        />
      </div>

      <div className="border-t dark:border-gray-700 pt-6">
        <Button className="text-2xl font-bold" onClick={() => onSave(config)}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default FlashcardSettings;
