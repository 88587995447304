import { useState, useEffect, useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import localforage from "localforage";
import Footer from "newComponents/Footer";
import { ApiContext } from "./contexts/ApiContext";
import UserRoutes from "newComponents/RoutesContainer";
import ScrollToTop from "newComponents/ScrollToTop";
import Header from "newComponents/Header";
import { BootingScreen } from "./newComponents/BootingScreen";
import { http } from "Services";
import Notifications from "pages/notifications";
import Spinner from "newComponents/Spinner";
import { setTheme } from "utils/theme";
import OnboardSlider from "newComponents/OnboardSlider";
import { redirectToLogin, redirectToVerifyEmail } from "pages/auth/utils";
import LessonReviewProvider from "contexts/LessonReviewProvider";
import ClassroomsProvider from "contexts/ClassroomProvider";
import TopBanner from "newComponents/TopBanner";
import ModalProvider from "contexts/ModalProvider";
import FlashcardsProvider from "contexts/FlashcardsProvider";
import Streak from "newComponents/Streak";
import Classrooms from "pages/public/Classrooms";
import { track } from "utils/tracker";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "dc",
  version: 1.0,
  storeName: "dcStore",
  description: "Domino Chinese courses",
});

const contextClass = {
  success: "bg-green-500",
  error: "bg-red-500",
  info: "bg-gray-500",
  warning: "bg-orange-400",
  default: "bg-indigo-500",
  dark: "bg-white-500 font-gray-300",
};

const bootMessages = [
  "Oiling up the gears...",
  "Warming up the processors...",
  "Counting backwards from infinity...",
  "Generating next funny line...",
  "Stretching first. I don't want to pull a muscle!",
  "Entertaining you while you wait...",
  "We apologise for the fault in the loading message. Those responsible have been sacked.",
  "We apologise again for the fault in the loading message. Those responsible for sacking the people who have just been sacked have been sacked.",
  "As a kid I was made to walk the plank. We couldn't afford a dog.",
  "Red sky at night: shepherd's delight. Blue sky at night: day.",
];

function needsVerification(user) {
  return user.email_verified_at === null && moment.duration(moment().diff(moment(user.created_at))).asDays() > 7;
}

const App = () => {
  const { setApiState, setUserMeta, showHeaderMore, setShowHeaderMore, setLastLocation } = useContext(ApiContext);
  const [state, setState] = useState({ booted: false, error: null });
  const {} = useContext(ApiContext);

  const location = useLocation();

  useEffect(() => {
    setLastLocation((prev) => ({ to: location, from: prev ? prev.to : null }));
    track("page_view", "study", { pathname: location.pathname });
    if (showHeaderMore) setShowHeaderMore(null);
  }, [location.pathname]);

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
    checkAuth();
  }, []);

  async function checkAuth() {
    try {
      const { data } = await http.get("me");
      const user = data;

      if (!user) return;

      setApiState("user", {
        ...user,
        coupons: user.coupons ? user.coupons : [],
      });

      if (needsVerification(user)) return redirectToVerifyEmail(user.email);

      setState((prev) => ({ ...prev, booted: true }));

      const userId = data.id;
      const key = `userMeta:${userId}`;
      setUserMeta(JSON.parse(localStorage.getItem(key)) || {});
    } catch (error) {
      console.log("error", error);
    }

    setState((prev) => ({ ...prev, booted: true }));
  }

  const bootMessage = bootMessages[Math.floor(Math.random() * bootMessages.length)];
  if (!state.booted) return <BootingScreen bootMessage={bootMessage} />;

  return (
    <ScrollToTop>
      <ModalProvider>
        <TopBanner />
        <Header />
        <Streak />
        <div className="flex-1 flex flex-col relative">
          <Switch>
            <Route path="/classrooms" component={Classrooms} />
            <Route component={UserApp} />
          </Switch>
        </div>
        <Footer />
      </ModalProvider>

      <ToastContainer
        hideProgressBar={true}
        toastClassName={({ type }) =>
          contextClass[type || "default"] + " relative flex px-4 py-3 m-4 min-h-10 rounded-xl justify-between overflow-hidden cursor-pointer mb-4 bg-opacity-90"
        }
      />
    </ScrollToTop>
  );
};

const UserApp = () => {
  const { user, setApiState, setDb, showNotifications, setNotifications, setLoadingNotifications } = useContext(ApiContext);
  const [booted, setBooted] = useState(false);

  if (process.env.NODE_ENV === "production") {
    if (window.location.host === "study-v4.dominochinese.com") window.location.href = "https://study.dominochinese.com" + window.location.pathname;
  }

  function checkNotifications() {
    setLoadingNotifications(true);
    http
      .get("/notifications/all")
      .then(({ data }) => {
        setNotifications(data.data);
        setLoadingNotifications(false);
      })
      .catch();
  }

  async function checkUserInfo() {
    try {
      if (needsVerification(user)) return redirectToVerifyEmail(user.email);

      let db = null;
      const dbUpdatedAt = localStorage.getItem("db_updated_at");
      if (dbUpdatedAt && dbUpdatedAt === user.db_updated_at) {
        db = await localforage.getItem("db");
      }
      if (!db) {
        const webdbRes = await http.get("/database?web");
        db = webdbRes.data.data;
        localforage.setItem("db", db);
      }
      setupLocalDB(db);
      localStorage.setItem("db_updated_at", user.db_updated_at);

      const nextRes = await http.get("next");
      if (nextRes.data) {
        setApiState("next", nextRes.data.data);
        setApiState("studying", {
          course_id: nextRes.data.data.course_id,
          level_id: nextRes.data.data.level_id,
          chapter_id: nextRes.data.data.chapter_id,
          lesson_id: nextRes.data.data.lesson_id,
          video_id: nextRes.data.data.video_id,
        });
      } else {
        toast.error("Could not connect to server. Please refresh this page. If issue persist, let us know.");
      }

      checkVideoQuality(user);
      checkNotifications();

      setBooted(true);
    } catch (error) {}
  }

  function setupLocalDB(db) {
    const courses = db.courses.sort((a, b) => a.ordering - b.ordering);
    const levels = db.levels.sort((a, b) => a.ordering - b.ordering);
    const chapters = db.chapters.sort((a, b) => a.ordering - b.ordering);
    const lessons = db.lessons.sort((a, b) => a.ordering - b.ordering);
    const videos = db.videos.sort((a, b) => a.ordering - b.ordering);
    const getCourseLevels = (courseId) => levels.filter((i) => i.course_id === courseId).sort((a, b) => a.ordering - b.ordering);
    const getCourse = (id) => db.courses.find((i) => i.id === id);

    const getLessonVideos = (id) => videos.filter((i) => i.videoable_id === id && i.videoable_type === "App\\Lesson").sort((a, b) => a.ordering - b.ordering);

    const getChapterVideos = (id) => videos.filter((i) => i.videoable_id === id && i.videoable_type === "App\\Chapter").sort((a, b) => a.ordering - b.ordering);

    const getLevelChapters = (id) => chapters.filter((i) => i.level_id === id);

    const getChapterLessons = (id) => lessons.filter((i) => i.chapter_id === id);

    const getLevelVideos = (id) => {
      let videos = [];
      const chapters = getLevelChapters(id);
      chapters.forEach((chapter) => {
        const chapterLessons = getChapterLessons(chapter.id);
        chapterLessons.forEach((lesson) => {
          videos = [...videos, ...getLessonVideos(lesson.id)];
        });
        const chapterVideo = videos.find((i) => i.videoable_id === chapter.id && i.videoable_type === "App\\Chapter");
        if (chapterVideo) {
          videos.push(chapterVideo);
        }
      });
      return videos;
    };

    setDb({
      courses,
      levels,
      chapters,
      lessons,
      videos,
      getCourseLevels,
      getCourse,
      getLessonVideos,
      getChapterVideos,
      getLevelChapters,
      getChapterLessons,
      getLevelVideos,
    });
  }

  function checkVideoQuality(user) {
    if (user.video_quality !== null) return;

    let quality = 3;
    if (navigator.language.toLowerCase().includes("zh") || navigator.language.toLowerCase().includes("cn")) {
      quality = 1;
    }
    http.post("/video-quality", { quality }).then(() => {
      setApiState("user", { ...user, video_quality: quality });
    });
  }

  useEffect(() => {
    if (user) {
      checkUserInfo();
    } else {
      redirectToLogin();
    }
  }, [user]);

  if (!booted) return <Spinner center />;

  return (
    <div className="flex-1 relative flex flex-col" style={{ minHeight: 250 }}>
      <OnboardSlider />
      <LessonReviewProvider>
        <ClassroomsProvider>
          <FlashcardsProvider>
            <UserRoutes />
          </FlashcardsProvider>
        </ClassroomsProvider>
      </LessonReviewProvider>

      <Notifications show={showNotifications} hide={() => setApiState("showNotifications", false)} />
    </div>
  );
};

export default App;
