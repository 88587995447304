import { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Button, NavigationBar, Select } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { http } from "Services";
import queryString from "query-string";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { isNumber } from "lodash";
import { toast } from "react-toastify";
import parseErrorMessage from "utils/errors";

const pageSize = 10;

const Dialogs = ({ meta }) => {
  const [data, setData] = useState(null);
  const [links, setLinks] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const q = queryString.parse(location.search);

  const page = q.page ? parseInt(q.page) : 1;
  const chapterId = q.chapter_id || "all";
  const topic = q.topic || "all";
  const difficulty = q.difficulty || "all";
  const filterCompleted = q.filterCompleted || "all";

  async function fetchDialogs(page, chapterId, topic, difficulty, filterCompleted) {
    setLoading(true);

    let url = `/dialogs?page=${page}&limit=${pageSize}`;
    if (topic !== "all") url += `&topic=${topic}`;
    if (chapterId !== "all") url += `&chapter_id=${chapterId}`;
    if (difficulty !== "all") url += `&difficulty=${difficulty}`;
    if (filterCompleted !== "all")
      url += `&completed=${filterCompleted == "completed" ? "true" : "false"}`;

    try {
      const { data } = await http.get(url);
      setLastPage(data.meta.last_page);
      setData(data.data);
      setLinks(data.links);
    } catch (error) {
      toast.error(parseErrorMessage(error));
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchDialogs(page, chapterId, topic, difficulty, filterCompleted);
  }, [page, chapterId, topic, difficulty, filterCompleted]);

  function navigate(page, chapterId, topic, difficulty, filterCompleted) {
    history.push(
      location.pathname +
        `?page=${page}&chapter_id=${chapterId}&topic=${topic}&difficulty=${difficulty}&filterCompleted=${filterCompleted}`
    );
  }

  return (
    <div className="container mx-auto px-6 sm:px-8 2xl:px-12 max-w-6xl flex flex-col">
      <NavigationBar title="Dialogs" subtitle="Improve your reading!" />

      <div className="space-y-4">
        <div className="flex items-center">
          <label className="pr-2">Chapter: </label>
          <Select
            className="max-w-[200px] sm:max-w-full"
            value={chapterId}
            onChange={e => navigate(page, e.target.value, topic, difficulty, filterCompleted)}>
            <option value="all">All</option>
            {meta.courses.map(course => (
              <optgroup key={course.id} label={course.name}>
                {course.chapters.map((i, index) => (
                  <option value={i.id} key={index}>
                    {i.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </div>

        <div className="space-y-4 xl:space-y-0 xl:flex xl:space-x-4 items-center justify-between">
          <div className="space-y-4 sm:space-y-0 sm:flex sm:space-x-4 items-center">
            <div className="flex items-center">
              <label className="pr-2">Topic: </label>
              <Select
                className="max-w-[200px] sm:max-w-full"
                value={topic}
                onChange={e =>
                  navigate(page, chapterId, e.target.value, difficulty, filterCompleted)
                }>
                <option value="all">All</option>
                {meta.topics.map((i, index) => (
                  <option value={i} key={index}>
                    {i}
                  </option>
                ))}
              </Select>
            </div>

            <div className="flex items-center">
              <label className="pr-2">Difficulty: </label>
              <Select
                value={difficulty}
                onChange={e =>
                  navigate(page, chapterId, topic, e.target.value, filterCompleted, chapterId)
                }>
                <option value="all">ALL</option>
                <option value="EASY">EASY</option>
                <option value="MEDIUM">MEDIUM</option>
                <option value="HARD">HARD</option>
              </Select>
            </div>

            <div className="flex items-center">
              <label className="pr-2">Completion: </label>
              <Select
                value={filterCompleted}
                onChange={e => navigate(page, chapterId, topic, difficulty, e.target.value)}>
                <option value="incomplete">Incomplete</option>
                <option value="completed">Completed</option>
                <option value="all">ALL</option>
              </Select>
            </div>
          </div>

          {loading ? null : (
            <div className="flex justify-end">
              <Pagination
                page={page}
                links={links}
                lastPage={lastPage}
                navigate={page => navigate(page, topic, difficulty, filterCompleted)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 flex-1">
        {loading ? (
          <div className="relative p-20">
            <Spinner label="Loading dialogs..." />
          </div>
        ) : data ? (
          data.length > 0 ? (
            data.map(i => <Dialog data={i} key={i.id} />)
          ) : (
            <div className="flex items-center opacity-70 pt-4">
              Sorry, no dialogs matched your search criteria.
            </div>
          )
        ) : null}
      </div>

      {loading ? null : (
        <div className="pb-4">
          <Pagination
            navigate={page => navigate(page, topic, difficulty, filterCompleted)}
            page={page}
            links={links}
            lastPage={lastPage}
          />
        </div>
      )}
    </div>
  );
};

const Pagination = ({ page, links, lastPage, navigate }) => {
  if (!links) return null;
  if (!links.next && !links.prev) return null;

  return (
    <div className="flex items-center space-x-2 sm:space-x-4">
      <Button
        disabled={links.prev === null}
        leftIcon={<MdFirstPage size={21} />}
        onClick={() => navigate(1)}
      />
      <Button disabled={links.prev === null} back onClick={() => navigate(page - 1)}>
        Prev
      </Button>

      <div className="text-gray-700 dark:text-gray-400">
        <Select
          style={{ textAlignLast: "center" }}
          value={page}
          onChange={e => navigate(parseInt(e.target.value))}>
          {[...Array(lastPage).keys()].map(i => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Select>{" "}
        / {lastPage}
      </div>

      <Button disabled={links.next === null} next onClick={() => navigate(page + 1)}>
        Next
      </Button>
      <Button
        disabled={links.next === null}
        rightIcon={<MdLastPage size={21} />}
        onClick={() => navigate(lastPage)}></Button>
    </div>
  );
};

const Dialog = ({ data }) => {
  const recommended = !data.is_complete && data.words_learned === "100%";

  return (
    <Link className="block" to={`/dialogs/${data.id}`}>
      <div className="flex items-center p-6 rounded-2xl bg-white dark:bg-gray-800 mb-6 cursor-pointer shadow-sm transform bounce scale-100 hover:scale-102 hover:shadow-lg">
        <div>
          {recommended ? <div className="text-green-500 font-bold">Recommended</div> : null}

          <div className="flex text-gray-600 text-sm space-x-4">
            {data.access_type === "CLASSROOM" ? (
              <div className="text-indigo-500 mb-2">Classroom Only</div>
            ) : null}
            <div>{data.difficulty}</div>
            <div>{data.words_learned} words learned</div>
          </div>
          <div className="flex space-x-4 items-center">
            <div className="text-2xl">
              {data.is_complete ? (
                <AiFillStar className="text-yellow-400" />
              ) : (
                <AiOutlineStar className="text-yellow-400" />
              )}
            </div>
            <div className="text-lg py-1">{data.title}</div>
          </div>

          <div className="text-xs text-gray-500">{data.topics.join(", ")}</div>
        </div>
      </div>
    </Link>
  );
};

export default Dialogs;
