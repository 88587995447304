import { Button } from "newComponents/base";
import { toast } from "react-toastify";
import { usePost } from "Services";

const ImpersonateButton = () => {
  const [impersonate, { loading }] = usePost("/users/impersonate", {
    onComplete: () => window.location.reload(),
    onError: error => toast.error(error.message)
  });

  return (
    <Button
      loading={loading}
      disabled={loading}
      onClick={() => {
        const email = window.prompt("Email");
        if (email) {
          impersonate({ email });
        }
      }}>
      Impersonate
    </Button>
  );
};

export default ImpersonateButton;
