import { ApiContext } from "contexts/ApiContext";
import { NavigationBar } from "newComponents/base";
import { useModals } from "contexts/ModalProvider";
import Spinner from "newComponents/Spinner";
import { useContext } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useGet } from "Services";
import { timeSince } from "utils/date";
import parseErrorMessage from "utils/errors";
import { Badge } from "./dashboard";
import Achievements from "./dashboard/Achievements";
import { DCBarChart } from "./dashboard/Chart";

const Leaderboard = () => {
  const { loading, error, data } = useGet("/leaderboard");
  const { user } = useContext(ApiContext);
  const modal = useModals();

  if (loading) return <Spinner center />;
  if (error) return <div>{parseErrorMessage(error)}</div>;
  if (!data) return null;

  return (
    <div className="container mx-auto px-6 sm:px-8 max-w-6xl">
      <NavigationBar
        title="Leaderboard"
        subtitle="See how you measure up to your fellow Domino classmates!"
      />

      <div className="bg-white dark:bg-gray-800 rounded-3xl p-4 sm:p-6">
        <table className="w-full border-collapse">
          <thead>
            <tr className="uppercase">
              <th className="border-b dark:border-gray-700 sm:w-6 lg:w-8 pb-3 sm:pb-6">Rank</th>
              <th className="border-b dark:border-gray-700 text-left pl-4 pb-3 sm:pb-6">Name</th>
              <th className="border-b dark:border-gray-700 text-right pb-3 sm:pb-6">POINTS</th>
              <th className="border-b dark:border-gray-700 text-right pr-4 pb-3 sm:pb-6 hidden sm:table-cell">
                MEMBER SINCE
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <tr
                onClick={() =>
                  modal.present({
                    title: `Rank #${i.rank}`,
                    center: false,
                    maxWidth: "max-w-7xl",
                    titleClassName: `font-serif font-black text-5xl
                      ${
                        i.rank === 1
                          ? "text-yellow-500"
                          : i.rank == 2
                          ? "text-yellow-600"
                          : i.rank == 3
                          ? "text-yellow-700"
                          : "opacity-70"
                      }`,
                    children: <UserProfile user={i} />
                  })
                }
                key={index}
                className={`cursor-pointer
                  ${
                    user.id === i.id
                      ? "bg-green-200 dark:bg-green-900"
                      : "hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-gray-700"
                  }
                `}>
                <td
                  className={`px-2 lg:px-4 text-center border-b dark:border-gray-700 sm:text-2xl font-serif
                  ${
                    index === 0
                      ? "text-yellow-500 font-black text-4xl sm:text-6xl"
                      : index == 1
                      ? "text-yellow-600 font-black text-3xl sm:text-5xl"
                      : index == 2
                      ? "text-yellow-700 font-bold text-xl sm:text-4xl"
                      : "opacity-70"
                  }
                `}>
                  {i.rank}
                </td>
                <td className="border-b dark:border-gray-700 py-3 pl-4 lg:pl-8">
                  <div className="flex items-center">
                    <div
                      className="w-8 h-8 sm:w-12 sm:h-12 rounded-full overflow-hidden bg-cover bg-center bg-no-repeat border border-gray-200"
                      style={{
                        backgroundImage: `url(${i.avatar})`
                      }}></div>
                    <div className="ml-4 text-sm sm:text-base">{i.name}</div>
                  </div>
                </td>

                <td className="text-right border-b dark:border-gray-700 text-sm sm:text-base">
                  {i.points}
                </td>
                <td className="text-right ml-4 border-b dark:border-gray-700 pr-4 hidden sm:table-cell">
                  {timeSince(new Date(i.created_at))} ago
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const UserProfile = ({ user }) => {
  const { loading, data } = useGet(`/profile/${user.id}`);

  if (loading) return <Spinner center />;
  if (!data) return null;

  const videoChartData = data.videos_chart.labels.map((label, index) => ({
    name: label,
    value: data.videos_chart.datasets[0].data[index]
  }));

  const flashcardChartData = data.lesson_reviews_chart.labels.map((label, index) => ({
    name: label,
    value: data.lesson_reviews_chart.datasets[0].data[index]
  }));

  return (
    <div className="px-10 xl:flex items-start xl:space-x-16 relative">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="w-64">
          <div className="relative">
            {user.avatar == "https://materials.dominochinese.com/misc/anonymous-user.png" ? (
              <div className="flex justify-center">
                <FaUserCircle size={200} />
              </div>
            ) : (
              <div
                className="w-full rounded-full overflow-hidden bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${data.user.avatar})`,
                  paddingBottom: "100%"
                }}></div>
            )}
          </div>
        </div>
        <div className="text-center mt-6 space-y-4">
          <h3>{user.name}</h3>
          <div className="opacity-60">Joined {timeSince(new Date(user.created_at))} ago</div>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Badge
            className="flex-1 bg-white dark:bg-gray-900 flex flex-col justify-center"
            title="Total Experience"
            value={data.total_experience}
          />
          <Badge
            className="flex-1 bg-white dark:bg-gray-900 flex flex-col justify-center"
            title="LESSONS COMPLETED"
            value={data.lessons_completed}
          />

          <Badge
            className="flex-1 bg-white dark:bg-gray-900 flex flex-col justify-center"
            title="VIDEOS COMPLETED"
            value={data.videos_completed}
          />

          <Badge
            className="flex-1 bg-white dark:bg-gray-900 flex flex-col justify-center"
            title="WORDS LEARNED"
            value={data.total_flashcards}
          />
        </div>

        <div className="mt-8">
          <Achievements userAchievements={data.achievements} bg="bg-white dark:bg-gray-900" />
        </div>

        <DCBarChart
          className="bg-white dark:bg-gray-900"
          data={videoChartData}
          title="Videos Completed Past Week"
        />
        <DCBarChart
          className="bg-white dark:bg-gray-900"
          data={flashcardChartData}
          title="Flashcards Studied in Lesson Reviews"
        />
      </div>
    </div>
  );
};

export default Leaderboard;
