import { useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { BsCardText, BsPencil } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { Button, PermissionLink } from "newComponents/base";
import { useGet } from "Services";
import LessonDetail from "./LessonDetail";
import LessonVideos from "./LessonVideos";
import Spinner from "newComponents/Spinner";
import ChapterVideo from "./ChapterVideo";
import { ApiContext } from "contexts/ApiContext";
import { IoIosCheckmark } from "react-icons/io";
import ProgressRing from "newComponents/ProgressRing";
import { toABC } from "utils/string";
import { hasLessonPermission } from "utils/permissions";
import NoLessonPermission from "newComponents/NoLessonPermission";
import Page from "newComponents/Page";
import { useModals } from "contexts/ModalProvider";

const LessonMain = () => {
  const { user, db } = useContext(ApiContext);
  const { course_id, level_id, chapter_id, lesson_id, video_id } = useParams();
  const { completedItems } = useContext(ApiContext);
  const history = useHistory();

  const lesson = db.lessons.find(i => i.id === lesson_id);
  const lessonVideos = db.getLessonVideos(lesson_id);

  if (lessonVideos.find(i => i.id === video_id) === null) {
    history.push(
      `/study/${course_id}/${level_id}/${chapter_id}/${lesson_id}/${lessonVideos[0].id}`
    );
  }

  const completedVideos = lessonVideos.filter(
    i => completedItems && completedItems.videoIds && completedItems.videoIds.includes(i.id)
  );
  const lessonProgress = (completedVideos.length * 100) / lessonVideos.length;

  const { present } = useModals();

  function setShowNotAllow() {
    present({
      title: "HOLD ON",
      center: true,
      children: <NoLessonPermission />
    });
  }

  return (
    <Page>
      <div className="lg:flex justify-between items-end">
        <div>
          <CourseNavigation />

          <div className="whitespace-nowrap">
            <SmallProgress
              completedItems={completedItems}
              lesson_id={lesson_id}
              lessonProgress={lessonProgress}
            />

            <div className="lg:flex lg:space-x-6 items-center">
              <h1>Lesson {toABC(lesson.ordering)}</h1>
              <h1 className="font-normal whitespace-normal">{lesson.name}</h1>
            </div>
          </div>

          <div className="mt-2 text-gray-700 dark:text-gray-400">{lesson.description}</div>
        </div>

        <LargeProgress
          completedItems={completedItems}
          lesson_id={lesson_id}
          lessonProgress={lessonProgress}
        />
      </div>

      <div className="lg:flex my-8">
        <div className="hidden lg:block w-96">
          <LessonVideos
            lesson_id={lesson_id}
            video_id={video_id}
            setShowNotAllow={setShowNotAllow}
          />
          {completedItems.lessonIds.includes(lesson_id) ? (
            <LessonFlashcards id={lesson_id} />
          ) : null}
          <LessonQuiz setShowNotAllow={setShowNotAllow} />
          <ChapterNavigation chapterId={chapter_id} lessonId={lesson_id} />
        </div>
        <div className="lg:ml-8 lg:flex-1">
          <div className="relative">
            <VideoBanner
              onClick={() => {
                if (hasLessonPermission(user, lesson)) {
                  history.push(`/study/video/${video_id}`);
                } else {
                  setShowNotAllow();
                }
              }}
            />
          </div>
          <div className="block lg:hidden flex-1">
            <LessonVideos
              lesson_id={lesson_id}
              video_id={video_id}
              setShowNotAllow={setShowNotAllow}
            />
            <LessonQuiz setShowNotAllow={setShowNotAllow} />
          </div>
          <div className="mt-8" id="test-lesson-words">
            {hasLessonPermission(user, lesson) ? (
              <LessonDetail />
            ) : (
              <div className="bg-white dark:bg-gray-800 rounded-xl p-6">
                <NoLessonPermission />
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

const CourseNavigation = () => {
  const { db } = useContext(ApiContext);
  const { course_id, level_id, chapter_id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const course = db.courses.find(i => i.id === course_id);
  const level = db.levels.find(i => i.id === level_id);
  const chapter = db.chapters.find(i => i.id === chapter_id);

  if (course && level && chapter)
    return (
      <div className="flex items-center justify-between whitespace-nowrap overflow-auto">
        <div className="flex items-center">
          <Button className="flex items-center space-x-2" to="/courses">
            <AiFillHome size={21} />
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <Button
            className="flex items-center py-6"
            to={`/study/courses/${course_id}?ref=${location.pathname}`}>
            {course.name}
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <Button
            className="flex items-center py-6"
            to={`/study/levels/${level_id}?ref=${location.pathname}`}>
            {level.name}
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <div
            disabled
            className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
            {chapter.name}
          </div>
        </div>
      </div>
    );

  history.push("/");

  return null;
};

const VideoBanner = ({ onClick }) => {
  const { video_id } = useParams();
  const { loading, thumb } = useGet(`frontend/videos/${video_id}/thumb`);
  const { db } = useContext(ApiContext);
  const video = db.videos.find(i => i.id === video_id);

  if (loading)
    return (
      <div className="py-16">
        <Spinner />
      </div>
    );
  if (!thumb) return null;
  if (!video) return null;

  return (
    <div
      id="video-banner"
      className="text-white cursor-pointer video-thumb-container shadow-sm"
      onClick={onClick}>
      <div className="mb-8 relative rounded-xl overflow-hidden">
        <div
          className="bg-cover bg-center bg-no-repeat"
          style={{ paddingTop: "56.25%", backgroundImage: `url(${thumb})` }}></div>
        <div className="absolute items-center justify-center flex inset-0 dark:bg-gray-900 dark:bg-opacity-50">
          <div
            className="flex flex-col justify-center items-center play-button transform bounce scale-100"
            style={{
              textShadow: "rgb(0 0 0 / 75%) 0px 0px 30px"
            }}>
            <div className="text-4xl md:text-5xl">
              <FaPlay />
            </div>
            <h3 className="mt-2">{video.name}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const LessonQuiz = ({ setShowNotAllow }) => {
  const { user } = useContext(ApiContext);
  const { lesson_id } = useParams();
  const { db } = useContext(ApiContext);

  const lesson = db.lessons.find(i => i.id === lesson_id);

  if (!lesson.has_quiz) return null;

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
      <PermissionLink
        className="flex items-center font-bold"
        to={`/study/lesson/${lesson_id}/quiz`}
        hasPermission={hasLessonPermission(user, lesson)}
        notAllowHandler={() => setShowNotAllow(true)}>
        <BsPencil className="mr-4 text-xl" />
        Lesson Quiz
      </PermissionLink>
    </div>
  );
};

const ChapterNavigation = ({ chapterId, lessonId }) => {
  const { course_id, level_id } = useParams();
  const { db } = useContext(ApiContext);
  const history = useHistory();
  const { present } = useModals();

  function gotoLesson(lessonId) {
    const lessonVideos = db.videos
      .filter(i => i.videoable_id === lessonId && i.videoable_type === "App\\Lesson")
      .sort((a, b) => a.ordering - b.ordering);
    history.push(`/study/${course_id}/${level_id}/${chapterId}/${lessonId}/${lessonVideos[0].id}`);
  }

  const chapter = db.chapters.find(i => i.id === chapterId);
  const chapterLessons = db.lessons
    .filter(i => i.chapter_id === chapterId)
    .sort((a, b) => a.ordering - b.ordering);

  const chapterVideo = db.videos.find(
    i => i.videoable_id === chapter.id && i.videoable_type === "App\\Chapter"
  );

  return (
    <>
      <div className="p-6 bg-white dark:bg-gray-800 rounded-xl mt-8 opacity-70 hover:opacity-100 animate-ease-2 shadow-sm">
        <label>{chapter.name}</label>
        <div className="mt-2">
          {chapterLessons.map(lesson => (
            <div
              key={lesson.id}
              className={`py-2 flex justify-between items-center cursor-pointer hover:text-orange-500
            ${lessonId === lesson.id ? "text-orange-500 font-bold" : "opacity-50"}
          `}
              onClick={() => gotoLesson(lesson.id)}>
              <div className="flex items-baseline space-x-2">
                <div className="font-bold">Lesson {toABC(lesson.ordering)}</div>
                <div>{lesson.name}</div>
              </div>
            </div>
          ))}
          {chapterVideo ? (
            <div
              className="hover:text-blue-500 flex items-center cursor-pointer py-2"
              onClick={() =>
                present({
                  maxWidth: "max-w-6xl",
                  children: <ChapterVideo id={chapterVideo.id} />
                })
              }>
              Chapter Story Time Video <FaPlay className="ml-3" size={12} />
            </div>
          ) : null}

          {chapter ? (
            <div className="mt-4">
              <Button
                to={`/dialogs?page=1&chapter_id=${chapter.id}&topic=all&difficulty=all&filterCompleted=all`}>
                Dialogs({chapter.dialog_count})
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const SmallProgress = ({ completedItems, lesson_id, lessonProgress }) => (
  <div className="block lg:hidden float-right">
    {completedItems && completedItems.lessonIds.includes(lesson_id) ? (
      <div className="relative">
        <ProgressRing progress={100} stroke={6} radius={40} />
        <div className="absolute inset-0 flex items-center justify-center">
          <IoIosCheckmark className="flex-shrink-0 text-7xl text-lime-500" />
        </div>
      </div>
    ) : (
      <ProgressRing progress={lessonProgress} stroke={8} radius={50} showPercentage={true} />
    )}
  </div>
);

const LargeProgress = ({ completedItems, lesson_id, lessonProgress }) => (
  <div className="flex-shrink-0 mt-8 lg:mt-0 hidden lg:block">
    {completedItems && completedItems.lessonIds.includes(lesson_id) ? (
      <div className="flex items-center space-x-2 text-lime-500 ml-12 flex-shrink-0">
        <div className="text-3xl text-right font-semibold">
          <div>LESSON</div>
          <div>COMPLETED</div>
        </div>
        <div className="relative">
          <ProgressRing progress={100} stroke={8} radius={50} />
          <div className="absolute inset-0 flex items-center justify-center">
            <IoIosCheckmark className="flex-shrink-0 text-8xl " />
          </div>
        </div>
      </div>
    ) : (
      <ProgressRing progress={lessonProgress} stroke={10} showPercentage={true} />
    )}
  </div>
);

const LessonFlashcards = ({ id }) => {
  return (
    <div className="p-6 mb-6 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
      <Button to={`/flashcards/lesson/${id}`} leftIcon={<BsCardText className="text-xl mr-2" />}>
        Lesson Flashcards
      </Button>
    </div>
  );
};

export default LessonMain;
