import axios from "axios";
import history from "./newComponents/history";
import { devLog } from "./helpers";

const http = (timeout = 18000) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + "/v3",
    timeout,
    withCredentials: true
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      devLog("warn", "%cError", "color: #f6993f; font-weight: bold", error, error.response);
      if (error.response && error.response.status && error.response.status === 503) {
        return history.push("/503");
      }

      if (error.response && error.response.status && error.response.status === 401) {
        return;
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export const get = url => http().get(url);
export const post = (url, payload) => http(30000).post(url, payload);
export const patch = (url, payload) => http().patch(url, payload);
export const destroy = (url, payload) => http().delete(url, payload);
