import { Trans } from "@lingui/macro";
import { useParams } from "react-router-dom";
import Spinner from "newComponents/Spinner";
import { useGet } from "Services";
import { Button, NavigationBar } from "newComponents/base";
import { FiDownload } from "react-icons/fi";
import leaves from "assets/images/icons/branch-with-leaves.svg";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";

const Certificate = () => {
  const { id } = useParams();
  const { lastLocation } = useContext(ApiContext);

  const { loading, error, data } = useGet(`/certificates/${id}`);

  if (loading) return <Spinner center />;
  if (error) return "Fail to load certificate.";

  return (
    <div className="container mx-auto px-6 sm:px-8 text-center">
      <NavigationBar
        backTo={lastLocation && lastLocation.from ? lastLocation.from.pathname : "/"}
      />

      <div className="flex justify-center items-center sm:my-8 space-x-2 text-gray-700">
        <div className="transform rotate-12">
          <img
            className="w-24 h-24"
            src={leaves}
            style={{
              transform: "scaleX(-1)"
            }}
          />
        </div>
        <h1 className="my-8">Domino Certificate</h1>
        <div className="transform -rotate-12">
          <img className="w-24 h-24" src={leaves} />
        </div>
      </div>

      <div className="max-w-3xl mx-auto shadow-xl">
        <img src={data} alt="" />
      </div>

      <div className="flex justify-center no-print my-8">
        <Button
          className="text-xl font-bold"
          download="Domino Education Certificate.jpg"
          onClick={() => {
            var a = document.createElement("a");
            a.href = data;
            a.download = "Domino Education Certificate.jpg";
            a.click();
          }}
          rightIcon={<FiDownload />}>
          <Trans>Download</Trans>
        </Button>
      </div>
    </div>
  );
};

export default Certificate;
