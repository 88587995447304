import { Button } from "newComponents/base";
import { BsExclamationCircleFill } from "react-icons/bs";

const NoDictionaryVideoPermission = () => {
  return (
    <div>
      <div className="text-4xl text-orange-500 flex space-x-4 items-center">
        <BsExclamationCircleFill />
        <h3>Watch videos from dictionary requires a Professional PLAN.</h3>
      </div>

      <div className="mt-4">Please upgrade your plan to view this content.</div>
      <div className="mt-4">
        <a className="text-xl font-bold text-blue-500 hover:text-blue-600" href={process.env.REACT_APP_SUBSCRIBE_PAGE}>
          Check our Plans
        </a>
      </div>
    </div>
  );
};

export default NoDictionaryVideoPermission;
