import { ApiContext } from "contexts/ApiContext";
import { useContext, useEffect, useState } from "react";
import { useDelete } from "Services";
import { setTheme as setGlobalTheme } from "utils/theme";
import ImpersonateButton from "./ImpersonateButton";
import { toast } from "react-toastify";
import { Button, Input, Switcher } from "newComponents/base";
import Modal from "newComponents/Modal";
import ToneColors from "./ToneColors";
import { correctAudio } from "pages/study/quiz/VideoQuiz";
import ChangePassword from "./ChangePassword";
import { redirectToLogin } from "pages/auth/utils";
import { useHistory } from "react-router-dom";
import { LessonReviewContext } from "contexts/LessonReviewProvider";
import { useModals } from "contexts/ModalProvider";
import parseErrorMessage from "utils/errors";

const Settings = () => {
  const { user, setApiState } = useContext(ApiContext);
  const { refresh } = useContext(LessonReviewContext);
  const history = useHistory();
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [disableQuizSound, setDisableQuizSound] = useState(
    localStorage.getItem("disableQuizSound") ? true : false
  );
  const deleteAccountModal = useModals();

  useEffect(() => {
    if (disableQuizSound) {
      localStorage.setItem("disableQuizSound", "1");
    } else {
      localStorage.removeItem("disableQuizSound");
    }
  }, [disableQuizSound]);

  const [resetProgress, resetProgressRes] = useDelete("/progress", {
    onComplete: () => {
      toast.success("You progress has been reset.");
      setShowConfirmReset(false);
      setApiState("completedItems", { courseIds: [], levelIds: [], lessonIds: [], videoIds: [] });
      refresh();
    },
    onError: error => toast.error(error.meesage)
  });

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "auto"
  );

  useEffect(() => {
    setGlobalTheme(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div>
      <div className="space-y-6">
        <hr className="dark:border-gray-700" />

        <div>
          <h4>Settings</h4>

          <div className="mt-4">
            <label className="pr-4">Theme: </label>
            <div className="flex space-x-4 mt-3">
              {["auto", "dark", "light"].map((i, index) => (
                <div
                  key={index}
                  className={`py-2 px-6 rounded-full hover:bg-blue-500 active:bg-blue-600 dark:hover:bg-blue-600 dark:active:bg-blue-700 cursor-pointer capitalize
                      ${
                        i === theme
                          ? "bg-blue-500 dark:bg-blue-600 text-white"
                          : "bg-white dark:bg-gray-800"
                      }
            `}
                  onClick={() => setTheme(i)}>
                  {i}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8 flex items-center">
            <label className="mr-4">Quiz Sound Effect: </label>
            <div>
              <Switcher
                isOn={!disableQuizSound}
                onChange={() => {
                  setDisableQuizSound(disabled => {
                    if (disabled) correctAudio.play();
                    return !disabled;
                  });
                }}
              />
            </div>
          </div>

          <div className="mt-8">
            <ToneColors />
          </div>

          <div className="mt-8 space-y-4">
            <ChangePassword />

            <Button
              className="opacity-20 hover:opacity-100"
              red
              onClick={() => setShowConfirmReset(true)}>
              Reset Progress
            </Button>

            <Button
              className="opacity-20 hover:opacity-100"
              red
              onClick={() =>
                deleteAccountModal.present({
                  title: "Delete Account",
                  center: true,
                  children: <DeleteAccountView user={user} />
                })
              }>
              Delete Account
            </Button>

            <Button
              className="opacity-20 hover:opacity-100"
              red
              onClick={() => {
                localStorage.clear();
                toast.success("Reset.");
                history.push("/");
              }}>
              Clear Local Storage
            </Button>
          </div>
        </div>

        <hr className="dark:border-gray-700" />

        <div className="space-y-4">
          {user.email === "charles@cors.tech" ? <ImpersonateButton /> : null}

          <div>
            <a
              className="font-bold text-red-500 hover:text-red-600"
              href={`${process.env.REACT_APP_AUTH_URL}/logout`}>
              Sign Out
            </a>
          </div>
        </div>
      </div>

      <Modal
        title="Delete Account"
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
        isBeingPresented={resetProgressRes.loading}></Modal>

      <Modal
        title="Reset Progress"
        show={showConfirmReset}
        onHide={() => setShowConfirmReset(false)}
        isBeingPresented={resetProgressRes.loading}>
        <div className="px-6 sm:px-10 pb-6 sm:pb-10">
          <div className="space-y-4 text-lg">
            <div>Are you sure to reset your study progress?</div>
            <div>All your completed items, flashcards and experience will be reset.</div>
          </div>

          <div className="flex items-center text-xl mt-6 font-bold">This CANNOT be undone.</div>

          <div className="mt-10 text-lg flex space-x-8">
            <Button
              red
              loading={resetProgressRes.loading}
              disabled={resetProgressRes.loading}
              onClick={() => resetProgress()}>
              Yes, I am sure! Reset Now.
            </Button>
            <Button onClick={() => setShowConfirmReset(false)} disabled={resetProgressRes.loading}>
              No, keep my progress.
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const DeleteAccountView = ({ user }) => {
  const [doubleConfirmEmail, setDoubleConfirmEmail] = useState("");
  const [deleteAccount, deleteAccountRes] = useDelete(`/users/${user.id}`, {
    onComplete: () => {
      redirectToLogin();
    },
    onError: error => {
      toast.error(parseErrorMessage(error));
    }
  });

  return (
    <div>
      <div className="space-y-4 text-lg">
        <div>Are you sure to delete your account?</div>
      </div>

      <div className="flex items-center text-xl mt-6 font-bold">This CANNOT be undone.</div>

      <div className="flex space-x-4 mt-8 items-center">
        <label htmlFor="">Input your account email: </label>
        <Input
          placeholder="Email"
          value={doubleConfirmEmail}
          onChange={e => setDoubleConfirmEmail(e.target.value)}
        />
        <Button
          red
          loading={deleteAccountRes.loading}
          disabled={deleteAccountRes.loading || user.email !== doubleConfirmEmail}
          onClick={() => deleteAccount()}>
          Delete Now.
        </Button>
      </div>

      <div className="mt-10 text-lg flex space-x-8">
        <Button onClick={() => setShowDeleteConfirm(false)} disabled={deleteAccountRes.loading}>
          No, keep my account.
        </Button>
      </div>
    </div>
  );
};

export default Settings;
