import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopContainer = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.remove("overflow-hidden");
  }, [location]);

  return <div className="min-h-screen flex flex-col">{children}</div>;
};

export default ScrollToTopContainer;
