import { Component } from "react";
import HanziWriter from "hanzi-writer";
import { devLog } from "helpers";
import { uuidv4 } from "utils/string";

class StrokeDiagram extends Component {
  state = {
    uuid: uuidv4(),
    showMedians: false,
    colorRadical: true,
    transparentStrokes: false,
    showOutline: true,
    radicalColor: "#F87171",
    strokeAnimationSpeed: 1,
    delayBetweenStrokes: 300,
    height: this.props.size,
    width: this.props.size,
    padding: 5,
    showCharacter: false,
    showHintAfterMisses: 2,
    highlightOnComplete: true,
    strokeNum: 0,
    mistakesOnStroke: 0,
    totalMistakes: 0,
    strokesRemaining: 0
  };

  componentDidMount() {
    this.writer = HanziWriter.create(
      `grid-background-target-${this.state.uuid}`,
      this.props.pinyin_unicode,
      {
        ...this.state,
        charDataLoader: () => {
          return this.props.stroke;
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pinyin_unicode !== this.props.pinyin_unicode) {
      this.writer.setCharacter(this.props.pinyin_unicode);
      this.animate();
    }
  }

  animate = () => {
    this.writer.showOutline();
    this.writer.animateCharacter();
  };

  quiz = () => {
    this.writer.quiz({
      onMistake: strokeData => {
        this.updateQuizData(strokeData);
        devLog("log", "Oh no! you made a mistake on stroke " + strokeData.strokeNum);
        devLog(
          "log",
          "You've made " + strokeData.mistakesOnStroke + " mistakes on this stroke so far"
        );
        devLog("log", "You've made " + strokeData.totalMistakes + " total mistakes on this quiz");
        devLog(
          "log",
          "There are " + strokeData.strokesRemaining + " strokes remaining in this character"
        );
      },
      onCorrectStroke: strokeData => {
        this.updateQuizData(strokeData);
        devLog("log", "Yes!!! You got stroke " + strokeData.strokeNum + " correct!");
        devLog("log", "You made " + strokeData.mistakesOnStroke + " mistakes on this stroke");
        devLog("log", "You've made " + strokeData.totalMistakes + " total mistakes on this quiz");
        devLog(
          "log",
          "There are " + strokeData.strokesRemaining + " strokes remaining in this character"
        );
      },
      onComplete: summaryData => {
        devLog("log", "You did it! You finished drawing " + summaryData.character);
        devLog("log", "You made " + summaryData.totalMistakes + " total mistakes on this quiz");
      }
    });
  };

  cancelQuiz = () => {
    this.writer.cancelQuiz();
  };

  updateQuizData = ({ strokeNum, mistakesOnStroke, totalMistakes, strokesRemaining }) => {
    this.setState({ strokeNum, mistakesOnStroke, totalMistakes, strokesRemaining });
  };

  render() {
    const { size } = this.props;

    return (
      <div
        className={`flex items-center justify-center cursor-pointer hover:bg-white dark:hover:bg-gray-900`}
        onClick={this.animate}>
        <div>
          <div
            style={{ height: size, width: size }}
            className={`border border-gray-300 dark:border-gray-500 shadow mx-auto`}>
            <svg
              className="stroke-current text-gray-200 dark:text-gray-700"
              xmlns="http://www.w3.org/2000/svg"
              width={size}
              height={size}
              id={`grid-background-target-${this.state.uuid}`}>
              <line x1="0" y1="0" x2={size} y2={size} />
              <line x1={size} y1="0" x2="0" y2={size} />
              <line x1={size / 2} y1="0" x2={size / 2} y2={size} />
              <line x1="0" y1={size / 2} x2={size} y2={size / 2} />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

StrokeDiagram.defaultProps = {
  size: 150,
  stroke: {
    strokes: [],
    medians: [],
    radStrokes: []
  },
  showQuiz: false
};

export default StrokeDiagram;
