import { ApiContext } from "contexts/ApiContext";
import { useContext } from "react";
import AudioPlayer from "../newComponents/AudioPlayer";

export const Pinyin = ({ className = "", word, showAudio = true }) => {
  const { user } = useContext(ApiContext);
  return (
    <div className={`text-xl ${className}`}>
      <div className="flex items-center">
        <div className="flex items-center justify-center flex-wrap space-x-2">
          <div className="text-gray-400 dark:text-gray-700">|</div>
          {word.characters.filter(i => i.pinyin_unicode !== null).length > 0 ? (
            word.characters.map((character, index) => (
              <div
                key={index}
                style={{
                  color: user.tone_colours[character.tone]
                }}>
                {character.pinyin_unicode}
              </div>
            ))
          ) : (
            <div>-</div>
          )}
          <div className="text-gray-400 dark:text-gray-700">|</div>
        </div>
        {word.audio_src && showAudio ? <AudioPlayer src={word.audio_src} mini={true} /> : null}
      </div>
    </div>
  );
};

export const Word = ({ word, hideWordText = false }) =>
  word ? (
    <div>
      {hideWordText ? null : <h4>{word.text}</h4>}
      <Pinyin className="mt-2" word={word} />
      <div className="mt-4">{word.definitions.join(", ")}</div>
    </div>
  ) : null;
