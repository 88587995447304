import { Button, Input } from "newComponents/base";
import Modal from "newComponents/Modal";
import { useState } from "react";
import { BsShieldLock } from "react-icons/bs";
import { toast } from "react-toastify";
import { usePatch } from "Services";
import parseErrorMessage from "utils/errors";

// tuLNTxmm5nLRXqF
const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const [updatePassword, updatePasswordRes] = usePatch("/auth/password", {
    onComplete: () => {
      toast.success("Password Updated.");
      setShowPasswordForm(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    },
    onError: error => toast.error(parseErrorMessage(error))
  });

  function saveHandler() {
    if (currentPassword === "") {
      return toast.error("Please input your current password.");
    }
    if (newPassword === "" || confirmPassword === "") {
      return toast.error("Please input your new password and confirmation.");
    }
    if (newPassword !== confirmPassword) {
      return toast.error("New password does not match.");
    }
    if (currentPassword === newPassword) {
      return toast.error("New password is the same as your current password.");
    }
    updatePassword({
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword
    });
  }

  function validate() {
    if (newPassword !== "" && confirmPassword !== "" && newPassword === confirmPassword)
      return true;
    return false;
  }

  return (
    <div>
      <Button onClick={() => setShowPasswordForm(true)}>Change Password</Button>

      <Modal maxWidth="max-w-3xl" show={showPasswordForm} onHide={() => setShowPasswordForm(false)}>
        <div className="px-6 py-16 sm:p-20">
          <div className="flex justify-center items-center transform sm:-translate-y-4 space-x-4">
            <BsShieldLock size={41} />
            <h1 className="">Update Password</h1>
          </div>

          <div className="space-y-4 mt-12">
            <div className="flex items-center space-x-4">
              <label className="w-44">Current Password:</label>
              <Input
                className="flex-1"
                type="password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-44">New Password:</label>
              <Input
                className="flex-1"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </div>

            <div className="flex items-center space-x-4">
              <label className="w-44">Confirm Password:</label>
              <Input
                className="flex-1"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="pt-12 flex justify-center">
            <Button
              loading={updatePasswordRes.loading}
              disabled={!validate() || updatePasswordRes.loading}
              className="text-2xl"
              onClick={saveHandler}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
