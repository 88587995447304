import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import moment from "moment";
import Spinner from "newComponents/Spinner";
import { IoIosPlayCircle } from "react-icons/io";

const SimpleVideoPlayer = ({ src, thumb, playButton, autoPlay = false }) => {
  const [playing, setPlaying] = useState(autoPlay);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);

  useEffect(() => {
    if (playing) setHasPlayed(true);
  }, [playing]);

  function onProgress(e) {
    setPlayedSeconds(e.playedSeconds);
  }

  return (
    <div className="relative w-full h-full">
      <ReactPlayer
        className="absolute inset-0"
        width="100%"
        height="100%"
        url={hasPlayed ? src : null}
        onBuffer={() => setBuffering(true)}
        onBufferEnd={() => setBuffering(false)}
        onDuration={setDuration}
        onProgress={onProgress}
        playing={playing}
        config={{
          file: {
            attributes: {
              preload: "none"
            }
          }
        }}
      />
      <div className="absolute bottom-0 left-0 text-white px-4 py-2 text-sm font-mono tracking-wide drop-shadow-lg filter">
        -{moment.utc((duration - playedSeconds) * 1000).format("mm:ss")}
      </div>
      {buffering ? <Spinner center /> : null}

      <button
        className={`absolute w-full h-full inset-0 flex items-center justify-center outline-none focus:outline-none text-white bg-cover bg-no-repeat ${
          src ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={() => (src ? setPlaying(prev => !prev) : false)}
        style={{
          backgroundImage: hasPlayed ? null : playing ? null : `url(${thumb})`
        }}>
        {src ? (
          <div
            className={`drop-shadow transform-gpu duration-200
            ${playing ? "opacity-0" : "opacity-100"}`}>
            {playButton ? playButton : <IoIosPlayCircle size={100} />}
          </div>
        ) : null}
      </button>
    </div>
  );
};

export default SimpleVideoPlayer;
