export const Spinner = ({
  className = "",
  center,
  label,
  color = "#00bbd6",
  thickness = 1,
  size = 32
}) => (
  <div
    className={`
    flex justify-center flex-col items-center z-30
    ${center ? "absolute inset-0" : ""} ${className}
    `}>
    <div className="lds-ring" style={{ width: size, height: size }}>
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
      <div
        style={{
          width: size,
          height: size,
          borderWidth: thickness,
          borderColor: `${color} transparent transparent transparent`
        }}
      />
    </div>
    {label ? <div className="mt-2 text-gray-600 text-xs">{label}</div> : null}
  </div>
);

export default Spinner;
