import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "newComponents/Spinner";
import Slider from "react-slick";
import { Button } from "newComponents/base";
import { RiSettings5Fill } from "react-icons/ri";
import Flashcard from "./Flashcard";
import FlashcardsContext from "./FlashcardsContext";
import FlashcardResults from "./FlashcardResults";
import { ApiContext } from "contexts/ApiContext";
import AudioRecorder from "newComponents/AudioRecorder";
import { IoIosSend } from "react-icons/io";
import Feedback from "pages/feedback";
import Errors from "newComponents/Errors";
import Page from "newComponents/Page";
import { FiChevronRight } from "react-icons/fi";
import { shuffledArray } from "utils/array";
import { toABC } from "utils/string";
import { Link, useHistory } from "react-router-dom";
import useFlashcards from "./useFlashcards";
import { useParams } from "react-router-dom";
import { ModalContext, useModals } from "contexts/ModalProvider";
import FlashcardSettings from "newComponents/FlashcardSettings";

const FlashcardsPage = () => {
  const [inited, setInited] = useState(true);
  const { modelType, modelId } = useParams();
  const history = useHistory();

  useEffect(() => {
    modelType
      ? localStorage.setItem("flashcards:modelType", modelType)
      : localStorage.removeItem("flashcards:modelType");
    modelId
      ? localStorage.setItem("flashcards:modelId", modelId)
      : localStorage.removeItem("flashcards:modelId");
  }, [modelType, modelId]);

  const [size, setSize] = useState(localStorage.getItem("flashcards:size") || 20);
  const [type, setType] = useState(
    localStorage.getItem("flashcards:type") ? parseInt(localStorage.getItem("flashcards:type")) : 0
  );
  const [cardFront, setCardFront] = useState(
    localStorage.getItem("flashcards:cardFront") || "Random"
  );
  const [autoPlayAudio, setAutoPlayAudio] = useState(
    localStorage.getItem("flashcards:autoPlayAudio") == "1" ? true : false
  );
  const [enableAnimation, setEnableAnimation] = useState(
    localStorage.getItem("flashcards:enableAnimation") == "0" ? false : true
  );
  const [font, setFont] = useState(localStorage.getItem("flashcards:font"));
  const [settingsUpdateCounter, setSettingsUpdateCounter] = useState(0);

  const [cards, setCards] = useState(null);
  const [initialCardsCount, setInitialCardsCount] = useState(0);

  const { loading, error, data, meta } = useFlashcards({
    size,
    type,
    modelType,
    modelId,
    settingsUpdateCounter
  });

  useEffect(() => {
    if (data) {
      setCards(
        data.map(i => ({
          ...i,
          cardFront: cardFront === "Random" ? randomCardFront(i.word.audio_src) : cardFront
        }))
      );
      setInitialCardsCount(data.length);
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem("flashcards:size", size);
    localStorage.setItem("flashcards:type", type);
    localStorage.setItem("flashcards:cardFront", cardFront);
    localStorage.setItem("flashcards:autoPlayAudio", autoPlayAudio ? "1" : "0");
    localStorage.setItem("flashcards:enableAnimation", enableAnimation ? "1" : "0");
    localStorage.setItem("flashcards:font", font);
  }, [size, type, cardFront, autoPlayAudio, enableAnimation, font]);

  useEffect(() => {
    if (cards)
      setCards(prev =>
        prev.map(i => ({
          ...i,
          cardFront: cardFront === "Random" ? randomCardFront(i.word.audio_src) : cardFront
        }))
      );
  }, [cardFront]);

  function tryMoreHandler() {
    history.push("/flashcards");
  }

  return (
    <FlashcardsContext.Provider
      value={{
        loading,
        error,
        modelType,
        modelId,
        size,
        setSize,
        type,
        setType,
        cardFront,
        setCardFront,
        autoPlayAudio,
        setAutoPlayAudio,
        enableAnimation,
        setEnableAnimation,
        font,
        setFont,
        cards,
        initialCardsCount,
        meta,
        setCards,
        setInited,
        setSettingsUpdateCounter,
        tryMoreHandler
      }}>
      {inited ? <FlashcardsContent /> : <Spinner center />}
    </FlashcardsContext.Provider>
  );
};

const randomCardFront = audioSrc => {
  const options = audioSrc ? ["Audio Only", "English", "Chinese"] : ["English", "Chinese"];
  return options[Math.floor(Math.random() * options.length)];
};

const FlashcardsContent = () => {
  const { user } = useContext(ApiContext);
  const { hasModals } = useContext(ModalContext);
  const {
    cardFront,
    loading,
    error,
    cards,
    setCards,
    size,
    setSize,
    font,
    setFont,
    setCardFront,
    autoPlayAudio,
    setAutoPlayAudio,
    enableAnimation,
    setEnableAnimation,
    meta,
    initialCardsCount,
    setSettingsUpdateCounter,
    tryMoreHandler
  } = useContext(FlashcardsContext);

  const slider = useRef();
  const [correctCount, setCorrectCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pressKeyboard, setPressKeyboard] = useState(null);
  const [keyboardCounter, setKeyboardCounter] = useState(0);

  const settingsModal = useModals();
  const resultModal = useModals();
  const feedbackModal = useModals();

  useEffect(() => {
    const spaceHandler = e => {
      if (!hasModals) {
        if (e.code === "Space") e.preventDefault();
        setPressKeyboard(e.code);
        setKeyboardCounter(prev => prev + 1);
      }
    };

    document.addEventListener("keydown", spaceHandler);
    return () => {
      document.removeEventListener("keydown", spaceHandler);
    };
  }, [hasModals]);

  function onSaveSettingsHandler() {
    settingsModal.hide();
    setSettingsUpdateCounter(prev => prev + 1);
    setCurrentIndex(0);
    setCorrectCount(0);
    setKeyboardCounter(0);
  }

  function next(repeatCardId) {
    let cardsLength = cards.length;
    if (repeatCardId) {
      cardsLength += 1;
      const repeatCard = cards.find(i => i.id === repeatCardId);

      const repeatedCards = shuffledArray([
        ...cards.filter(i => i.repeated),
        {
          ...repeatCard,
          cardFront:
            cardFront === "Random" ? randomCardFront(repeatCard.word.audio_src) : cardFront,
          repeated: true
        }
      ]);

      setCards(prev => [...prev.filter(i => !i.repeated), ...repeatedCards]);
    } else {
      setCorrectCount(prev => prev + 1);
    }
    if (currentIndex + 1 === cardsLength) {
      resultModal.present({
        center: true,
        children: (
          <FlashcardResults overdue={meta ? meta.total_due : null} tryMore={tryMoreHandler} />
        )
      });
    } else {
      slider.current.slickNext();
    }
    setPressKeyboard(null);
  }

  const settings = {
    dots: true,
    swipe: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    adaptiveHeight: false,
    infinite: false,
    draggable: false,
    fade: !enableAnimation
  };

  return (
    <Page>
      <div className="flex-1 flex flex-col min-h-screen pb-24">
        <div className="mt-8 flex justify-between">
          <h1>Flashcards</h1>

          <Button
            leftIcon={
              <RiSettings5Fill
                size={27}
                onClick={() =>
                  settingsModal.present({
                    title: "Flashcard Settings",
                    center: true,
                    children: (
                      <FlashcardSettings
                        onSave={config => {
                          settingsModal.hide();
                          setSize(config.size);
                          setCardFront(config.cardFront);
                          setFont(config.font);
                          setAutoPlayAudio(config.autoPlayAudio);
                          setEnableAnimation(config.enableAnimation);
                        }}
                        initialValues={{ size, cardFront, font, autoPlayAudio, enableAnimation }}
                      />
                    )
                  })
                }
              />
            }></Button>
        </div>

        <div className="mt-4 space-y-2">
          Note: This is the old flashcard system. If you are still using it please note that it will
          not update towards your overall progress. Try the{" "}
          <Link className="font-bold" to="/review">
            New Lesson Deck Flashcards
          </Link>
          , where we review in the same way you learned the words - by lesson. This way makes it
          much easier to retain what you’re reviewing.
        </div>

        <div className="relative flex-1 flex flex-col" style={{ minHeight: 300 }}>
          <div className="container mx-auto mt-4 md:flex justify-between items-center space-y-4 md:space-y-0">
            <FlashcardsFilter />

            {meta && meta.total_due > 0 ? (
              <div className="flex space-x-8 items-center">
                <div className="flex space-x-4 items-center">
                  <div className="text-gray-600">
                    {correctCount} / {initialCardsCount}
                  </div>
                  <div className="text-gray-500">Overdue: {meta.total_due}</div>
                </div>
              </div>
            ) : null}
          </div>

          {loading ? (
            <Spinner center />
          ) : error ? (
            <Errors error={error} />
          ) : cards ? (
            cards.length === 0 ? (
              <div className="flex-1 flex items-center text-center justify-center">
                <div className="p-8 text-gray-500 text-2xl">
                  Earn new cards by completing videos!
                </div>
              </div>
            ) : (
              <div className="flex-1 flex flex-col">
                <div
                  className="flex-1 flex flex-col justify-center"
                  style={
                    enableAnimation ? { animationDuration: "0.5s", animationName: "slidein" } : null
                  }>
                  <Slider
                    className="focus:outline-none"
                    ref={slider}
                    {...settings}
                    afterChange={setCurrentIndex}>
                    {cards.map((card, index) => (
                      <Flashcard
                        key={index}
                        data={card}
                        next={next}
                        keyboardCounter={keyboardCounter}
                        pressKeyboard={pressKeyboard}
                        isShowing={index === currentIndex}
                        cardFront={card.cardFront}
                      />
                    ))}
                  </Slider>
                </div>

                {user && user.permissions.includes("UPLOAD_AUDIO") ? (
                  <div className="flex justify-center mb-6">
                    <AudioRecorder card={cards[currentIndex]} />
                  </div>
                ) : null}

                <div className="px-8 text-center text-sm text-gray-500">
                  Press space bar to reveal the card and numbers 1-6 to mark your answer.{" "}
                </div>

                <div className="mt-4 px-8 text-center text-xs md:text-sm text-gray-500 flex justify-center">
                  <Button
                    bold={false}
                    rightIcon={<IoIosSend />}
                    onClick={() =>
                      feedbackModal.present({
                        title: "Flashcard Feedback",
                        children: (
                          <Feedback
                            itemType="flashcard"
                            data={cards[currentIndex]}
                            hide={feedbackModal.hide}
                          />
                        )
                      })
                    }>
                    Submit feedback for the current flashcard.
                  </Button>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </Page>
  );
};

const FlashcardsFilter = () => {
  const history = useHistory();
  const { modelType, modelId } = useContext(FlashcardsContext);
  const { flashcardsMeta } = useContext(ApiContext);
  const { db } = useContext(ApiContext);

  const levels = flashcardsMeta.courses.flatMap(i => i.levels);

  function findLevel(type, id) {
    if (type === "level") return levels.find(i => i.id === id);

    if (type === "lesson") {
      const lesson = db.lessons.find(i => i.id === id);
      const chapter = db.chapters.find(i => i.id === lesson.chapter_id);
      const level = levels.find(i => i.id === chapter.level_id);
      return level;
    }

    return null;
  }

  const level = findLevel(modelType, modelId);

  const getModelName = () => {
    if (!modelId) return "All Courses";

    if (modelType === "word_list") {
      const item = flashcardsMeta.wordLists.find(i => i.id === parseInt(modelId));
      return item.name;
    }

    if (level) {
      const dbLevel = db.levels.find(i => i.id === level.id);
      const course = db.courses.find(i => i.id === dbLevel.course_id);
      return `${course.name} - ${level.name}`;
    }
  };

  const getSelectedValue = () => {
    if (!modelId === null) return "all";
    if (modelType === "word_list") return `word_list|${modelId}`;
    if (modelType === "level" || modelType === "lesson") return `level|${level.id}`;
  };

  const onChangeHandler = e => {
    const value = e.target.value;
    let url = "/flashcards";
    if (value === "all") {
      url = "/flashcards/all";
    } else {
      const [type, id] = value.split("|");
      console.log("type, id", type, id);
      url = `/flashcards/${type}/${id}`;
    }
    history.push(url);
    localStorage.setItem("flashcards:lastPage", url);
  };

  return (
    <div>
      <div className=" space-y-4 md:space-y-0 md:flex md:space-x-6">
        <div className="relative overflow-hidden">
          <div className="flex items-center space-x-2 text-sm md:text-lg text-blue-500 dark:text-blue-600 font-semibold bg-white dark:bg-gray-700 dark:bg-opacity-50 px-6 py-2 rounded-full">
            <div>{getModelName()}</div>
            <FiChevronRight size={21} className="transform-gpu rotate-90" />
          </div>
          <select
            className="absolute inset-0 appearance-none bg-transparent font-bold text-blue-500 text-xl cursor-pointer opacity-0 active:opacity-100"
            value={getSelectedValue()}
            onChange={onChangeHandler}>
            <option value="all">All Courses</option>
            {flashcardsMeta.courses.map((course, courseIndex) => (
              <optgroup key={courseIndex} label={course.name}>
                {course.levels.map(level => (
                  <option key={level.id} value={`level|${level.id}`} disabled={level.count === 0}>
                    {level.name}
                  </option>
                ))}
              </optgroup>
            ))}
            <optgroup label="Word Lists">
              {flashcardsMeta.wordLists.map(list => (
                <option key={list.id} value={`word_list|${list.id}`}>
                  {list.name} - {list.count}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
        {level ? <LessonFilter level={level} /> : null}
      </div>
      {modelType === "lesson" ? (
        <div className="mt-4 opacity-70">
          Note: filtering by an individual lesson will return all flashcards for that lesson,
          regardless of whether they are overdue or not.
        </div>
      ) : null}
    </div>
  );
};

const LessonFilter = ({ level }) => {
  const { db, completedItems } = useContext(ApiContext);
  const { modelType, modelId } = useContext(FlashcardsContext);
  const history = useHistory();

  if (!modelId || modelType === "word_list") return null;

  const chapters = db.chapters
    .filter(chapter => chapter.level_id === level.id)
    .map(chapter => ({
      ...chapter,
      lessons: db.lessons.filter(lesson => lesson.chapter_id === chapter.id)
    }));

  const lesson =
    modelType === "lesson" ? chapters.flatMap(i => i.lessons).find(i => i.id === modelId) : null;
  const selectedLessonName = lesson
    ? `Lesson ${toABC(lesson.ordering)} - ${lesson.name}`
    : "All Lessons";

  function chooseLesson(e) {
    const value = e.target.value;
    let url = "/flashcards";
    if (value === "all") {
      url = `/flashcards/level/${level.id}`;
    } else {
      url = `/flashcards/lesson/${value}`;
    }
    history.push(url);
    localStorage.setItem("flashcards:lastPage", url);
  }

  const selectedLessonId = modelType === "lesson" ? modelId : "all";

  return (
    <div className="relative overflow-hidden">
      <div className="flex items-center space-x-2 text-sm md:text-lg text-blue-500 dark:text-blue-600 font-semibold bg-white dark:bg-gray-700 dark:bg-opacity-50 px-6 py-2 rounded-full">
        <div>{selectedLessonName}</div>
        <FiChevronRight size={21} className="transform-gpu rotate-90" />
      </div>
      <select
        className="absolute inset-0 appearance-none bg-transparent font-bold text-blue-500 text-xl cursor-pointer opacity-0 active:opacity-100"
        value={selectedLessonId}
        onChange={chooseLesson}>
        <option value="all">All Lessons</option>
        {chapters.map(chapter => (
          <optgroup key={chapter.id} label={chapter.name}>
            {chapter.lessons.map(lesson => (
              <option
                key={lesson.id}
                value={lesson.id}
                disabled={!completedItems.lessonIds.includes(lesson.id)}>
                Lesson {toABC(lesson.ordering)} - {lesson.name}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
};

export default FlashcardsPage;
