import Errors from "newComponents/Errors";
import Spinner from "newComponents/Spinner";
import { createContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useGet } from "Services";
import DeckList from "./DeckList";
import SmallerDecks from "./SmallerDecks";

export const MetaContext = createContext();

const Decks = () => {
  const { loading, error, meta } = useGet("/flashcards/filter");

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;
  if (!meta) return null;

  return (
    <MetaContext.Provider value={{ loading, error, meta }}>
      <Switch>
        <Route path="/flashcards/decks" exact component={DeckList} />
        <Route path="/flashcards/decks/:type/:id" exact component={SmallerDecks} />
      </Switch>
    </MetaContext.Provider>
  );
};

export default Decks;
