import { AiFillInfoCircle } from "react-icons/ai";
import parseErrorMessage from "utils/errors";
import { Button } from "./base";

const Errors = ({ error }) => {
  const errorMessage = parseErrorMessage(error);

  const permissionError = errorMessage === "Please upgrade your subscription to view this content.";

  return (
    <div className="text-lg text-center text-gray-700 dark:text-gray-400 p-24 flex items-center justify-center flex-1">
      <div className="flex items-center">
        <AiFillInfoCircle size={24} />
        <div className="ml-2">{errorMessage}</div>
      </div>

      {permissionError ? (
        <a href={process.env.REACT_APP_SUBSCRIBE_PAGE} rel="noreferer">
          Check out our Plans
        </a>
      ) : null}
    </div>
  );
};

export default Errors;
