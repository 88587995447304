import Page from "newComponents/Page";
import Spinner from "newComponents/Spinner";
import Errors from "newComponents/Errors";
import { Link } from "react-router-dom";
import { Button } from "newComponents/base";
import { BsArrowRightCircle } from "react-icons/bs";
import { useModals } from "contexts/ModalProvider";
import { useContext } from "react";
import { LessonReviewContext } from "contexts/LessonReviewProvider";

const Dashboard = () => {
  const { loading, error, reviewsToday, allReviews, todayCompleted } =
    useContext(LessonReviewContext);
  const modal = useModals();

  if (loading) return <Spinner center label="Loading Flashcards..." />;
  if (error) return <Errors error={error} />;

  return (
    <Page>
      <h1 className="py-8 md:py-12">Review what you&apos;ve learned</h1>

      <div className="opacity-80">
        You&apos;ve worked hard to learn new things, now review so you can keep all the valuable
        information. We build our lessons meticulously so they&apos;re easier to retain and
        that&apos;s why you review by lesson also. Our lesson reviews are based on a complex
        algorithm that calculates when you need to review your lesson. Just follow our lead and
        you&apos;re awesome!
      </div>

      <div className="mt-2">
        <Button
          bold={false}
          onClick={() =>
            modal.present({
              title: "About Flashcards",
              children: <FlashcardIntro />
            })
          }>
          How do the flashcards work.
        </Button>
      </div>

      <div className="space-y-12 mt-8">
        <section className="space-y-10">
          <div>
            <h3 className="mb-4">Recommended Reviews Today</h3>
            {reviewsToday.length > 0 ? (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 text-white">
                  {reviewsToday.map((lesson, index) => (
                    <LessonReview
                      key={index}
                      lesson={lesson}
                      justCompleted={lesson.review_count === 1}
                    />
                  ))}
                </div>
              </>
            ) : todayCompleted.length > 0 ? (
              <div className="font-semibold text-green-500 dark:text-green-600 text-xl">
                You don&apos;t have any lesson reviews due today. Great job! 🎉🎉🎉 Go get some
                巧克力 🍫！
              </div>
            ) : (
              <div className="font-semibold opacity-60">
                Nothing to review for now. Come back later…
              </div>
            )}
          </div>

          {allReviews.length > 0 ? (
            <Button to="/review/upcoming" next>
              View all reviews
            </Button>
          ) : null}

          {todayCompleted.length > 0 ? (
            <div>
              <h3>Just Completed</h3>
              <div className="mt-2 opacity-80">
                Recently completed lesson reviews (refreshes every 24 hours).
              </div>
              <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10 text-white">
                {todayCompleted.map((lesson, index) => (
                  <LessonReview key={index} lesson={lesson} justCompleted={true} />
                ))}
              </div>
            </div>
          ) : null}
        </section>

        <div className="flex-1"></div>

        <hr />

        <Button className="mt-4 underline text-sm" to="/flashcards" bold={false} gray>
          Old Flashcard Page
        </Button>
      </div>
    </Page>
  );
};

export const LessonReview = ({ lesson, justCompleted }) => {
  const getColor = score => {
    if (score) {
      if (score < 40) return "bg-pink-500 dark:bg-pink-700";
      if (score < 60) return "bg-orange-500 dark:bg-orange-700";
      if (score < 80) return "bg-lime-500 dark:bg-lime-700";
      return "bg-green-500 dark:bg-green-700";
    }

    return "bg-sky-500 dark:bg-sky-700";
  };

  return (
    <Link
      to={`/review/lesson/${lesson.id}`}
      className={`p-6 sm:p-10 rounded-3xl cursor-pointer transform shadow bounce scale-100 hover:scale-102 active:scale-100 relative text-white hover:text-white active:text-white
        ${getColor(lesson.score)}
      `}>
      <div
        className={`absolute bottom-0 right-0 z-0 opacity-20 text-white font-serif italic
        ${lesson.score ? "text-9xl" : "text-8xl"}`}>
        {lesson.score ? lesson.score : "New"}
      </div>
      <div className="h-full flex flex-col justify-between">
        <div className="font-semibold opacity-80">{lesson.full_name}</div>
        <div>
          <h1 className="mt-8">{lesson.flashcard_count} Flashcards</h1>
          <div className="flex items-center mt-6 space-x-4">
            <div className="font-semibold text-2xl">Review {justCompleted ? "Again" : "Now"}</div>
            <BsArrowRightCircle size={37} />
          </div>
        </div>
      </div>
    </Link>
  );
};

const FlashcardIntro = () => (
  <div className="space-y-6">
    <p>
      At Domino all the words are related like a long keychain, so when you review you should review
      by lesson, the way it makes sense. When you finish studying a lesson it will appear on this
      page (review page) in blue at the very top. Blue lessons should be reviewed asap.
    </p>
    <p>
      How well you know the flashcards affects your overall lesson score. You can see the score by
      the color of the lesson deck and by the number “0-100”.
    </p>
    <ul className="list-inside list-disc">
      <li>Blue lesson decks: Not yet studied. Top priority.</li>
      <li>
        Green lesson decks: Scoring above 90% will get you a green deck and the lesson won’t
        reappear for another 10 days.
      </li>
      <li>Orange lesson decks: 60-90%. Will come back in 5 days.</li>
      <li>Red lesson decks: 0-59%. Will come back in 1 day.</li>
    </ul>
    <p>
      Unless you score a red lesson the time intervall will increase by x1.5 every time you finish
      the lesson deck. Lessons that are due today will be shown on this main review page, however,
      we don’t recommend you to review too many lessons in one day so if there are more than 6
      lesson due on one day you’ll still only be able to finish 6 decks. After 24 hours the page
      will automatically refresh with the most prioritised lesson decks.
    </p>

    <p>
      If you finish the daily recommended lessons and still want to review more you can go to{" "}
      <Link className=" underline" to="/review/upcoming">
        View all upcoming reviews
      </Link>{" "}
      and review any lesson. On this page you can also see when each lesson is due.
    </p>

    <p>
      As the time increases between every interval you’ll soon have just a few lessons to review and
      some days, even zero.
    </p>
  </div>
);

export default Dashboard;
