import { createContext, useEffect, useState } from "react";
import { useGet } from "Services";

export const LessonReviewContext = createContext({});

const isToday = x => (+new Date() - x) / (1000 * 3600 * 24) < 1;

function removeDuplicatedLessons(res, lesson) {
  if (res.find(i => i.id === lesson.id)) return res;
  return [...res, lesson];
}

const LessonReviewProvider = ({ children }) => {
  const [completedNotReviewTime, setCompletedNotReviewTime] = useState(
    localStorage.getItem("completedNotReviewTime")
      ? JSON.parse(localStorage.getItem("completedNotReviewTime"))
      : []
  );
  const [completedOverdueTime, setCompletedOverdueTime] = useState(
    localStorage.getItem("completedOverdueTime")
      ? JSON.parse(localStorage.getItem("completedOverdueTime"))
      : []
  );

  const { loading, error, data, refresh } = useGet("/lesson-reviews");

  useEffect(() => {
    localStorage.setItem("completedNotReviewTime", JSON.stringify(completedNotReviewTime));
    localStorage.setItem("completedOverdueTime", JSON.stringify(completedOverdueTime));
  }, [completedNotReviewTime, completedOverdueTime]);

  let notReview = [];
  let overdue = [];
  let upcoming = [];
  let reviewsToday = [];
  let todayCompleted = [];
  let allReviews = [];

  const totalDeckShouldReview = 6;

  if (data) {
    todayCompleted = data.recently_completed.reduce(removeDuplicatedLessons, []);

    notReview = data.not_reviewed.filter(hasFlashcards);
    overdue = data.overdue.filter(hasFlashcards);

    const notReviewCount = Math.min(notReview.length, 3);

    const overdueCount = totalDeckShouldReview - notReviewCount;

    reviewsToday = [
      ...notReview.slice(
        0,
        Math.max(0, notReviewCount - completedNotReviewTime.filter(isToday).length)
      ),
      ...overdue.slice(0, Math.max(0, overdueCount - completedOverdueTime.filter(isToday).length))
    ];

    upcoming = data.upcoming;
    allReviews = [...notReview, ...overdue, ...upcoming];
  }

  const nextReview = reviewsToday.length > 0 ? reviewsToday[0] : null;

  return (
    <LessonReviewContext.Provider
      value={{
        loading,
        error,
        refresh,
        reviewsToday,
        notReview,
        overdue,
        allReviews,
        todayCompleted,
        upcoming,
        nextReview,
        setCompletedNotReviewTime,
        setCompletedOverdueTime
      }}>
      {children}
    </LessonReviewContext.Provider>
  );
};

const hasFlashcards = lesson => lesson.flashcard_count > 0;

export default LessonReviewProvider;
