import { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";

const QuizChoiceQuestion = ({ result, answers, didAnswer }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    setSelectedAnswer(null);
  }, [answers]);

  useEffect(() => {
    if (!result) setSelectedAnswer(null);
  }, [result]);

  function selectAnswer(answer) {
    if (selectedAnswer === null) {
      setSelectedAnswer(answer);
      didAnswer(answer.is_correct ? "PASS" : "FAIL");
    }
  }

  if (!answers.length) {
    return (
      <div className="bg-red-500 text-sm mb-2 w-full text-white p-2 rounded-xl">
        <Trans>
          Uh oh! Looks like there aren&apos;t any answers for this question. Please click the submit
          feedback button below and let us know!
        </Trans>
      </div>
    );
  }

  return answers.map((a, index) => (
    <div
      key={a.id}
      className={`rounded-2xl overflow-hidden -mt-px
      ${
        selectedAnswer
          ? selectedAnswer.id === a.id
            ? "bg-gray-100 dark:bg-gray-700"
            : ""
          : "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
      }`}>
      <div
        className={`flex items-center space-x-4 p-2 lg:p-4 border-b dark:border-gray-700 `}
        onClick={() => selectAnswer(a)}>
        <span
          className={`text-base font-normal border-2 w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full flex-shrink-0
            ${selectedAnswer && a.is_correct ? "bg-green-500 border-green-500 text-white" : ""}
            ${selectedAnswer && !a.is_correct ? "bg-red-500 border-red-500 text-white" : ""}`}>
          {String.fromCharCode(1 + 64 + index)}
        </span>

        <span className="text-xl lg:text-2xl 2xl:lg:text-4xl">
          {a.text}
          {process.env.NODE_ENV === "development" && ` (${a.is_correct.toString()})`}
        </span>
      </div>
    </div>
  ));
};

export default QuizChoiceQuestion;
