import moment from "moment";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { RiFacebookFill } from "react-icons/ri";
import AppStore2022 from "assets/images/icons/AppStore2022.svg";

export const SITE_LINKS = [
  {
    title: "Domino OPEN",
    link: "/"
  },
  {
    title: "Lesson Summaries",
    link: "/review/lesson-summaries"
  },
  {
    title: "Dialogs",
    link: "/dialogs"
  },
  {
    title: "Forum",
    link: "https://forum.dominochinese.com/"
  },
  {
    title: "Discord",
    link: "https://discord.gg/wP9YnfSrVY"
  },

  {
    title: "Blog",
    link: "https://www.dominochinese.com/posts"
  },
  {
    title: "Testimonials",
    link: "https://www.dominochinese.com/testimonials/"
  },
  {
    title: "Learn Chinese in 9 weeks",
    link: "https://www.dominochinese.com/2020/12/20/success-story-vanessa/"
  },
  {
    title: "Learn Chinese for $50",
    link: "https://www.dominochinese.com/2020/12/20/success-story-jakub/"
  },
  {
    title: "Study Resources",
    link: "https://www.dominochinese.com/study-resources/"
  },
  {
    title: "FAQ",
    link: "https://www.dominochinese.com/faq/"
  },
  {
    title: "Help us Grow",
    link: "https://www.dominochinese.com/elementor-1774/"
  },
  {
    title: "The Domino Team",
    link: "https://www.dominochinese.com/meet-the-team"
  },
  {
    title: "Setup Keyboard",
    link: "https://www.dominochinese.com/set-up-keyboard/"
  },
  {
    title: "Domino Partners",
    link: "https://www.dominochinese.com/domino-partners/"
  },
  {
    title: "Domino Merch",
    link: "https://shopdomino.bigcartel.com/products"
  },
  {
    title: "Donate",
    link: "https://www.buymeacoffee.com/dominochinese"
  },
  {
    title: "Donation",
    link: "https://www.buymeacoffee.com/dominochinese"
  },
  {
    title: "Feedback",
    link: "/feedback"
  },
  {
    title: "Submit a Testimonial",
    link: "/testimonials/submit"
  },
  {
    title: "My Domino",
    link: "https://my.dominochinese.com"
  },
  {
    title: "Jobs in China",
    link: "https://jooble.org/jobs/China",
    className: "text-orange-500 dark:text-orange-600"
  }
];

const Footer = () => {
  const { user } = useContext(ApiContext);
  const links = SITE_LINKS;

  const programLinks = [
    {
      title: "Domino OPEN",
      link: "/"
    },
    {
      title: "Domino CLASSROOM",
      link: "https://classroom.dominochinese.com/"
    }
  ];
  const aboutLinks = [
    {
      title: "Help us Grow",
      link: "https://www.dominochinese.com/elementor-1774/"
    },
    {
      title: "Merch",
      link: "https://shopdomino.bigcartel.com/products"
    },
    {
      title: "Donation",
      link: "https://www.buymeacoffee.com/dominochinese"
    },
    {
      title: "Domino Partners",
      link: "https://www.dominochinese.com/domino-partners/"
    },
    {
      title: "About the Domino Team",
      link: "https://www.dominochinese.com/meet-the-team"
    }
  ];

  const socialIcons = [
    { link: "https://www.facebook.com/DominoChinese", icon: <RiFacebookFill /> },
    { link: "https://www.twitter.com/DominoChinese", icon: <AiOutlineTwitter /> },
    { link: "https://www.instagram.com/DominoChinese", icon: <AiOutlineInstagram /> }
  ];

  return (
    <footer className="no-print footer bg-gray-200 bg-opacity-30 dark:bg-gray-800 relative z-0 mt-20 border-t dark:border-gray-700 border-opacity-50">
      <div className="container mx-auto px-6 2xl:px-12 relative z-10 grid grid-cols-12 py-20 gap-6">
        <div className="col-span-full xl:col-span-4">
          <div className="fill-current flex h-14">
            <DCLogo />
          </div>

          <div className="mt-4">The best way to study Chinese.</div>

          <div className="flex mt-4">
            <a
              className="transform transition-all scale-100 hover:scale-110 duration-100 dark:opacity-70"
              href="https://apps.apple.com/cn/app/domino-chinese/id1410292152?l=en"
              rel="noopener noreferrer"
              target="_blank">
              <img src={AppStore2022} />
            </a>
          </div>

          <div className="mt-4">
            <h4>Contact</h4>
            <div className="mt-2">support@dominochinese.com</div>
          </div>
        </div>

        <div className="col-span-full sm:col-span-4 xl:col-span-2">
          <h4>PROGRAMS</h4>
          <div className="mt-2 space-y-2">
            {programLinks.map((i, index) => (
              <a
                className="text-gray-700 dark:text-gray-400 block text-sm hover:underline"
                href={i.link}
                target={i.link.includes("http") ? "_blank" : "_self"}
                rel="noopener noreferrer"
                key={index}>
                {i.title}
              </a>
            ))}
          </div>
        </div>

        <div className="col-span-full sm:col-span-4 xl:col-span-2">
          <h4>ABOUT</h4>
          <div className="mt-2 space-y-2">
            {aboutLinks.map((i, index) => (
              <a
                className="text-gray-700 dark:text-gray-400 block text-sm hover:underline"
                href={i.link}
                target={i.link.includes("http") ? "_blank" : "_self"}
                rel="noopener noreferrer"
                key={index}>
                {i.title}
              </a>
            ))}
          </div>
        </div>

        <div className="col-span-full sm:col-span-4 xl:col-span-4 flex flex-col items-start sm:items-end">
          <h4>SOCIAL MEDIA</h4>
          <div className="mt-6 space-y-6 flex flex-col sm:items-end">
            <div className="flex space-x-4">
              {socialIcons.map((i, index) => (
                <a
                  className="transform transition-all duration-200 scale-100 hover:scale-110 w-8 h-8 bg-white dark:bg-opacity-20 rounded-full flex items-center justify-center text-lg dark:text-gray-100"
                  key={index}
                  target="_blank"
                  rel="noreferer"
                  href={i.link}>
                  {i.icon}
                </a>
              ))}
            </div>

            <div>
              <DiscordLink
                title="DOMINO OPEN"
                subtitle="Join our Discord Server"
                link="https://discord.gg/wP9YnfSrVY"
              />
            </div>
          </div>
        </div>

        <div className="col-span-full pt-4 border-t dark:border-gray-700 border-opacity-50 space-y-2 sm:space-y-0 sm:flex justify-between items-center text-xs font-light">
          <p>
            Copyright &copy; {moment().format("YYYY")} Domino Education Ltd. All rights reserved.
          </p>

          <a
            className="text-gray-700 dark:text-gray-400 block"
            href="https://info.dominochinese.com/terms-and-conditions/"
            target="_blank"
            rel="noreferrer">
            Terms & Conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

const DiscordLink = ({ title, subtitle, link }) => (
  <a
    className="flex space-x-2 text-gray-100 hover:text-gray-200 items-center cursor-pointer transform transition-all scale-100 hover:scale-105 duration-200 px-4 rounded-2xl bg-indigo-500 dark:bg-indigo-800"
    href={link}
    target="_blank"
    rel="noreferrer">
    <div>
      <svg
        className="fill-current w-9"
        width="71"
        height="55"
        viewBox="0 0 71 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="71" height="55" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div className="leading-tight">
      <div style={{ fontSize: 8 }}>{subtitle}</div>
      <div className="font-bold text-xs">{title}</div>
    </div>
  </a>
);

export const DCLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 473.27 141.23">
    <g>
      <path d="M361.9,103.15v4.45c-1.8,1.69-3.88,1.08-5.95.82a5.3,5.3,0,0,1-.23-5.28C357.58,102.36,359.38,102.62,361.9,103.15Z" />
      <path d="M361.23,113.37v26.86c-1.67,1.39-3.47,1-5.65.47V113.49C357.05,112,359,112.57,361.23,113.37Z" />
      <path d="M261.59,51.38c0-2.13,0-4.27-.16-6.4-1-14.47-11.63-23.13-26.12-21.57-8.16.89-14.6,5-20.25,10.71A22.68,22.68,0,0,0,199.85,23.8c-10.08-2-17.69,2.64-25,9.15-.29-3.17-.5-5.46-.71-7.71H150.33V48.41Q140,25.4,116.07,23.58c-17.63-1.36-31,6.14-40.13,23.9V1.53H51.8V31.24c.41,2.47-2.19-.43-2.87-1C34.5,18.69,14.69,22.06,5.68,38.18-1.78,51.51-1.83,65.48,5.18,79,11.05,90.39,23.06,96,35.62,94.17c6.34-.91,10.91-5,15.9-9.09.26,3,.17,5.15.36,7.3,8.15,0,16.13.08,24.51.08V71.55C85,88.19,97.64,95.19,114.69,94.43s28.21-9.71,35.89-24.55V92.44H174.5V87.8c0-10.68-.18-21.37.08-32,.17-7.22,4.33-11.49,10.17-11.27,5.5.21,8.9,4.33,9.28,11.39.13,2.36,0,4.74,0,7.11V92.55H218.2V86c0-8.78,0-17.56,0-26.34,0-10.37,3.16-15.16,9.82-15.16s9.32,4.49,9.36,15.41c0,3.32,0,6.64,0,10V92.41H261.6C261.6,78.41,261.61,64.9,261.59,51.38Zm-223.17,23C30.28,74.48,24.09,68,24,59.13c-.11-8.65,6.1-15.74,14-15.94s14.14,6.65,14.24,15.48C52.27,67.81,46.61,74.28,38.42,74.38Zm74.23-.07h-.2c-8.36-.11-14.76-7.09-14.56-15.89.2-8.43,6.48-14.89,14.43-14.89h.32c8.28.18,14.79,7.31,14.57,16C127,68,120.76,74.31,112.65,74.31Z" />
      <path d="M445.88,24.66C426,19.6,409.15,27.75,398.11,48.24c-1.27-11.15-4.6-20.44-16.18-23.81-10.91-3.18-19.85.64-27.49,8.44-1.67-2.74.3-5.68-1.78-7.77H329.92V92.59h22.85c1.4-2,.83-4,.84-5.81.08-10.44-.09-20.89.12-31.33.14-6.63,4.09-10.8,9.73-11s9.55,3.79,10.09,10.59c.23,2.83.09,5.69.09,8.54,0,9.64,0,19.28,0,28.73h24.14V69.29c11.06,20.84,28.15,29.08,47.28,24.18,17-4.35,28.33-18.38,28.21-34.82C473.16,42.75,461.87,28.74,445.88,24.66ZM435.58,74.3c-8.59.17-14.93-6.38-14.95-15.45,0-8.86,6-15.34,14.29-15.32s14.49,6.64,14.63,15.21C449.69,67.72,443.93,74.13,435.58,74.3Z" />
      <path d="M452.16,129.62h20.06c.09-9.87-3.48-15.44-10.35-16.77a13.76,13.76,0,0,0-15.22,9c-2.41,6.73,0,14.11,5.76,17.38,6.45,3.67,13.32,2.37,18.51-4C465.15,132,456,140.72,452.16,129.62Zm7.22-11.47c4.26.1,6.24,2.64,6.7,7H452.26C453.2,120.78,455,118,459.38,118.15Z" />
      <path d="M401,129.73h20c0-10.17-3.61-15.63-10.51-16.89a13.73,13.73,0,0,0-15.13,9.07c-2.37,6.79.12,14.09,5.9,17.32,6.52,3.65,13.43,2.27,18.35-4.21C413.74,132.25,404.64,140.68,401,129.73Zm7.27-11.59c4.38.16,5.88,3.06,6.82,7.13H400.92C402,120.63,403.94,118,408.28,118.14Z" />
      <path d="M318.37,130.84l3.63,3c-2.72,4.38-6.32,6-10.23,6.89a18.62,18.62,0,0,1-21.27-10.27c-3.8-8.35-1.53-18.43,5.35-23.8,7.46-5.82,17.34-5.24,25.22,1.53,0,2.18-1.71,3.17-3.12,4.35-9.39-5.12-15.06-4.91-19.59.71a14.06,14.06,0,0,0,.28,17.71C303.32,136.38,308.85,136.45,318.37,130.84Z" />
      <path d="M331.5,115.67c13.23-5.9,18.58-2.42,18.62,11.47,0,4.26,0,8.52,0,12.69-1.9,2.13-3.89,1-6.3,1.23,0-5.54,0-10.47,0-15.4,0-3.75-1.4-6.71-5.49-6.91-4.37-.22-6.45,2.63-6.61,6.69-.2,4.95-.05,9.9-.05,14.59-2.1,1.54-3.88,1.27-6,.62V103.24c1.76-1.22,3.49-.94,5.85,0Z" />
      <path d="M385.12,140.94c0-5.48,0-10.41,0-15.34,0-3.75-1.45-6.69-5.54-6.85-4.38-.18-6.41,2.69-6.57,6.75-.19,4.93,0,9.88,0,14.56-2.14,1.48-3.91,1.27-6,.56V113.21c4.42-.33,4.42-.33,6.54,2.37,12-6,17.87-2.35,17.95,11,0,4.49,0,9,0,13.26C389.77,141.85,387.84,141,385.12,140.94Z" />
      <path d="M429,120.36c2.88,3.84,7.11,3.54,10.41,5.17,3.12,1.54,5.76,3.4,5.49,7.4s-2.61,6.46-6.4,7.55c-5.64,1.62-10.71.18-15.43-2.88a3.62,3.62,0,0,1,2.37-4.22c4.41,1.71,9.06,4.86,13.88.54-1.41-4.3-5-4-7.75-4.89-4.62-1.52-8.83-3.51-7.81-9.19,1.09-6.15,6.14-7.27,11.44-7a15,15,0,0,1,7.94,2.57c1,2-.25,3.1-1.47,4.21C437.6,119.3,433.39,115.35,429,120.36Z" />
    </g>
    <g>
      <path d="M276.43,133.25c-.88,4.83-3.48,8-8.54,7.69-4.62-.3-7.43-3.4-7.26-8,.19-4.9,3.13-7.81,8.32-7.55C273.86,125.66,275.75,129,276.43,133.25Z" />
      <path d="M247.94,133c-.7,4.75-3.14,8-8.22,7.91-4.69-.1-7.55-3.16-7.59-7.69s2.41-7.61,7.32-7.85C244.7,125.15,247,128.28,247.94,133Z" />
      <path d="M219.48,133c-.77,4.9-3.25,8.14-8.32,8-4.68-.15-7.51-3.22-7.51-7.76s2.48-7.57,7.39-7.79C216.34,125.17,218.54,128.43,219.48,133Z" />
      <path d="M182.59,125.33c5.5.21,8.29,3.29,8.19,8.08-.09,4.48-3,7.56-7.74,7.54-5,0-8-3.17-7.85-8.1C175.3,128.29,178.1,125.59,182.59,125.33Z" />
      <path d="M154.47,125.33c5.15.32,7.82,3.31,7.83,7.73,0,4.84-3.05,8-8.09,7.88-4.68-.12-7.55-3.2-7.53-7.74C146.7,128.33,149.59,125.43,154.47,125.33Z" />
      <path d="M134,132.92c-.49,4.46-2.72,7.68-7.38,8-5,.35-8.2-2.67-8.4-7.54-.18-4.46,2.29-7.66,7.17-8C130.6,125.08,133.05,128.13,134,132.92Z" />
      <path d="M97.87,125.35c4.75.28,7.44,3.26,7.46,7.69,0,4.84-3,8-8.06,7.91-4.69-.11-7.57-3.19-7.56-7.72C89.72,128.38,92.55,125.4,97.87,125.35Z" />
      <path d="M69.32,125.35c4.78.26,7.48,3.21,7.52,7.65,0,4.84-3,8-8,7.95-4.69-.08-7.58-3.16-7.6-7.68C61.21,128.43,64,125.43,69.32,125.35Z" />
      <path d="M40.87,125.35c4.76.27,7.46,3.24,7.49,7.67,0,4.84-3,8-8,7.93-4.69-.09-7.58-3.18-7.58-7.7C32.74,128.41,35.56,125.41,40.87,125.35Z" />
      <path d="M12.41,125.35c4.76.28,7.45,3.26,7.47,7.69,0,4.84-3,8-8.07,7.91-4.68-.11-7.56-3.19-7.55-7.72C4.27,128.38,7.1,125.4,12.41,125.35Z" />
      <path d="M11.91,104c5.17.11,8,3.18,8,8,0,4.47-2.94,7.6-7.63,7.65-5,.06-8-3.06-8-8C4.33,107.1,7,104.24,11.91,104Z" />
      <path d="M48.57,111.52c-.71,4.89-3.14,8.15-8.22,8.07-4.69-.08-7.55-3.14-7.61-7.67S35.13,104.31,40,104C45.3,103.76,47.59,107,48.57,111.52Z" />
      <path d="M268.83,104c4.74.3,7.41,3.3,7.41,7.72,0,4.84-3.06,8-8.11,7.87-4.68-.13-7.55-3.22-7.51-7.75C260.66,107,263.5,104,268.83,104Z" />
      <path d="M240,104c4.84.18,7.58,3.06,7.71,7.51.14,4.87-2.86,8.13-7.88,8.07s-7.91-3.28-7.69-8.23C232.38,106.78,235.18,104.1,240,104Z" />
      <path d="M211.35,104c5.14.13,8,3.2,7.93,8,0,4.46-3,7.59-7.66,7.62-5,0-8-3.08-8-8C203.73,107.07,206.44,104.23,211.35,104Z" />
      <path d="M183,104c5,.19,7.84,3.27,7.75,8.06a7.26,7.26,0,0,1-7.74,7.54c-5,0-8-3.15-7.86-8.08C175.31,107,178,104.18,183,104Z" />
      <path d="M162.49,111.8c-.8,4.79-3.33,8-8.4,7.78-4.65-.21-7.5-3.3-7.4-7.86.11-4.86,3-7.86,8.19-7.68C159.8,104.21,161.75,107.46,162.49,111.8Z" />
      <path d="M125.32,104c5.67.09,8.51,3.15,8.51,7.93,0,4.46-2.92,7.6-7.61,7.67-5,.07-8-3-8-8C118.24,107.1,121,104.31,125.32,104Z" />
      <path d="M105.52,111.65c-.68,4.74-3.1,8-8.18,7.94-4.69-.07-7.56-3.13-7.63-7.65s2.37-7.63,7.28-7.89C102.23,103.76,104.6,106.87,105.52,111.65Z" />
      <path d="M77,111.8c-.8,4.79-3.33,8-8.4,7.78-4.66-.21-7.5-3.3-7.4-7.86.1-4.86,3-7.86,8.19-7.68C74.35,104.21,76.29,107.46,77,111.8Z" />
      <path d="M307.56,0l22.76,10.87-7.48,16L300,16.14Z" />
      <path d="M290.44,93.83,268.53,83.46c9.76-20.55,19.4-40.85,29.28-61.67l21.92,10.39C310,52.69,300.39,72.88,290.44,93.83Z" />
    </g>
  </svg>
);

export default Footer;
