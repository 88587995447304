import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationBar } from "newComponents/base";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useGet, usePost } from "Services";
import Spinner from "newComponents/Spinner";
import { Button } from "newComponents/base";
import { ApiContext } from "contexts/ApiContext";
import ResultItem from "pages/dictionary/ResultItem";
import SimpleVideo from "newComponents/SimpleVideo";
import { BsChevronRight, BsExclamationCircleFill } from "react-icons/bs";
import { ModalContext, useModals } from "contexts/ModalProvider";

const DialogDetail = () => {
  const { user } = useContext(ApiContext);
  const { id } = useParams();
  const { loading, data } = useGet(`/dialogs/${id}`);

  if (loading) return <Spinner center />;
  if (!data) return null;

  if (data.access_type === "CLASSROOM" && !user.permissions.includes("VIEW_CLASSROOM_DIALOGS"))
    return <NoDialogPermission />;

  return <DialogDetailContent data={data} />;
};

const NoDialogPermission = () => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <div className="text-4xl text-orange-500 space-x-4 flex flex-col items-center">
        <BsExclamationCircleFill size={72} />
        <h3 className="mt-8">This Dialog is for Classroom Students Only.</h3>
      </div>

      <div className="mt-8 flex flex-col items-center">
        <a
          className="text-xl font-bold text-blue-500 hover:text-blue-600 flex space-x-2 items-center"
          href="https://classroom.dominochinese.com"
          rel="noreferrer"
          target="_blank">
          <span>Check out Domino Classroom</span>
          <BsChevronRight />
        </a>

        <Button className="mt-4" to="/dialogs">
          Back to Dialogs
        </Button>
      </div>
    </div>
  );
};

const DialogDetailContent = ({ data }) => {
  const { completedItems, setApiState, lastLocation } = useContext(ApiContext);
  const { hasModals } = useContext(ModalContext);
  const { id } = useParams();
  const playerRef = useRef();
  const [mounted, setMounted] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [showPhonetic, setShowPhonetic] = useState(
    localStorage.getItem("dialog:showPhonetic") === "true"
  );
  const [showTranslation, setShowTranslation] = useState(
    localStorage.getItem("dialog:showTranslation") === "true"
  );

  const wordModal = useModals();
  const videoModal = useModals();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    localStorage.setItem("dialog:showPhonetic", String(showPhonetic));
  }, [showPhonetic]);

  useEffect(() => {
    if (mounted && playerRef && playerRef.current) {
      if (hasModals) {
        if (!playerRef.current.paused) {
          playerRef.current.pause();
          setWasPlaying(true);
        } else {
          setWasPlaying(false);
        }
      } else {
        if (wasPlaying) playerRef && playerRef.current.play();
      }
    }
  }, [hasModals]);

  useEffect(() => {
    localStorage.setItem("dialog:showTranslation", String(showTranslation));
  }, [showTranslation]);

  const [hasMarkedCompleted, setHasMarkedCompleted] = useState(false);
  const [completeDialog, completeDialogRes] = usePost(`/dialogs/${id}/complete`, {
    onComplete: () => {
      setHasMarkedCompleted(true);
      setApiState("completedItems", {
        ...completedItems,
        dialogIds: [...completedItems.dialogIds, id]
      });
    }
  });

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-6 max-w-6xl">
        <NavigationBar
          title={data.title}
          backTo={
            lastLocation && lastLocation.from
              ? lastLocation.from.pathname + lastLocation.from.search
              : "/dialogs"
          }
        />
      </div>

      <div className="container mx-auto px-6 space-y-4 max-w-6xl">
        <div className="lg:flex lg:justify-between">
          <div className="flex space-x-4">
            <div className="flex">
              <label className="pr-2">Difficulty: </label>
              <div>{data.difficulty}</div>
            </div>
            <div className="flex">
              <label className="pr-2">Topics: </label>
              <div>{data.topics.map(i => i.name).join(", ")}</div>
            </div>
          </div>

          <div className="flex space-x-4 text-sm mt-4 lg:mt-0">
            <Button onClick={() => setShowPhonetic(prev => !prev)}>
              {showPhonetic ? "Hide" : "Show"} Phonetic
            </Button>
            <Button onClick={() => setShowTranslation(prev => !prev)}>
              {showTranslation ? "Hide" : "Show"} Translations
            </Button>
          </div>
        </div>

        <div>
          <audio className="w-full" controls ref={playerRef}>
            <source src={data.audio_src} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        {data.sentences.map((i, index) => (
          <Sentence
            data={i}
            key={index}
            showPhonetic={showPhonetic}
            showTranslation={showTranslation}
            selectWord={word =>
              wordModal.present({
                maxWidth: "max-w-5xl",
                children: (
                  <ResultItem
                    word={word}
                    watchVideo={video =>
                      videoModal.present({
                        maxWidth: "max-w-6xl",
                        children: <SimpleVideo video={video} />
                      })
                    }
                    videosInline={false}
                    wordTarget="_blank"
                  />
                )
              })
            }
          />
        ))}

        <div className="mt-10">
          <div className="flex mt-8 justify-between">
            {data.is_complete || hasMarkedCompleted ? (
              <Button
                className="flex items-center space-x-2 text-lg"
                disabled
                leftIcon={<AiFillStar size={21} className="text-yellow-500" />}>
                Completed
              </Button>
            ) : (
              <Button
                className="flex items-center space-x-2 text-lg"
                onClick={() => completeDialog()}
                disabled={completeDialogRes.loading}
                loading={completeDialogRes.loading}
                leftIcon={<AiOutlineStar size={21} />}>
                Mark Complete
              </Button>
            )}
          </div>
        </div>

        <div className="pb-12"></div>
      </div>
    </div>
  );
};

const Sentence = ({ data, showPhonetic, showTranslation, selectWord }) => {
  const { user } = useContext(ApiContext);
  return (
    <div className="sm:flex items-baseline text-xl">
      <label className="pr-4">{data.actor}: </label>
      <div>
        <div className="flex items-center space-x-1 flex-wrap">
          {data.words.map((word, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center cursor-pointer"
              onClick={() => selectWord(word)}>
              {showPhonetic ? (
                <div className="flex">
                  {word.characters.map((character, characterIndex) => (
                    <div
                      key={characterIndex}
                      style={{ color: user.tone_colours[character.tone ? character.tone : 0] }}>
                      {character.pinyin_unicode || <span>&nbsp;</span>}
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="flex">
                {word.characters.map((character, characterIndex) => (
                  <div
                    key={characterIndex}
                    style={{ color: user.tone_colours[character.tone ? character.tone : 0] }}>
                    {character.text}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {showTranslation ? <div>{data.translation}</div> : null}
      </div>
    </div>
  );
};

export default DialogDetail;
