import { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { Button, Input } from "newComponents/base";

const clean = s => s.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()。，、？！]/g, "");

const QuizTextQuestion = ({ quizId, result, answers, didAnswer }) => {
  const [answer, setAnswer] = useState("");
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    setSubmited(false);
  }, [answers]);

  useEffect(() => {
    if (!result) {
      setAnswer("");
      setSubmited(false);
    }
  }, [result]);

  const isCorrect = answers.filter(i => clean(i.text) === clean(answer)).length > 0;

  const isValid = answer.trim().length > 0;

  function submit() {
    if (!isValid) return;
    setSubmited(true);
    didAnswer(isCorrect ? "PASS" : "FAIL");
  }

  return (
    <form
      method="post"
      onSubmit={e => {
        e.preventDefault();
        if (!submited) submit();
      }}>
      <div className="flex flex-col">
        <Input
          onChange={e => setAnswer(e.target.value)}
          value={answer}
          type="text"
          disabled={submited}
          placeholder="Input your answer here."
          className={`sm:text-2xl ${submited && isCorrect ? "bg-green-500 dark:bg-green-600" : ""}`}
        />
        <div className="flex sm:justify-end mt-6 sm:mt-2">
          <a
            className="text-xs underline"
            href="https://www.youtube.com/watch?v=77NwhjytO70"
            target="_blank"
            rel="noreferrer">
            Learn how to set up Chinese keyboard.
          </a>
        </div>

        {submited ? (
          <div>
            <div className="flex items-center p-4 rounded-xl bg-gray-100 dark:bg-gray-700 mt-4">
              <div>
                <label>Correct Answers:</label>
              </div>
              <div className="flex ml-2 text-2xl">
                {answers.map(answer => answer.text).join(" / ")}
              </div>
            </div>

            {quizId === "7cd7b2c1-ee81-4559-8dc8-8e77668a13f0" && isCorrect ? (
              <div className="text-green-600 indent-4 mt-4">
                🎉🎉🎉 Wow! You've just written your first (as far as I know) character! Soooo good!
              </div>
            ) : null}
          </div>
        ) : (
          <div className="flex mt-6 space-x-8">
            <Button disabled={submited || !isValid} onClick={submit} type="button">
              <Trans>Submit Answer</Trans>
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default QuizTextQuestion;
