import gradstop from "gradstop";
import { useEffect, useState } from "react";

function ProgressBar({ progress = 0 }) {
  const [realProgress, setRealProgress] = useState(0);
  const [showPercentage, setShowPercentage] = useState(false);

  const colors = gradstop({
    stops: 101,
    inputFormat: "hex",
    colorArray: ["#10b981", "#84CC16"]
  });

  useEffect(() => {
    const i = setTimeout(() => {
      setShowPercentage(true);
      setRealProgress(progress);
    }, 600);
    return () => {
      clearInterval(i);
    };
  }, [progress]);

  return (
    <div className="h-2 relative bg-gray-200 rounded-full">
      <div
        className="h-full rounded-full transition-all duration-1000 relative"
        style={{ width: `${realProgress}%`, backgroundColor: colors[parseInt(progress)] }}>
        <div
          className={`
            absolute bottom-full text-xs mb-1 italic transition-all duration-300
            ${progress > 5 ? "right-0" : "left-0"}
            ${showPercentage ? "opacity-70" : "opacity-0"}
            `}>
          {progress}%
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
