import { useContext, useEffect, useState } from "react";
import { http, usePost } from "Services";
import { Text, Button, FileSelector, Input, Select, NavigationBar } from "newComponents/base";
import { toast } from "react-toastify";
import { ApiContext } from "contexts/ApiContext";
import { uuidv4 } from "utils/string";
import ProgressRing from "newComponents/ProgressRing";
import { RiCloseCircleFill } from "react-icons/ri";
import parseErrorMessage from "utils/errors";
import { isMobile } from "./utils";
import { Footer } from "./Footer";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Feedback = () => {
  const { showFeedbackForm } = useContext(ApiContext);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("0");
  const [platform, setPlatform] = useState("0");
  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [createdIssueId, setCreatedIssueId] = useState(null);

  useEffect(() => {
    const parseHandler = res => {
      if (showFeedbackForm) return;
      const data = res.clipboardData;
      const files = [...data.files].map(file => ({
        id: uuidv4(),
        file,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: null
      }));
      files.forEach(uploadFile);
      setFiles(prev => [...prev, ...files]);
    };

    window.addEventListener("paste", parseHandler);

    return () => {
      window.removeEventListener("paste", parseHandler);
    };
  }, [showFeedbackForm]);

  const url = "/feedback";
  let payload = {
    title,
    description: content,
    type,
    platform,
    images
  };

  payload.description = `
      ${payload.description}

      ${isMobile() ? "[is mobile device]" : ""}

      ${window.location.href}

      ${navigator.userAgent}
      Page Size: ${window.innerWidth} x ${window.innerHeight}
      `;

  const [submit, { loading }] = usePost(url, {
    onComplete: res => {
      toast.success("Thank you! We have received your feedback.");
      setContent("");
      setImages([]);
      setFiles([]);
      setType("0");
      setPlatform("0");
      setCreatedIssueId(res.data.id);
    },
    onError: error => toast.error(parseErrorMessage(error))
  });

  function validateInput() {
    if (title === "") {
      toast.error("Please input a Title.");
      return false;
    }
    if (type === "0") {
      toast.error("Please select the Type.");
      return false;
    }
    if (platform === "0") {
      toast.error("Please select the Platform.");
      return false;
    }
    if (content === "") {
      toast.error("Please add some description.");
      return false;
    }
    return true;
  }

  function submitHandler() {
    if (validateInput()) submit(payload);
  }

  function uploadFile({ id, file }) {
    var formData = new FormData();
    formData.append("file", file);
    http
      .post("/media", formData, {
        onUploadProgress: function (e) {
          const progress = Math.round((e.loaded * 100) / e.total);
          setFiles(prev => prev.map(i => (i.id !== id ? i : { ...i, progress })));
        }
      })
      .then(res => {
        setImages(prev => [...prev, res.data.data.name]);
        setFiles(prev => prev.map(i => (i.id === id ? { ...i, uploaded: true } : i)));
      })
      .catch(error => toast.error(error));
  }

  const uploadingImages = files.filter(i => i.progress < 100 && i.error === null).length > 0;

  return (
    <div className="container mx-auto p-6 max-w-4xl">
      <NavigationBar title="Feedback" subtitle="Get in touch with us." />

      <div className="mt-8 space-y-4">
        <div className="flex items-center">
          <label className="w-32 flex-shrink-0 mr-4">Title: </label>
          <Input
            className="flex-1 dark:bg-gray-800"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>

        <div className="flex items-center">
          <label className="w-32 flex-shrink-0 mr-4">Type: </label>
          <Select value={type} onChange={e => setType(e.target.value)}>
            <option disabled value="0">
              Select Type
            </option>
            <option value="FEATURE">FEATURE</option>
            <option value="ENHANCEMENT">ENHANCEMENT</option>
            <option value="SUBSCRIPTION">SUBSCRIPTION</option>
            <option value="BUG">BUG</option>
            <option value="VIDEO_BUG">VIDEO_BUG</option>
            <option value="MARKETING">MARKETING</option>
            <option value="RESEARCH">RESEARCH</option>
          </Select>
        </div>

        <div className="flex items-center">
          <label className="w-32 flex-shrink-0 mr-4">Platform: </label>
          <Select value={platform} onChange={e => setPlatform(e.target.value)}>
            <option disabled value="0">
              Select Platform
            </option>
            <option value="ANDROID">ANDROID</option>
            <option value="IOS">IOS</option>
            <option value="STUDY">STUDY</option>
            <option value="SERVER">SERVER</option>
            <option value="WP">WP</option>
            <option value="FORUM">FORUM</option>
            <option value="ADMIN">ADMIN</option>
            <option value="CONTENT">CONTENT</option>
            <option value="THIRD_PARTY">THIRD_PARTY</option>
          </Select>
        </div>

        <div className="flex items-top">
          <label className="w-32 flex-shrink-0 mr-4 mt-3">Description: </label>
          <div className="flex-1">
            <Text
              placeholder="Description"
              rows={8}
              value={content}
              onChange={e => setContent(e.target.value)}
            />
            <div className="opacity-60 text-xs px-2">
              You can copy and paste images into the description.
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap mt-4">
        {files.map(i => (
          <div key={i.id} className="mr-2 mb-2 h-24 relative">
            <div
              className="cursor-pointer text-red-500 absolute p-2 right-0"
              onClick={() => setFiles(prev => prev.filter(prevItem => prevItem.id !== i.id))}>
              <RiCloseCircleFill className="text-xl" />
            </div>
            <img className="h-full" src={i.preview} alt="upload preview" />
            {i.error ? (
              <div>error</div>
            ) : i.progress < 100 ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <ProgressRing progress={i.progress} radius={30} stroke={3} showPercentage={true} />
              </div>
            ) : null}
          </div>
        ))}
      </div>

      <div className="px-2">
        <FileSelector
          accept="image/*"
          title="+ Add Images"
          className="mt-4"
          onChange={e => {
            const files = [...e.target.files].map(file => ({
              id: uuidv4(),
              file,
              preview: URL.createObjectURL(file),
              progress: 0,
              uploaded: false,
              error: null
            }));
            files.forEach(uploadFile);
            setFiles(prev => [...prev, ...files]);
            e.target.value = null;
          }}
        />
      </div>

      <div className="px-2">
        <Button
          loading={loading}
          disabled={loading || uploadingImages}
          className="text-xl mt-6 flex items-center"
          onClick={submitHandler}>
          Submit
        </Button>
      </div>

      {createdIssueId ? (
        <div className="my-6 ml-2 text-lg font-semibold text-green-500 rounded-full flex items-center space-x-2">
          <IoIosCheckmarkCircle size={27} />
          <span>Thanks for you feedback. You could review and follow up this case </span>
          <a
            href={`https://my.dominochinese.com/feedback/${createdIssueId}`}
            target="_blank"
            rel="noreferrer">
            here
          </a>
          .
        </div>
      ) : null}

      <Footer />
    </div>
  );
};

export default Feedback;
