import { toABC } from "utils/string";
import { FaPlay, FaChevronRight } from "react-icons/fa";
import { Button } from "newComponents/base";
import VideoPlayer from "newComponents/video/VideoPlayer";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import Spinner from "./Spinner";
import Errors from "./Errors";
import { useGet } from "Services";

export const VideoPreview = ({ video, onClick }) => {
  const { db } = useContext(ApiContext);
  const lesson = db.lessons.find(i => i.id === video.lesson_id);

  return (
    <div className="w-full" onClick={onClick}>
      <div className="text-white cursor-pointer video-thumb-container">
        <div className="relative rounded-xl overflow-hidden">
          <div
            className="bg-cover bg-center bg-no-repeat bg-gray-200 dark:bg-gray-700"
            style={{ paddingTop: "56.25%", backgroundImage: `url(${video.thumb})` }}></div>
          <div className="absolute items-center justify-center flex inset-0">
            <div
              className="flex flex-col justify-center items-center play-button transform transition-transform duration-300 scale-100 translate-y-2"
              style={{
                textShadow: "rgb(0 0 0 / 75%) 0px 0px 30px"
              }}>
              <div className="text-4xl md:text-3xl">
                <FaPlay />
              </div>
              <h3 className="mt-2">{video.name}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div className="text-gray-500 text-xs">
          <div>
            {video.course_name} / {video.level_name} / {video.chapter_name}
          </div>
          <div>
            Lesson {toABC(lesson.ordering)}: {video.lesson_name}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SimpleVideoById = ({ id }) => {
  const { loading, error, data } = useGet(`frontend/videos/${id}`);

  if (loading) return <Spinner center />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  return <SimpleVideo video={data} />;
};

const SimpleVideo = ({ video }) => {
  const { db } = useContext(ApiContext);
  const lesson = db.lessons.find(i => i.id === video.lesson_id);
  const chapter = db.chapters.find(i => i.id === video.chapter_id);
  const level = db.levels.find(i => i.id === video.level_id);
  const course = db.courses.find(i => i.id === video.course_id);

  return (
    <div>
      <div className="flex items-center justify-between whitespace-nowrap">
        <div className="flex items-center flex-wrap">
          <Button
            className="flex items-center"
            to={`/study/courses/${video.course_id}`}
            target="_blank">
            {course.name}
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <Button
            className="flex items-center"
            to={`/study/levels/${video.level_id}`}
            target="_blank">
            {level.name}
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <div className="flex items-center font-bold text-gray-600 dark:text-gray-400">
            {chapter.name}
          </div>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <Button
            className="flex items-center"
            to={`/study/${video.course_id}/${video.level_id}/${video.chapter_id}/${video.lesson_id}/${video.id}`}
            target="_blank">
            Lesson {toABC(lesson.ordering)} - {lesson.name}
          </Button>
        </div>
      </div>

      <h1 className="mt-6">{video.name}</h1>
      <div className="mt-8">
        <VideoPlayer video={video} forcePlaying={video !== null} alwaysWide={true} />
      </div>
    </div>
  );
};

export default SimpleVideo;
