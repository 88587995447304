import { IoPlayCircleOutline } from "react-icons/io5";
import { RoundButton } from "./base";
import Countdown from "./Countdown";
import SimpleVideoPlayer from "./SimpleVideoPlayer";

const videoSrc = "https://materials.dominochinese.com/web-v4/HUGH+Discount+per+year+on+Website.mp4";
const thumbSrc = "https://materials.dominochinese.com/web-v4/discount2.jpg";

const CouponModalContentNineDollars = ({ campaign, checkout }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-t-3xl pb-16">
      <div className="flex flex-col items-center space-y-8 p-12">
        <div className="text-4xl text-center">
          Grab the biggest discount yet! <b>Save 40%</b>. Forever.
        </div>
        <Countdown date={campaign.endDate} />

        <div>
          <RoundButton
            className="bg-sky-500 dark:bg-sky-600 px-8 py-2 tracking-wider text-lg"
            onClick={checkout}>
            Check out deal
          </RoundButton>
        </div>
      </div>

      <div className="relative">
        <div
          className="w-full"
          style={{
            paddingBottom: `${(1060 * 100) / 1794}%`
          }}></div>
        <div className="absolute inset-0 z-10">
          <SimpleVideoPlayer
            src={videoSrc}
            thumb={thumbSrc}
            playButton={<IoPlayCircleOutline size={100} />}
          />
        </div>
      </div>
    </div>
  );
};

export default CouponModalContentNineDollars;
