import { ApiContext } from "contexts/ApiContext";
import { useContext } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from "recharts";

export const DCBarChart = ({ className = "", title, data, color = "#10B981" }) => {
  const { screenSize } = useContext(ApiContext);
  return (
    <div
      className={`mt-8 bg-white dark:bg-gray-800 rounded-xl py-6 pr-6 shadow-sm w-full relative ${className}`}>
      <h4 className="px-6 mb-4">{title}</h4>
      <ResponsiveContainer width="99%" aspect={screenSize.width > 1023 ? 4 : 2}>
        <BarChart
          data={data}
          stackOffset="silhouette"
          margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Bar dataKey="value" maxBarSize={40} minBarSize={10} fill={color}>
            <LabelList dataKey="value" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
