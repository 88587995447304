import CouponModalContent from "newComponents/CouponModalContent";
import prmoCoursesImage from "assets/images/promo/Courses.png";
import promoClassroomImage from "assets/images/promo/Classroom.png";
import CouponModalContentNineDollars from "newComponents/CouponModalContentNineDollars";

const VideoPromo = ({ id, coupon, bigDiscount, checkout }) => {
  if (id === "eb049b58-46bd-4a3e-b066-e40fa6f4bc3a" && coupon) {
    if (bigDiscount) return <CouponModalContentNineDollars checkout={checkout} />;
    return <CouponModalContent coupon={coupon} />;
  }

  if (id === "c8421346-2dd5-41ec-9ec0-fb33db83c7dc")
    return (
      <div>
        <img src={prmoCoursesImage} alt="Domino Courses" />

        <div className="p-12">
          Did you know we have 5000+ videos? Select "courses" and browse between beginner to
          academic Chinese. We have it all!
        </div>
      </div>
    );

  return (
    <div>
      <img src={promoClassroomImage} alt="Domino Courses" />

      <div className="p-12">
        Prefer to learn with a teacher? Check out Domino Classroom. Join a classroom and we will
        guarantee you'll learn Chinese in 9 months! (bold 9 months) That's it.
      </div>
    </div>
  );
};

export default VideoPromo;
