import { useContext, useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { VscRefresh } from "react-icons/vsc";
import { useGet, usePost } from "Services";
import { Button, PrimaryLink } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import QuizChoiceQuestion from "./Choice";
import QuizTextQuestion from "./Text";
import QuizSentenceQuestion from "./Sentence";
import { ApiContext } from "contexts/ApiContext";
import AnswerResult from "./AnswerResult";
import { shuffledArray } from "utils/array";
import Feedback from "pages/feedback";
import { IoIosSend, IoMdVolumeHigh, IoMdVolumeOff } from "react-icons/io";
import correctSound from "assets/quiz-correct.mp3";
import errorSound from "assets/quiz-error.mp3";
import { MdOutlineEditOff } from "react-icons/md";
import { useModals } from "contexts/ModalProvider";
import VideoPromo from "../VideoPromo";

export const correctAudio = new Audio(correctSound);
export const errorAudio = new Audio(errorSound);

const VideoQuiz = ({ id, watchVideoAgain, goNextVideo, lastVideoOfLesson, coupon, bigDiscount, checkout }) => {
  const { loading, error, data } = useGet(`frontend/video/${id}/quiz`);
  const [submitQuizResult] = usePost(`frontend/video/${id}/quiz`);
  const { completedItems, setApiState } = useContext(ApiContext);
  const [result, setResult] = useState(null);
  const [choiceAnswers, setChoiceAnswers] = useState([]);
  const modal = useModals();

  useEffect(() => {
    if (data) setChoiceAnswers(shuffledArray(data.questions[0].answers));
  }, [data]);

  if (loading) return <Spinner center />;
  if (error) return JSON.stringify(error);
  if (!data || data.questions.length === 0) return null;

  const quiz = data.questions[0];

  function didAnswer(result) {
    setResult(result);

    if (!localStorage.getItem("disableQuizSound")) result === "PASS" ? correctAudio.play() : errorAudio.play();

    submitQuizResult({ status: result });

    if (result === "PASS") {
      setApiState("completedItems", {
        ...completedItems,
        videoIds: completedItems && completedItems.videoIds ? [...completedItems.videoIds, id] : [id],
      });

      const videosToPromo = [
        "c8421346-2dd5-41ec-9ec0-fb33db83c7dc", // video 2
        "4816c673-bc4b-4e01-a82b-1c6ed3dd69fb", // video 5
        "eb049b58-46bd-4a3e-b066-e40fa6f4bc3a", // video 8
      ];

      if (videosToPromo.includes(id))
        modal.present({
          center: bigDiscount ? false : true,
          noPadding: true,
          maxWidth: bigDiscount ? "max-w-6xl" : "max-w-5xl",
          children: <VideoPromo bigDiscount={bigDiscount} coupon={coupon} id={id} checkout={checkout} />,
        });
    }
  }

  return (
    <div>
      <div className="p-6 sm:p-10">
        <div className="text-gray-500">Video Quiz</div>
        <div className="my-6 text-xl lg:text-2xl 2xl:text-4xl">{quiz.text}</div>

        {quiz.type === "text" ? (
          <QuizTextQuestion quizId={id} result={result} answers={quiz.answers} didAnswer={didAnswer} />
        ) : quiz.type === "choice" ? (
          <QuizChoiceQuestion
            result={result}
            answers={choiceAnswers}
            onSelect={(answer) => {
              submitQuizResult(answer.is_correct ? "PASS" : "FAIL");
            }}
            didAnswer={didAnswer}
          />
        ) : quiz.type === "sentence" ? (
          <QuizSentenceQuestion result={result} correctAnswer={quiz.answers[0].text} didAnswer={didAnswer} />
        ) : (
          "No this type of quiz."
        )}
      </div>

      {goNextVideo ? (
        <div className="m-6 sm:my8 sm:mx-10 flex justify-start">
          <Button gray onClick={goNextVideo} leftIcon={<MdOutlineEditOff />}>
            <Trans>Skip Quiz</Trans>
          </Button>
        </div>
      ) : null}

      {result !== null ? (
        <div
          className={`sm:px-3 py-8
            ${result === "PASS" ? "bg-green-200" : "bg-red-200"}
          `}
        >
          <div className="space-y-6 sm:space-y-0 sm:flex items-center justify-between px-6 sm:px-8">
            <div>
              <AnswerResult result={result} />

              <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-6 items-center justify-center">
                <Button rightIcon={<VscRefresh size={21} />} onClick={watchVideoAgain}>
                  Watch Video Again
                </Button>
                <Button
                  rightIcon={<IoIosSend size={18} />}
                  onClick={() =>
                    modal.present({
                      title: "Quiz Feedback",
                      center: true,
                      children: <Feedback itemType="quiz" itemId={quiz.id} data={{ title: quiz.text }} hide={modal.hide} />,
                    })
                  }
                >
                  Quiz Feedback
                </Button>
              </div>
            </div>

            {goNextVideo ? (
              <Button className="text-2xl 2xl:text-3xl" next green={result === "PASS"} red={result === "FAIL"} onClick={goNextVideo}>
                Next Video
              </Button>
            ) : lastVideoOfLesson ? (
              <PrimaryLink to={`/study/lesson/${lastVideoOfLesson.id}/quiz`}>Take the Lesson Quiz</PrimaryLink>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VideoQuiz;
