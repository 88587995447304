import { ApiContext } from "contexts/ApiContext";
import Spinner from "newComponents/Spinner";
import { useContext, useState } from "react";
import { http, useGet } from "Services";
import { timeSince } from "utils/date";
import Achievements from "./Achievements";
import { DCBarChart } from "./Chart";
import FavouriteVideos from "./FavouriteVideos";
import { FaUserCircle } from "react-icons/fa";
import SubscriptionInfo from "./SubscriptionInfo";
import { uuidv4 } from "utils/string";
import { toast } from "react-toastify";
import ProgressRing from "newComponents/ProgressRing";
import UserFullName from "./UserFullName";
import Settings from "./Settings";
import { IoIosSend } from "react-icons/io";

export const Badge = ({ className = "", title, value }) => (
  <div className={`bg-white dark:bg-gray-800 p-6 rounded-xl text-center shadow-sm ${className}`}>
    <div className="font-semibold text-xl md:text-3xl">{value}</div>
    <div className="text-gray-500 mt-2 text-sm lg:text-base">{title}</div>
  </div>
);

const DashboardPage = () => {
  const { user, setApiState } = useContext(ApiContext);
  const [uid] = useState(uuidv4());
  const [avatarUploadProgress, setAvatarUploadProgress] = useState(0);

  const { loading, data } = useGet(`/profile/${user.id}`);

  if (loading) return <Spinner center />;
  if (!data) return null;

  const videoChartData = data.videos_chart.labels.map((label, index) => ({
    name: label,
    value: data.videos_chart.datasets[0].data[index]
  }));

  const flashcardChartData = data.lesson_reviews_chart
    ? data.lesson_reviews_chart.labels.map((label, index) => ({
        name: label,
        value: data.lesson_reviews_chart.datasets[0].data[index]
      }))
    : [];

  return (
    <div className="container mx-auto px-6 md:px-8 2xl:px-12 md:flex md:space-x-8 my-8">
      <div className="md:w-80 2xl:w-96 flex-shrink-0 space-y-8">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-xl text-center shadow-sm relative">
          <div className="p-8 relative">
            {user.avatar == "https://materials.dominochinese.com/misc/anonymous-user.png" ? (
              <div className="flex justify-center">
                <FaUserCircle size={200} />
              </div>
            ) : (
              <div
                className="w-full rounded-full overflow-hidden bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${user.avatar})`,
                  paddingBottom: "100%"
                }}></div>
            )}
            {avatarUploadProgress > 0 && avatarUploadProgress < 100 ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <ProgressRing progress={avatarUploadProgress} size={30} />
              </div>
            ) : null}
            <label
              className="absolute inset-0 flex items-center justify-center opacity-0 transition-all transform-gpu hover:opacity-100 duration-300 cursor-pointer hover:bg-gray-900 hover:bg-opacity-20 text-gray-100 rounded-full m-8"
              htmlFor={uid}>
              Update Avatar
              <input
                accept="image/*"
                id={uid}
                className="hidden"
                type="file"
                onChange={e => {
                  if (e.target.files.length > 0) {
                    const file = [...e.target.files][0];
                    e.target.value = null;
                    var formData = new FormData();
                    formData.append("file", file);
                    setAvatarUploadProgress(0);
                    http
                      .post("/avatar", formData, {
                        onUploadProgress: function (e) {
                          const progress = Math.round((e.loaded * 100) / e.total);
                          setAvatarUploadProgress(progress);
                        }
                      })
                      .then(res => {
                        setApiState("user", { ...user, avatar: res.data.avatar });
                        setAvatarUploadProgress(0);
                      })
                      .catch(error => {
                        toast.error(error);
                        setAvatarUploadProgress(0);
                      });
                  }
                }}
              />
            </label>
          </div>

          <UserFullName />

          <div className="mt-2">{user.email}</div>

          <div className="text-gray-400 mt-2">
            Joined {timeSince(new Date(data.user.created_at))} ago
          </div>
        </div>

        <SubscriptionInfo />

        <Achievements userAchievements={data.achievements} />

        <div className="hidden md:block">
          <Settings />
        </div>
      </div>

      <div className="space-y-8 flex-1 mt-8 md:mt-0">
        <div className="space-y-8 lg:space-y-0 lg:flex -mr-8">
          <Badge
            className="flex-1 py-12 mr-8"
            title="Total Experience"
            value={data.total_experience}
          />
          <Badge
            className="flex-1 py-12 mr-8"
            title="LESSONS COMPLETED"
            value={data.lessons_completed}
          />
          <Badge
            className="flex-1 py-12 mr-8"
            title="VIDEOS COMPLETED"
            value={data.videos_completed}
          />
          <Badge
            className="flex-1 py-12 mr-8"
            title="WORDS LEARNED"
            value={data.total_flashcards}
          />
        </div>

        <DCBarChart data={videoChartData} title="Videos Completed Past Week" />
        <DCBarChart data={flashcardChartData} title="Flashcards Studied in Lesson Reviews" />

        <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
          <a
            className="font-bold"
            href="https://my.dominochinese.com/feedback"
            target="_blank"
            rel="noreferrer">
            View My Feedback <IoIosSend className="inline pl-2" size={27} />
          </a>
        </div>

        <FavouriteVideos />

        <div className="md:hidden">
          <Settings />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
