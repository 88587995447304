import { FaPlay, FaPause } from "react-icons/fa";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Duration from "../Duration";
import { ProgressBar } from "../../newComponents/base";

const VideoPlaybackControls = ({
  playing,
  played,
  loaded,
  togglePlay,
  onSeekBeforeChange,
  onSeekChange,
  onSeekAfterChange,
  duration
}) => (
  <div className="flex flex-1 items-center space-x-6">
    <div className="cursor-pointer w-8" onClick={togglePlay}>
      {playing ? <FaPause /> : <FaPlay />}
    </div>

    <Slider
      className="w-full"
      value={parseFloat(played)}
      max={1}
      step={0.00001}
      onChange={onSeekChange}
      onBeforeChange={onSeekBeforeChange}
      onAfterChange={onSeekAfterChange}
      handleStyle={{ zIndex: 10 }}>
      <ProgressBar className="z-0" value={loaded * 100} />
    </Slider>

    <div className="w-18 font-sans space-x-2 text-xs">
      <Duration classes="text-white" seconds={duration * played} />
      <span>/</span>
      <Duration classes="text-white" seconds={duration} />
    </div>
  </div>
);

export default VideoPlaybackControls;
