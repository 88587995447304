import { Route, Switch, Redirect } from "react-router-dom";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import LessonMain from "./LessonMain";
import CourseDetailPage from "./courses/Detail";
import LevelPage from "./level";
import CoursesPage from "./courses";
import StudyVideo from "./StudyVideo";
import MultipleQuiz from "./quiz/MultipleQuiz";

const StudyIndex = () => {
  const { studying } = useContext(ApiContext);

  return (
    <Switch>
      <Route
        path="/study/:course_id/:level_id/:chapter_id/:lesson_id/:video_id"
        exact
        component={LessonMain}
      />
      <Route exact path="/study/courses/:id" component={CourseDetailPage} />
      <Route exact path="/study/levels/:id" component={LevelPage} />
      <Route exact path="/study/courses" component={CoursesPage} />
      <Route exact path="/study/video/:id" component={StudyVideo} />
      <Route exact path="/study/:type/:id/quiz" component={MultipleQuiz} />
      <Route
        path="/study"
        render={() => (
          <Redirect
            to={{
              pathname:
                studying &&
                studying.course_id &&
                studying.level_id &&
                studying.chapter_id &&
                studying.lesson_id &&
                studying.video_id
                  ? `/study/${studying.course_id}/${studying.level_id}/${studying.chapter_id}/${studying.lesson_id}/${studying.video_id}`
                  : "/"
            }}
          />
        )}
      />
    </Switch>
  );
};

export default StudyIndex;
