import gradstop from "gradstop";
import { useEffect, useState } from "react";

function ProgressRing({ radius = 60, stroke = 6, progress = 0, showPercentage = false }) {
  const [realProgress, setRealProgress] = useState(0);

  const colors = gradstop({
    stops: 101,
    inputFormat: "hex",
    colorArray: ["#F87171", "#84CC16"]
  });
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (realProgress / 100) * circumference;

  useEffect(() => {
    const i = setTimeout(() => {
      setRealProgress(progress);
    }, 200);
    return () => {
      clearInterval(i);
    };
  }, [progress]);

  return (
    <div>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="stroke-current text-gray-200"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + " " + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          strokeLinecap="round"
          fill="transparent"
          strokeWidth={stroke}
          stroke={colors[parseInt(progress)]}
          strokeDasharray={circumference + " " + circumference}
          style={{
            transition: `stroke-dashoffset 0.75s`,
            transform: `rotate(-90deg)`,
            transformOrigin: `50% 50%`,
            strokeDashoffset
          }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />

        {showPercentage ? (
          <text
            className="fill-current"
            offset=""
            x="50%"
            y="51%"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize={`${radius / 50}em`}>
            {progress.toFixed(0)}%
          </text>
        ) : null}
      </svg>
    </div>
  );
}

export default ProgressRing;
