import React from "react";
import PropTypes from "prop-types";
import { GiSpeaker } from "react-icons/gi";

class AudioPlayer extends React.Component {
  state = {
    isPlaying: false,
    ready: false,
    currentTime: 0,
    duration: 0,
    playbackRate: 1,
    showRateSelector: false
  };

  componentDidMount() {
    this.player.oncanplay = () => {
      if (!this.player) return;

      this.setState({ ready: true, duration: this.player.duration });
    };

    this.player.ontimeupdate = () => {
      if (!this.player) return;

      this.setState({ currentTime: this.player.currentTime });
    };

    this.player.onended = () => {
      this.setState({ isPlaying: false, duration: 0 });

      if (!this.player) return;

      this.player.currentTime = 0;
    };
  }

  componentWillUnmount() {
    this.player.pause();
  }

  playPauseAudio = () => {
    if (this.player.paused) {
      this.player.play();
      this.setState({ isPlaying: true });
    } else {
      this.player.pause();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    const { src } = this.props;

    return (
      <div>
        <audio
          className={"hidden"}
          ref={player => {
            this.player = player;
          }}>
          <source src={src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <div onClick={this.playPauseAudio} className="audio-player cursor-pointer">
          <GiSpeaker className="hover:text-blue-500" size={27} />
        </div>
      </div>
    );
  }
}

AudioPlayer.defaultProps = {
  mini: false,
  colour: "pink"
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  colour: PropTypes.string,
  mini: PropTypes.bool.isRequired
};

export default AudioPlayer;
