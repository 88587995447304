import { createContext } from "react";
import { useGet } from "Services";

export const ClassroomsContext = createContext({});

const ClassroomsProvider = ({ children }) => {
  const { loading, error, data, refresh } = useGet("/dashboard/sidebar");

  return (
    <ClassroomsContext.Provider
      value={{
        loading,
        error,
        data,
        refresh
      }}>
      {children}
    </ClassroomsContext.Provider>
  );
};

export default ClassroomsProvider;
