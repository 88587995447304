import { ApiContext } from "contexts/ApiContext";
import { useModals } from "contexts/ModalProvider";
import { NavigationBar } from "newComponents/base";
import Page from "newComponents/Page";
import { SimpleVideoById } from "newComponents/SimpleVideo";
import { useContext } from "react";
import { IoIosVideocam } from "react-icons/io";
import { toABC } from "utils/string";

const LessonSummaries = () => {
  const { db } = useContext(ApiContext);
  const modal = useModals();

  function onClickVideo(video) {
    modal.present({
      maxWidth: "max-w-7xl",
      children: <SimpleVideoById id={video.id} />
    });
  }

  const courses = db.courses
    .map(course => {
      const levels = db
        .getCourseLevels(course.id)
        .map(level => {
          const chapters = db
            .getLevelChapters(level.id)
            .map(chapter => {
              const lessons = db
                .getChapterLessons(chapter.id)
                .map(lesson => {
                  const summaryVideos = db
                    .getLessonVideos(lesson.id)
                    .filter(i => i.name.toLowerCase().includes("lesson summary"));
                  return { ...lesson, summaryVideos };
                })
                .filter(i => i.summaryVideos.length > 0);
              return { ...chapter, lessons };
            })
            .filter(i => i.lessons.length > 0);
          return { ...level, chapters };
        })
        .filter(i => i.chapters.length > 0);
      return { ...course, levels };
    })
    .filter(i => i.levels.length > 0);

  return (
    <Page title="Lesson Summaries">
      <NavigationBar title="Lesson Summaries" />
      <div className="space-y-16">
        {courses.map(course => (
          <div key={course.id}>
            <div className="sticky top-0 md:top-14 xl:top-16 backdrop-filter backdrop-blur bg-gray-100 bg-opacity-80 dark:bg-gray-900 dark:bg-opacity-80 z-10 py-4 shadow-sm">
              <h3>{course.name}</h3>
            </div>

            <div className="mt-4 space-y-8">
              {course.levels.map(level => (
                <div key={level.id}>
                  <div className="opacity-60">{level.name}</div>
                  <div className="mt-3 grid md:grid-cols-2 xl:grid-cols-3 gap-8">
                    {level.chapters.map(chapter => (
                      <div
                        key={chapter.id}
                        className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-sm">
                        <div>{chapter.name}</div>
                        <div className="mt-2">
                          {chapter.lessons.map(lesson => (
                            <LessonSummary
                              lesson={lesson}
                              key={lesson.id}
                              onClickVideo={onClickVideo}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
};

const LessonSummary = ({ lesson, onClickVideo }) => {
  return (
    <div>
      {lesson.summaryVideos.map(video => (
        <div key={video.id} className="border-b dark:border-gray-800 py-1">
          <button
            className="text-left text-xs align-middle space-x-2 text-blue-500 hover:text-blue-600 active:text-blue-700 cursor-pointer"
            bold={false}
            onClick={() => onClickVideo(video)}>
            <span className="inline-block align-middle">
              Lesson {toABC(lesson.ordering)} : {lesson.name}
            </span>
            <span className="inline-block align-middle">
              <IoIosVideocam size={18} />
            </span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default LessonSummaries;
