import { useParams, useHistory } from "react-router-dom";
import { AiOutlineStar, AiFillStar, AiFillHome } from "react-icons/ai";
import { FaCertificate, FaChevronRight } from "react-icons/fa";
import { Button } from "newComponents/base";
import { useGet } from "Services";
import SimpleVideoPlayer from "newComponents/SimpleVideoPlayer";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import ProgressRing from "newComponents/ProgressRing";
import { BsPencil } from "react-icons/bs";
import { IoIosCheckmark } from "react-icons/io";
import Page from "newComponents/Page";

const CourseDetailPage = () => {
  const { id } = useParams();
  const { db } = useContext(ApiContext);
  const { data } = useGet(`/courses/${id}`);

  const history = useHistory();

  const course = db.courses.find(i => i.id === id);
  const levels = db.getCourseLevels(id);

  if (!course) {
    history.push("/");
    return null;
  }

  if (levels.length === 1) {
    history.push(`/study/levels/${levels[0].id}`);
    return null;
  }

  return (
    <Page>
      <CourselNavigation course={course} />

      <div className="flex mt-4 max-w-5xl">
        <div className="flex-1">
          {course.intro_video ? <CourseIntroVideoLoader courseId={course.id} /> : null}

          <div className="mt-2 sm:mt-4 text-gray-700 dark:text-gray-400">{course.description}</div>

          <div className="mt-4 text-gray-600 dark:text-gray-400 md:flex md:space-x-8">
            <div className="flex items-baseline">
              <label className="pr-2">Words: </label>
              <div className="">{course.video_count}</div>
            </div>
            {course.grammar_count ? (
              <div className="flex items-baseline">
                <label className="pr-2">Grammar Points: </label>
                <div className="">{course.grammar_count}</div>
              </div>
            ) : null}
            <div className="flex items-baseline">
              <label className="pr-2">Videos: </label>
              <div className="">{course.video_count}</div>
            </div>
            <div className="flex items-baseline">
              <label className="pr-2">Duration: </label>
              <div className="">{(course.duration / 3600).toFixed(1)} hours</div>
            </div>
          </div>

          <div className="my-8 space-y-8">
            {levels.map(level => (
              <LevelItem
                hasQuiz={course.has_quiz}
                key={level.id}
                level={level}
                progress={
                  data && data.levels.find(i => i.id === level.id)
                    ? data.levels.find(i => i.id === level.id).progress * 100
                    : 0
                }
                certificateId={
                  data && data.levels.find(i => i.id === level.id)
                    ? data.levels.find(i => i.id === level.id).certificate_id
                    : null
                }
              />
            ))}
          </div>
        </div>

        <div className="flex-1 ml-8 break-words break-all hidden">
          <div className="p-6 font-mono bg-white dark:bg-gray-800 rounded-xl text-9xl">
            Some thing fun here.
          </div>
        </div>
      </div>
    </Page>
  );
};

const CourseIntroVideoLoader = ({ courseId }) => {
  const { data } = useGet("/courses");
  const { db } = useContext(ApiContext);

  const course = db.getCourse(courseId);

  const src = data && course.intro_video ? course.intro_video.preferred_src : null;

  const thumb = course && course.intro_video ? course.intro_video.thumb : null;

  return (
    <div className="relative bg-gray-300 rounded-xl overflow-hidden">
      <div className="w-full" style={{ paddingBottom: "56.25%" }}></div>
      <div className="absolute inset-0 z-10">
        <SimpleVideoPlayer src={src} thumb={thumb} />
      </div>
    </div>
  );
};

const CourselNavigation = ({ course }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Button className="flex items-center space-x-2" to="/courses">
            <AiFillHome size={21} />
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <div
            disabled
            className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
            {course.name}
          </div>
        </div>
      </div>

      <h1>{course.name}</h1>
    </div>
  );
};

const LevelItem = ({ level, progress = 0, certificateId, hasQuiz = false }) => {
  const { completedItems, studying } = useContext(ApiContext);

  return (
    <div className="bg-white dark:bg-gray-800 px-4 sm:px-6 m:py-3 shadow-sm hover:shadow-xl rounded-2xl transform bounce scale-100 hover:scale-101">
      <div className="py-4 lg:py-8 flex-1">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 align-middle text-yellow-400 text-3xl">
            {completedItems && completedItems.levelIds.includes(level.id) ? (
              <AiFillStar />
            ) : (
              <AiOutlineStar />
            )}
          </div>
          <Button className="text-2xl sm:text-3xl" to={`/study/levels/${level.id}`}>
            <div>
              {level.name}{" "}
              {studying.level_id === level.id ? (
                <span className="text-green-500 dark:text-green-600 ml-3 font-normal text-xl">
                  👨🏻‍💻 Studying
                </span>
              ) : null}
            </div>
          </Button>
        </div>

        <div className="text-gray-500 dark:text-gray-400 mt-4">
          <div className="float-right transform md:ml-10">
            {completedItems && completedItems.levelIds.includes(level.id) ? (
              <div className="text-lime-500">
                <div className="relative">
                  <ProgressRing progress={100} stroke={8} radius={50} />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <IoIosCheckmark className="flex-shrink-0 text-8xl " />
                  </div>
                </div>
                <div className="text-center font-semibold hidden sm:block">
                  <div>LEVEL</div>
                  <div>COMPLETED</div>
                </div>
              </div>
            ) : (
              <ProgressRing progress={progress} stroke={8} radius={50} showPercentage={true} />
            )}
          </div>

          <div className="mt-2">{level.description}</div>
          {level.extra_description ? <div className="mt-2">{level.extra_description}</div> : null}
        </div>

        <div className="mt-4 lg:flex lg:space-x-6">
          <div className="flex items-baseline">
            <label className="pr-2">Words: </label>
            <div className="">{level.word_count}</div>
          </div>
          <div className="flex items-baseline">
            <label className="pr-2">Grammar Points: </label>
            <div className="">{level.grammar_count}</div>
          </div>
          <div className="flex items-baseline">
            <label className="pr-2">Videos: </label>
            <div className="">{level.video_count}</div>
          </div>
          <div className="flex items-baseline">
            <label className="pr-2">Duration: </label>
            <div className="">{(level.duration / 3600).toFixed(1)} hours</div>
          </div>
        </div>

        <div className="mt-8 lg:flex space-y-2 lg:space-y-0 clear-both">
          <Button next to={`/study/levels/${level.id}`}>
            Choose
          </Button>
          {hasQuiz ? (
            <Button
              className="lg:ml-8"
              rightIcon={<BsPencil size={17} />}
              to={`/study/level/${level.id}/quiz`}>
              Take {level.name} Quiz
            </Button>
          ) : null}
          {certificateId ? (
            <Button
              className="lg:ml-12"
              rightIcon={<FaCertificate />}
              to={`/certificate/${certificateId}`}>
              My Certificate
            </Button>
          ) : null}
        </div>

        {level.notes ? (
          <div className="flex items-center space-x-3 mt-4 text-orange-400">
            <h3>{level.notes}</h3>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CourseDetailPage;
