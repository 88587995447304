import { useState, useEffect, useContext } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useGet, usePost } from "Services";
import VideoPlayer from "newComponents/video/VideoPlayer";
import { Button, PrimaryLink } from "newComponents/base";
import Spinner from "newComponents/Spinner";
import { ApiContext } from "contexts/ApiContext";
import Errors from "newComponents/Errors";

const ChapterVideo = ({ show, id }) => {
  const { completedItems, setApiState } = useContext(ApiContext);
  const [fetchVideo, { loading, error, data }] = useGet(`frontend/videos/${id}`, true);
  const [hasMarkedCompleted, setHasMarkedCompleted] = useState(false);
  const [completeVideo, completeVideoRes] = usePost(`/videos/${id}/complete`, {
    onComplete: () => {
      setHasMarkedCompleted(true);
      setApiState("completedItems", {
        ...completedItems,
        videoIds: [...completedItems.videoIds, id],
      });
    },
  });

  useEffect(() => {
    if (id) {
      fetchVideo();
      setHasMarkedCompleted(false);
    }
  }, [id]);

  if (loading) return <Spinner className="p-48" center />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  return (
    <div className="">
      <div className="flex">
        <div className="flex-grow">
          <h1 className="py-4 px-2">{data.name}</h1>
          <div className="sm:mt-8">
            <VideoPlayer video={data} onEnded={completeVideo} forcePlaying={show} alwaysWide={true} />
          </div>

          <div className="flex mt-8 justify-between">
            {data.is_complete || hasMarkedCompleted ? (
              <Button className="flex items-center space-x-2" disabled leftIcon={<AiFillStar size={17} className="text-yellow-500" />}>
                Completed
              </Button>
            ) : (
              <Button
                className="flex items-center space-x-2"
                onClick={() => completeVideo()}
                disabled={completeVideoRes.loading}
                loading={completeVideoRes.loading}
                leftIcon={<AiOutlineStar size={17} />}
              >
                Mark Complete
              </Button>
            )}
          </div>

          <PromoSection id={id} />
        </div>
      </div>
    </div>
  );
};

const PromoSection = ({ id }) => {
  const { user } = useContext(ApiContext);

  if (user.subscription)
    return (
      <div className="flex space-x-8 justify-end py-8">
        <PrimaryLink to="/">Go to Dashboard</PrimaryLink>
      </div>
    );

  if (id !== "2ddaaf59-1bc8-41ba-8275-f10e9400b40c") return null;

  return (
    <div className="space-y-4 mt-8">
      <h1>Faaaaantastic! 🎉🎉🎉</h1>
      <p>
        You have completed the trial part of Domino Open. We hope you have had a good time and that you&apos;ve got a better understanding of how Domino works.
        Below you will find a discount code that works both for Domino Classroom and Domino Open.
      </p>
      <p>Wether you want to study on your own or choose to join a class with teachers we hope you have a great journey with us!</p>
      <p>
        P.S - You can always talk with us all on discord:{" "}
        <a href="https://discord.com/invite/wP9YnfSrVY" rel="noreferrer" target="_blank">
          https://discord.com/invite/wP9YnfSrVY
        </a>
      </p>
      <p>
        Oh! And dont forget that you can setup counceling session with me over here:
        <a href="https://app.greminders.com/c/felixlattman" rel="noreferrer" target="_blank">
          https://app.greminders.com/c/felixlattman
        </a>
      </p>

      <p className="text-2xl pt-4 text-green-500">
        Your Discount Code for Domino Open or Domino Classroom: <b>KINGOFCHAPTER1</b>
      </p>

      <div className="flex space-x-8 justify-between py-8">
        <Button to="/">Go to Dashboard</Button>
        <a href={process.env.REACT_APP_SUBSCRIBE_PAGE}>Subscribe</a>
      </div>
    </div>
  );
};

export default ChapterVideo;
