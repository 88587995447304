const MOODLE_COURSES = [
  { id: 17, number: 1 },
  { id: 91, number: 2 },
  { id: 93, number: 3 },
  { id: 94, number: 4 },
  { id: 95, number: 5 },
  { id: 98, number: 6 },
  { id: 99, number: 7 },
  { id: 100, number: 8 }
];

const MoodleCourseSelector = () => (
  <div className="grid md:grid-cols-2 gap-10 text-base px-10 pb-10">
    {MOODLE_COURSES.map(i => (
      <div
        className="flex items-center justify-center font-semibold text-xl scale-100 hover:scale-102 bounce rounded-2xl overflow-auto relative"
        key={i.id}>
        <a
          className=""
          href={`https://practice.dominochinese.com/course/view.php?id=${i.id}`}
          rel="noreferer"
          target="_blank">
          <img
            src={`https://materials.dominochinese.com/static/moodle_course_${i.number}.jpg`}
            alt={i.name}
          />{" "}
          <div className="absolute inset-0 flex items-center justify-center text-2xl drop-shadow text-white"></div>
        </a>
      </div>
    ))}
  </div>
);

export default MoodleCourseSelector;
