import couponFelix from "assets/images/coupon/felix.png";
import couponAna from "assets/images/coupon/ana.png";
import couponDiscount from "assets/images/coupon/discount.png";
import { PrimaryLink } from "./base";

const CouponModalContent = ({ coupon }) => {
  return (
    <div className="relative">
      <div className="flex justify-center -translate-x-16 translate-y-16">
        <img className="w-1/2" src={couponDiscount} alt="discount" />
      </div>
      <div className="flex justify-between items-end mt-8">
        <img className="w-1/2" src={couponAna} alt="Aan" />
        <img className="w-1/2" src={couponFelix} alt="Aan" />
      </div>

      <div className="absolute inset-0 flex justify-center items-center">
        <div className="flex flex-col items-center justify-center space-y-4 translate-y-12 w-1/3">
          <div className="text-3xl leading-relaxed text-center font-bold">{coupon.description}</div>
          <div>
            <a href={process.env.REACT_APP_SUBSCRIBE_PAGE}>Check out my deal!</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponModalContent;
