import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { ApiContext } from "contexts/ApiContext";
import ProgressRing from "newComponents/ProgressRing";
import { IoIosCheckmark } from "react-icons/io";
import { BsChevronRight } from "react-icons/bs";
import Page from "newComponents/Page";
import { ClassroomsContext } from "contexts/ClassroomProvider";
import tree1 from "assets/images/dominoTree/1.svg";
import tree2 from "assets/images/dominoTree/2.svg";
import tree3 from "assets/images/dominoTree/3.svg";
import tree4 from "assets/images/dominoTree/4.svg";
import tree5 from "assets/images/dominoTree/5.svg";
import tree6 from "assets/images/dominoTree/6.svg";
import tree7 from "assets/images/dominoTree/7.svg";

const trees = [tree1, tree2, tree3, tree4, tree5, tree6, tree7];

const CoursesPage = () => {
  const { db } = useContext(ApiContext);

  const coreCourses = db.courses.filter((i) => i.tag === "CORE");
  const premiumCourses = db.courses.filter((i) => i.tag === "PREMIUM");
  const freeCourses = db.courses.filter((i) => i.tag === "FREE");
  const oldCourses = db.courses.filter((i) => i.tag === "OLD");

  return (
    <Page className="space-y-6 lg:space-y-12">
      <div className="grid gap-12 place-items-start">
        <div className="xl:col-span-2 space-y-16">
          <div>
            <h1 className="text-center md:text-left py-8">Domino Core Courses</h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12">
              {coreCourses.map((course, index) => (
                <CourseItem key={course.id} course={course} number={index + 1} hasTree />
              ))}
            </div>
          </div>

          <div>
            <h1 className="text-center md:text-left py-8">Free Courses</h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12">
              {freeCourses.map((course) => (
                <div key={course.id}>
                  <CourseItem course={course} />
                </div>
              ))}
            </div>
          </div>

          <div>
            <h1 className="text-center md:text-left py-8">Premium Courses</h1>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12">
              {premiumCourses.map((course) => (
                <div key={course.id}>
                  <CourseItem course={course} />
                </div>
              ))}
            </div>
          </div>

          <div>
            <h1 className="text-center md:text-left pt-8">Old Courses</h1>
            <div className="mt-2">
              Available until 2022-12-31,{" "}
              <a href="https://www.dominochinese.com/faq/" target="_blank" rel="noreferer">
                read more about the update on FAQ
              </a>
              .
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-12 mt-8">
              {oldCourses.map((course) => (
                <div key={course.id}>
                  <CourseItem course={course} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const CourseItem = ({ course, number, hasTree }) => {
  const { completedItems, db, studying } = useContext(ApiContext);
  const history = useHistory();

  const courseLevels = db.levels.filter((i) => i.course_id === course.id);
  const hasCompletedCourse = completedItems ? courseLevels.every((i) => completedItems.levelIds.includes(i.id)) : false;

  const completedLevels = completedItems ? courseLevels.filter((i) => completedItems.levelIds.includes(i.id)) : [];
  let progress = parseFloat(completedLevels.length * 100) / parseFloat(courseLevels.length);

  const isCurrentCourse = course.id === studying.course_id;

  return (
    <div
      className="bg-white dark:bg-gray-800 cursor-pointer rounded-2xl lg:rounded-3xl overflow-hidden shadow transform bounce scale-100 hover:scale-101 flex w-full course-item"
      onClick={() => history.push(`/study/courses/${course.id}`)}
    >
      <div className={`w-full ${hasTree ? "pb-8" : ""}`}>
        <div
          className="bg-no-repeat bg-cover aspect-w-16 aspect-h-9"
          style={{
            backgroundImage: course.intro_video
              ? `url(${course.intro_video.thumb})`
              : "url(https://materials.dominochinese.com/feedback/29509/conversions/61702f764a7ec5a726fb6cfd2460144a5dd5ec58adfd1-large.jpg)",
          }}
        ></div>

        <div className="p-6 2xl:p-8">
          <div className="float-right pl-4">
            {hasCompletedCourse ? (
              <div className="text-lime-500">
                <div className="relative">
                  <div className="hidden lg:block">
                    <ProgressRing progress={100} stroke={8} radius={48} />
                  </div>
                  <div className="block lg:hidden">
                    <ProgressRing progress={100} stroke={4} radius={30} />
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <IoIosCheckmark className="flex-shrink-0 text-6xl lg:text-7xl" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="transform translate-x-2 -translate-y-2">
                <ProgressRing progress={progress} radius={50} stroke={8} showPercentage={true} />
              </div>
            )}
          </div>

          {number ? <div className="italic text-3xl">#{number}</div> : null}
          <div className="flex items-baseline space-x-2">
            <h4 className="pb-4">{course.name}</h4>
          </div>

          <div className="text-gray-700 dark:text-gray-400 text-sm">{course.description}</div>

          {isCurrentCourse ? (
            <div
              className="block my-4 text-blue-500 dark:text-blue-400 cursor-pointer transform-gpu bounce scale-100 hover:scale-101"
              onClick={(e) => {
                e.stopPropagation();
                window.open("https://classroom.dominochinese.com/");
              }}
            >
              <b>Guaranteed Success! </b>
              <span>Study with a teacher, join a Classroom at your level.</span>
              <span className="inline-block align-middle">
                <BsChevronRight />
              </span>
            </div>
          ) : null}

          <div className="mt-4 text-gray-600 dark:text-gray-400">
            <div className="flex items-baseline">
              <label className="pr-2">Words: </label>
              <div className="">{course.word_count}</div>
            </div>
            {course.grammar_count ? (
              <div className="flex items-baseline">
                <label className="pr-2">Grammar Points: </label>
                <div className="">{course.grammar_count}</div>
              </div>
            ) : null}
            <div className="flex items-baseline">
              <label className="pr-2">Videos: </label>
              <div className="">{course.video_count}</div>
            </div>
            <div className="flex items-baseline">
              <label className="pr-2">Duration: </label>
              <div className="">{(course.duration / 3600).toFixed(1)} hours</div>
            </div>
          </div>
        </div>

        {hasTree && number <= trees.length ? (
          <div className="absolute right-10 bottom-0">
            <img className="h-28" src={trees[number - 1]} alt={number} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CoursesPage;
