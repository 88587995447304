import Spinner from "newComponents/Spinner";

export const BootingScreen = ({ bootMessage }) => (
  <div className="h-screen w-full flex flex-col items-center justify-center">
    <div className="flex flex-col justify-center items-center space-y-8">
      <img
        src={`${process.env.REACT_APP_MAIN_LOGO}`}
        alt={`${process.env.REACT_APP_SITE_NAME} Logo`}
      />

      <Spinner />

      <div className="text-center px-4">{bootMessage}</div>
    </div>
  </div>
);
