import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { Button } from "./base";
import { IoIosPlay } from "react-icons/io";
import { http } from "Services";
import { MdFiberManualRecord } from "react-icons/md";
import { FaStop } from "react-icons/fa";

const AudioRecorder = ({ card }) => {
  const [audio, setAudio] = useState(null);
  const [blob, setBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const player = useRef();

  const [mediaRecorder, setMediaRecorder] = useState(null);

  useEffect(() => {
    setBlob(null);
    setAudio(null);
    setIsRecording(false);
    setIsUploading(false);
  }, [card.id]);

  useEffect(() => {
    if (!mediaRecorder) return;
    if (isRecording) {
      if (mediaRecorder.state !== "recording") mediaRecorder.start(500);
    } else {
      if (mediaRecorder.state !== "inactive") mediaRecorder.stop();
    }
  }, [isRecording]);

  const handleUpload = () => {
    setIsUploading(true);
    const { id } = card.word;
    const type = card.word || "WORD";
    const formData = new FormData();
    formData.append("audio", blob, `${type}_${id}`);
    formData.append("id", id);
    formData.append("type", type);

    http
      .post("audio", formData)
      .then(() => {
        toast.success("Audio uploaded");
        setIsUploading(false);
        setBlob(null);
      })
      .catch(error => {
        setIsUploading(false);
        toast.error(error.message);
      });
  };

  function recordHandler() {
    if (!mediaRecorder) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          // const options = { mimeType: "audio/webm" };
          let recordedChunks = [];
          const mediaRecorder = new MediaRecorder(stream);

          mediaRecorder.addEventListener("dataavailable", e => {
            if (e.data.size > 0) recordedChunks.push(e.data);
          });

          mediaRecorder.addEventListener("stop", () => {
            const blob = new Blob(recordedChunks, {
              type: mediaRecorder.mimeType === "audio/mp4" ? mediaRecorder.mimeType : "audio/ogg"
            });
            const url = URL.createObjectURL(blob);
            setBlob(blob);
            setAudio(url);
            recordedChunks = [];
          });
          setMediaRecorder(mediaRecorder);
          setIsRecording(true);
        })
        .catch(() => {
          alert("You must allow the browser to access your microphone to record audio.");
        });
    } else {
      setIsRecording(prev => !prev);
    }
  }

  return (
    <div className="flex space-x-6 items-center  text-sm md:text-lg">
      {isRecording ? <div>Recording...</div> : null}

      <Button onClick={recordHandler} leftIcon={isRecording ? <FaStop /> : <MdFiberManualRecord />}>
        {isRecording ? "Stop" : audio ? "Re-record" : "Record Audio for your Classroom Teacher"}
      </Button>

      {audio && !isRecording ? (
        <Button rightIcon={<IoIosPlay size={21} />} onClick={() => player.current.play()}>
          Play
        </Button>
      ) : null}

      <audio className="hidden" ref={player} src={audio} controls />

      {blob ? (
        <div>
          <Button loading={isUploading} disabled={isUploading} onClick={handleUpload}>
            Upload
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default AudioRecorder;
