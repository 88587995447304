import { ApiContext } from "contexts/ApiContext";
import { Button } from "newComponents/base";
import Page from "newComponents/Page";
import { useContext } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useParams } from "react-router";
import { toABC } from "utils/string";

const SmallerDecks = ({}) => {
  const { db } = useContext(ApiContext);
  const { id } = useParams();

  const level = db.levels.find(i => i.id === id);

  const chapters = db.getLevelChapters(id);

  return (
    <Page>
      <div className="flex items-center justify-between whitespace-nowrap overflow-auto">
        <div className="flex items-center">
          <Button className="flex items-center space-x-2" to="/flashcards">
            Flashcards
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <Button className="flex items-center space-x-2" to="/flashcards/decks">
            Decks
          </Button>
          <span className="px-3 text-gray-500">
            <FaChevronRight />
          </span>
          <div
            disabled
            className="flex items-center py-6 font-bold text-gray-600 dark:text-gray-400">
            {level.name}
          </div>
        </div>
      </div>

      <h1>{level.name} Decks</h1>

      <div className="space-y-16 mt-8">
        {chapters.map(chapter => (
          <div key={chapter.id}>
            <h4>{chapter.name}</h4>
            <ChapterLessons chapter={chapter} />
          </div>
        ))}
      </div>
    </Page>
  );
};

const ChapterLessons = ({ chapter }) => {
  const { db } = useContext(ApiContext);

  const lessons = db.getChapterLessons(chapter.id);

  console.log("lessons", lessons);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-4">
      {lessons.map(lesson => (
        <div
          key={lesson.id}
          className="p-8 rounded-2xl bg-white dark:bg-gray-800 relative shadow transform-gpu bounce scale-100 hover:scale-105">
          <div className="absolute text-9xl opacity-5 right-0 bottom-0 dark:text-gray-500 font-serif italic z-0">
            {lesson.count}
          </div>
          <div className="text-2xl font-semibold opacity-80">Lesson {toABC(lesson.ordering)}</div>
          <div className="opacity-70 mt-2">{lesson.name}</div>
          <div className="flex space-x-4 mt-4 relative z-10">
            <Button next>Start</Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SmallerDecks;
