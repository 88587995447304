import React, { useState, useEffect, useContext } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoIosMenu, IoIosClose, IoIosSend } from "react-icons/io";
import { BsFillBellFill } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { ApiContext } from "contexts/ApiContext";
import { Button, NavigationLink } from "./base";
import { SITE_LINKS } from "newComponents/Footer";
import dcLogo from "assets/images/dc-logo.svg";
import dcLogoWhite from "assets/images/dc-logo-white.svg";
import FeedbackForm from "pages/feedback";
import { useModals } from "contexts/ModalProvider";
import { TiStarburst } from "react-icons/ti";
import MoodleCourseSelector from "./MoodleCourses";

const Header = () => {
  const moodleCourseModal = useModals();

  function showMoodleCourses() {
    moodleCourseModal.present({
      title: "Practice",
      subtitle: "Choose your current course and explore our many different exercises.",
      maxWidth: "max-w-4xl 2xl:max-w-6xl",
      noPadding: true,
      children: <MoodleCourseSelector />,
    });
  }

  return (
    <>
      <SmallNav showMoodleCourses={showMoodleCourses} />
      <LargeNav showMoodleCourses={showMoodleCourses} />
    </>
  );
};

const menus = [
  {
    title: "Dashboard",
    link: "/",
    bold: true,
    exact: true,
  },
  {
    title: "Courses",
    link: "/courses",
    exact: true,
  },
  {
    title: "Study",
    link: "/study",
  },
  {
    title: "Review",
    link: "/review",
  },
  {
    title: "Practice",
    link: process.env.REACT_APP_MOODLE_URL,
  },
  {
    title: "Vocab Lists",
    link: "/word-lists",
  },
  {
    title: "Dictionary",
    link: "/dictionary",
  },
  {
    title: "Explore",
    links: SITE_LINKS,
  },
];

const HeaderDropdownItem = ({ index, title, links }) => {
  const { showHeaderMore, setShowHeaderMore } = useContext(ApiContext);
  return (
    <div
      className={`text-gray-700 dark:text-gray-400
        header-with-links relative
        ${showHeaderMore === index ? "show-links" : ""}
      `}
    >
      <div
        className="cursor-pointer flex items-center space-x-2 whitespace-nowrap hover:text-sky-500 dark:hover:text-sky-500 h-full"
        onClick={() => setShowHeaderMore((prev) => (prev ? null : index))}
      >
        <span>{title}</span>
        <div
          className={`transform-gpu transition-all duration-300
          ${showHeaderMore === index ? "rotate-90" : "rotate-0"}
          `}
        >
          <FiChevronRight size={18} />
        </div>
      </div>

      <div
        className={`inset-0 fixed h-screen ${showHeaderMore === index ? "block" : "hidden"}`}
        style={{ zIndex: -1 }}
        onClick={() => setShowHeaderMore(null)}
      ></div>

      <div className="links">
        <div className="container mx-auto px-6 max-w-5xl">
          <div className="flex flex-wrap items-center justify-center">
            {links.map((i, index) => (
              <NavigationLink key={index} className={`mx-4 mb-6 underline ${i.className ? i.className : ""}`} to={i.link} exact={i.exact} title={i.title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserMenu = () => {
  const { user } = useContext(ApiContext);

  if (user)
    return (
      <div className="w-10 text-center flex justify-center items-center cursor-pointer mr-2">
        <Button to="/me">
          {user.avatar == "https://materials.dominochinese.com/misc/anonymous-user.png" ? (
            <div className="flex justify-center">
              <BiUserCircle size={27} />
            </div>
          ) : (
            <div
              className="w-7 h-7 rounded-full overflow-hidden bg-cover bg-center bg-no-repeat border border-gray-200 hover:border-sky-500"
              style={{
                backgroundImage: `url(${user.avatar})`,
                paddingBottom: "100%",
              }}
            ></div>
          )}
        </Button>
      </div>
    );

  return null;
};

const SmallNavigationLink = ({ item, onclick }) => {
  if (item.links)
    return item.links.map((i, index) => (
      <NavigationLink key={index} className="block py-4 px-6 border-b dark:border-gray-700" to={i.link} title={i.title} onClick={onclick} />
    ));
  return <NavigationLink className="block p-4 px-6 border-b dark:border-gray-700" to={item.link} title={item.title} onClick={onclick} />;
};

const SmallNav = () => {
  const height = 60;
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [showMenu]);

  return (
    <div className="flex md:hidden flex-col dark:bg-gray-900 dark:text-gray-300 dark:border-gray-800 border-b">
      <div className="flex justify-center items-center z-20 relative" style={{ height }}>
        <div className="w-16 h-full flex items-center justify-center" onClick={() => setShowMenu((prev) => !prev)}>
          {showMenu ? <IoIosClose size="41" /> : <IoIosMenu size="27" />}
        </div>

        <div className="flex-grow flex justify-center">
          <Link to="/" className="block h-full" onClick={() => setShowMenu(false)}>
            <img className="dark:hidden" width={175} src={dcLogo} alt={`${process.env.REACT_APP_SITE_NAME} Logo`} />
            <img className="hidden dark:block" width={175} src={dcLogoWhite} alt={`${process.env.REACT_APP_SITE_NAME} Logo`} />
          </Link>
        </div>

        <div className="xl:flex items-center space-x-0 pr-2" onClick={() => setShowMenu(false)}>
          <UserMenu />
        </div>
      </div>

      <div
        className="z-50 fixed w-full animate-ease-5 bg-gray-50 dark:bg-gray-900 overflow-y-scroll"
        style={{
          height: showMenu ? `calc(100% - ${height}px)` : 0,
          top: height,
          transition: "height 0.5s cubic-bezier(0, 0.5, 0.3, 1) 0.05s",
        }}
      >
        {menus.map((item, index) => (
          <SmallNavigationLink key={index} item={item} onclick={() => setShowMenu(false)} />
        ))}
      </div>
    </div>
  );
};

const LargeNav = ({ showMoodleCourses }) => {
  const { setApiState, notifications, user } = useContext(ApiContext);
  const { present } = useModals();
  const unreadNotificationsCount = notifications.filter((i) => i.read_at === null).length;

  return (
    <div
      className="hidden md:block border-b dark:bg-gray-800 dark:border-gray-800 dark:text-gray-300 bg-white sticky top-0 bg-opacity-75 dark:bg-opacity-90 z-30 text-sm 2xl:text-base h-14 2xl:h-16"
      style={{
        backdropFilter: `saturate(180%) blur(20px)`,
        WebkitBackdropFilter: `saturate(180%) blur(20px)`,
      }}
    >
      <div className="flex h-full justify-between items-stretch">
        <div className="flex items-stretch overflow-auto">
          <div className="sticky left-4 pl-4 xl:pl-2 flex-shrink-0">
            <Link to="/" className="flex items-center h-full">
              <img className="dark:hidden" width={175} src={dcLogo} alt={`${process.env.REACT_APP_SITE_NAME} Logo`} />
              <img className="hidden dark:block" width={175} src={dcLogoWhite} alt={`${process.env.REACT_APP_SITE_NAME} Logo`} />
            </Link>
          </div>
          <div className="flex overflow-auto items-center space-x-6 xl:space-x-8 ml-16 mr-4">
            {menus
              .filter((i) => !i.smallNavOnly)
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item.links ? (
                    <HeaderDropdownItem title={item.title} links={item.links} index={index} />
                  ) : item.title === "Practice" ? (
                    <div className="cursor-pointer hover:text-sky-600" onClick={showMoodleCourses}>
                      Practice
                    </div>
                  ) : (
                    <NavigationLink className={`block ${item.bold ? "font-bold" : ""}`} to={item.link} title={item.title} exact={item.exact} />
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>

        {user ? (
          <div className="flex items-center">
            <div
              id="FeedbackIcon"
              className="flex justify-center items-center cursor-pointer text-sky-500 hover:text-sky-600 active:text-sky-800 space-x-2 mr-4"
              onClick={() =>
                present({
                  title: "Feedback",
                  subtitle: "We are happy to hear from you.",
                  center: true,
                  keepChildren: true,
                  children: <FeedbackForm />,
                })
              }
            >
              <IoIosSend size={24} />
            </div>

            <div className="mr-4">
              <Streaks />
            </div>

            <UserMenu />

            <div className="w-10 mr-4 flex justify-center cursor-pointer hover:text-sky-500 relative" onClick={() => setApiState("showNotifications", true)}>
              <BsFillBellFill size={21} />
              {unreadNotificationsCount > 0 ? (
                <div className="absolute text-white rounded-full text-xs right-0 -top-2 bg-red-500 w-5 h-5 flex items-center justify-center">
                  {unreadNotificationsCount}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="flex items-center pr-6 space-x-6 font-bold backdrop-filter drop-shadow">
            <a className="cursor-pointer text-purple-500" href={process.env.REACT_APP_AUTH_URL} target="_blank" rel="noreferrer">
              Sign In
            </a>
            <a className="cursor-pointer" href={process.env.REACT_APP_AUTH_URL + "/register"} target="_blank" rel="noreferrer">
              Register
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const Streaks = () => {
  const { user, setShowStreak } = useContext(ApiContext);
  if (!user) return null;

  return (
    <div className="relative">
      <div className="cursor-pointer relative" onClick={() => setShowStreak((prev) => !prev)}>
        <TiStarburst className="text-amber-500 dark:text-amber-600" size={40} />
        <div
          className="absolute inset-0 text-gray-100 flex justify-center items-center text-xs font-bold"
          style={{
            fontSize: user.current_streak > 99 ? 10 : 12,
          }}
        >
          {user.current_streak}
        </div>
      </div>
    </div>
  );
};

export default Header;
