export function redirectToLogin() {
  window.location = `${process.env.REACT_APP_AUTH_URL}/?redirectUrl=${window.location.href}`;
}

export function redirectToVerifyEmail(email) {
  window.location.href = `${process.env.REACT_APP_AUTH_URL}/request-verification?email=${email}&redirectUrl=${window.location.href}`;
}

export function redirectToPay() {
  window.location.href = `${process.env.REACT_APP_SUBSCRIBE_PAGE}/summary`;
}
