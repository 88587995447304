export const subscriptionPage = "/subscribe";

export function hasLessonPermission(user, lesson) {
  if (lesson.access_type === "FREE") return true;

  return user.permissions.includes(lesson.access_type) || user.permissions.includes(`VIEW_${lesson.access_type}_CONTENT`);
}

export function hasPermission(user, permission) {
  return user.permissions.includes(permission);
}
