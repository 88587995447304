import { useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import TextareaAutosize from "react-autosize-textarea";
import Spinner from "./Spinner";
import { uuidv4 } from "utils/string";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export const RoundButton = ({ className = "", loading, children, ...rest }) => {
  return (
    <button
      type="button"
      className={`
        cursor-pointer rounded-full text-white
        disabled:cursor-not-allowed
        disabled:text-gray-300
        flex items-center space-x-2
        ${className}
        `}
      {...rest}
    >
      {loading ? <Spinner className="pr-4" color="white" size={18} /> : null}
      {children}
    </button>
  );
};

export const PrimaryLink = ({ children, next, back, ...rest }) => {
  return (
    <Link
      className="bg-sky-500 dark:bg-sky-600 dark:hover:bg-sky-700 dark:active:bg-sky-800 text-white hover:text-white rounded-full px-6 py-2 text-lg font-semibold flex items-center space-x-3 whitespace-nowrap"
      {...rest}
    >
      {next ? (
        <>
          <span>{children}</span>
          <FaChevronRight />
        </>
      ) : back ? (
        <>
          <FaChevronLeft />
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </Link>
  );
};

export const Button = ({
  className = "",
  disabled = false,
  loading = false,
  gray,
  red,
  green,
  customColor,
  bold = true,
  to,
  back,
  next,
  leftIcon = null,
  rightIcon = null,
  children,
  ...rest
}) => {
  if (to) {
    return (
      <Link
        className={`flex items-center cursor-pointer align-middle space-x-2
        ${bold ? "font-bold" : ""}
        ${
          gray
            ? "text-gray-600 hover:text-gray-700"
            : red
            ? "text-red-600 hover:text-red-700"
            : green
            ? "text-green-600 hover:text-green-700"
            : customColor
            ? ""
            : "text-sky-500 hover:text-sky-700 disabled:text-sky-300 dark:disabled:text-sky-800"
        }
        ${customColor ? customColor : "text-sky-500 hover:text-sky-700"}
        ${className}
        `}
        to={to}
        {...rest}
      >
        {next ? (
          <>
            <span>{children}</span>
            <FaChevronRight />
          </>
        ) : back ? (
          <>
            <FaChevronLeft />
            <span>{children}</span>
          </>
        ) : (
          <>
            {leftIcon}
            <div>{children}</div>
            {rightIcon}
          </>
        )}
      </Link>
    );
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className={`
        cursor-pointer animate-ease-1 outline-none focus:outline-none
        ${bold ? "font-bold" : ""}
        ${
          gray
            ? "text-gray-600 hover:text-gray-700"
            : red
            ? "text-red-600 hover:text-red-700"
            : green
            ? "text-green-600 hover:text-green-700"
            : customColor
            ? ""
            : "text-sky-500 hover:text-sky-700 disabled:text-sky-300 dark:disabled:text-sky-800"
        }
        disabled:cursor-not-allowed
        disabled:text-gray-500
        ${className} flex items-center
        `}
      {...rest}
    >
      {loading ? <Spinner className="pr-4" size={18} /> : null}
      <div className="flex-1 flex items-center justify-center space-x-2">
        {next ? (
          <>
            <span>{children}</span>
            <FaChevronRight />
          </>
        ) : back ? (
          <>
            <FaChevronLeft />
            <span>{children}</span>
          </>
        ) : (
          <>
            {leftIcon}
            {children ? <div>{children}</div> : null}
            {rightIcon}
          </>
        )}
      </div>
    </button>
  );
};

export const ButtonX = ({ className = "", ...rest }) => (
  <button
    className={`
      outline-none focus:outline-none text-4xl text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100
      ${className}
    `}
    {...rest}
  >
    &times;
  </button>
);

export const BackButton = ({ className = "", to, label = "Back", ...rest }) => (
  <Link id="back-button" className={`flex items-center space-x-3 font-bold text-sky-500 hover:text-sky-700 cursor-pointer ${className}`} to={to} {...rest}>
    <FaChevronLeft size={21} />
    <span>{label}</span>
  </Link>
);

export const NavigationLink = ({ className = "", title, to = "", ...rest }) => {
  const anotherSite = to.indexOf("http") > -1;
  if (anotherSite)
    return (
      <a className={`whitespace-nowrap ${className} text-gray-700 dark:text-gray-400`} href={to} target="_blank" rel="noreferrer">
        {title}
      </a>
    );
  return (
    <NavLink
      className={`whitespace-nowrap ${className} text-gray-700 dark:text-gray-400 dark:hover:text-sky-500`}
      activeClassName="text-sky-500 dark:text-sky-400 dark:hover:text-sky-500"
      to={to}
      {...rest}
    >
      {title}
    </NavLink>
  );
};

export const PermissionLink = ({ className = "", to, children, hasPermission, notAllowHandler, ...rest }) => {
  const history = useHistory();
  return (
    <button
      className={`truncate text-sky-500 hover:text-sky-600 active:text-sky-700 focus:outline-none outline-none ${className}`}
      onClick={() => (hasPermission ? history.push(to) : notAllowHandler())}
      {...rest}
    >
      {children}
    </button>
  );
};

export const Select = ({ className = "", children, ...rest }) => (
  <select
    className={`bg-transparent outline-none border rounded-xl px-4 py-1 dark:border-gray-600 bg-white dark:bg-gray-900 appearance-none
        ${className}
        `}
    {...rest}
  >
    {children}
  </select>
);

export const Input = ({ className = "", ...rest }) => (
  <input
    className={`
      border dark:bg-gray-900 rounded-xl p-2 px-4 outline-none focus:outline-none border-gray-200 dark:border-gray-700
      focus:border-sky-500
      disabled:bg-gray-100
      ${className}
    `}
    {...rest}
  />
);

export const Checkbox = ({ className = "", ...rest }) => <input type="checkbox" className={`outline-none focus:outline-none ${className}`} {...rest} />;

export const Text = ({ className = "", rows = 3, ...rest }) => (
  <TextareaAutosize
    rows={rows}
    className={`${className} w-full p-4 text-sm rounded-lg appearance-none border border-gray-200 focus:border-sky-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200`}
    {...rest}
  />
);

export const NavigationBar = ({ backTo, backLabel = "Back", title, subtitle }) => (
  <div className="py-6 space-y-4">
    {backTo ? <BackButton className="mr-4" to={backTo} label={backLabel} /> : null}
    {title ? (
      <div>
        <h1>{title}</h1>
        {subtitle ? <div className="mt-1">{subtitle}</div> : null}
      </div>
    ) : null}
  </div>
);

export const ProgressBar = ({ className = "", value }) => (
  <div className={`h-1 relative ${className}`}>
    <div className="absolute h-full rounded-full animate-ease-3 bg-sky-500" style={{ width: `${value}%` }}></div>
    <div className="h-full rounded-full w-full bg-gray-500"></div>
  </div>
);

export const FileSelector = ({ className = "", title = "add files", bold = false, bgColor = "", onChange, uploading, ...rest }) => {
  const [mouseIn, setMouseIn] = useState(false);
  const [uid] = useState(uuidv4());
  const borderStyle = "text-sky-600";
  const hoverBorderStyle = "text-sky-700";
  return (
    <div className={`${className} overflow-hidden relative`} onMouseEnter={() => setMouseIn(true)} onMouseLeave={() => setMouseIn(false)}>
      <label
        className={`
              cursor-pointer relative
              ${bold ? "font-bold" : "font-normal"}
              ${borderStyle}
              ${mouseIn ? hoverBorderStyle : ""}
              ${bgColor}
              `}
        htmlFor={uid}
      >
        {uploading ? "uploading..." : title}
      </label>
      <input id={uid} className="hidden" type="file" multiple required onChange={onChange} disabled={uploading} {...rest} />
    </div>
  );
};

export const Switcher = ({ className = "", isOn, onChange }) => {
  return (
    <div
      className={`
           flex items-center rounded-full cursor-pointer relative
           ${isOn ? "bg-green-500 dark:bg-green-600" : "bg-gray-300 dark:bg-gray-700"} animate-ease-2
           ${className}
           `}
      style={{ width: 44, height: 26 }}
      onClick={onChange}
    >
      <div style={{ width: 22, height: 22, left: isOn ? 20 : 2 }} className="rounded-full animate-ease-2 absolute bg-white"></div>
    </div>
  );
};
