export const getScoreTextColor = score => {
  if (score) {
    if (score < 40) return "bg-pink-500 dark:bg-pink-700";
    if (score < 60) return "bg-orange-500 dark:bg-orange-700";
    if (score < 80) return "bg-lime-500 dark:bg-lime-700";
    return "bg-green-500 dark:bg-green-700";
  }

  return "bg-blue-500 dark:bg-blue-700";
};
