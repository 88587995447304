import { ApiContext } from "contexts/ApiContext";
import { Pinyin } from "newComponents/Word";
import { useRef, useState, useEffect, useContext } from "react";
import { BsBook } from "react-icons/bs";
import { GiSpeaker } from "react-icons/gi";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { http } from "Services";
import FlashcardsContext from "./FlashcardsContext";

const CardFront = ({ word, cardFront, onClickAudio }) => {
  const { font } = useContext(FlashcardsContext);
  return (
    <div className="text-7xl px-6 -mt-4 text-center max-h-full">
      {cardFront === "Audio Only" ? (
        <div>
          <div
            onClick={e => {
              e.stopPropagation();
              onClickAudio();
            }}
            className="audio-player cursor-pointer p-8 hover:text-blue-500">
            <GiSpeaker size={72} />
          </div>
        </div>
      ) : cardFront === "English" ? (
        <div className="text-2xl sm:text-4xl">{word.definitions.slice(0, 5).join(", ")}</div>
      ) : (
        <div className="text-3xl sm:text-7xl" style={font ? { fontFamily: font } : null}>
          {word.text}
        </div>
      )}
    </div>
  );
};

const CardBack = ({
  word,
  rememberPoints,
  data,
  choosePoint,
  colors,
  rememberNumbers,
  rememberTexts,
  next_due,
  player,
  playPauseAudio
}) => {
  const { user } = useContext(ApiContext);
  const { font } = useContext(FlashcardsContext);

  const maxLength = 150;
  let definitionsString = word.definitions.join(", ");
  if (definitionsString.length > maxLength)
    definitionsString = definitionsString.substring(0, maxLength) + "...";

  return (
    <div className="bg-white dark:bg-gray-800 rounded-3xl overflow-hidden shadow-lg flex h-full flex-col justify-between">
      <div className="p-4 flex items-center justify-end space-x-4 z-10">
        {word.type !== "SENTENCE" ? (
          <a
            className="cursor-pointer text-gray-700 hover:text-blue-500 active:text-blue-600"
            href={`/dictionary/${word.id}`}
            target="_blank"
            rel="noreferrer">
            <BsBook size={21} />
          </a>
        ) : null}
        {word.audio_src ? (
          <div>
            <div>
              <audio className="hidden" ref={player} src={word.audio_src} />
              <div onClick={playPauseAudio} className={`audio-player cursor-pointer p-2`}>
                <GiSpeaker className="hover:text-blue-500" size={34} />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="flex-1 flex items-center justify-center">
        <div className="p-4 md:px-8 space-y-4 w-full -mt-8">
          <div className="font-bold text-xl sm:text-3xl text-center">{definitionsString}</div>
          <div className="flex flex-wrap justify-center space-x-1 text-gray-500">
            <Pinyin className="text-2xl" word={word} showAudio={false} />
          </div>
          <div
            className={`text-center flex justify-center flex-wrap
              ${word.characters.length > 10 ? "text-lg" : "text-3xl"}`}
            style={font ? { fontFamily: font } : null}>
            {word.characters.map((char, index) => (
              <div key={index}>
                <span style={{ color: user.tone_colours[char.tone ? char.tone : 0] }}>
                  {char.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex flex-shrink-0 h-24 relative bg-gray-100 dark:bg-gray-700">
        <div className="absolute inset-0 flex animate-ease-2 transform justify-between space-x-px">
          {rememberPoints
            .filter(i => (data.repeated ? i === 3 || i === 4 : true))
            .map((point, index) => (
              <div
                className="flex items-center justify-between text-center text-xs flex-1 bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                onClick={() => choosePoint(point - 1)}
                key={index}
                style={{ fontSize: 8 }}>
                <div className="flex-1">
                  <div className="font-bold text-xl" style={{ color: colors[point - 1] }}>
                    {data.repeated ? (
                      <div className="flex justify-center text-5xl">
                        {point === 3 ? <IoMdClose /> : <IoMdCheckmark />}
                      </div>
                    ) : (
                      rememberNumbers[point - 1]
                    )}
                  </div>
                  {data.repeated ? null : (
                    <div className="text-gray-500 mt-1">
                      <div>{rememberTexts[point - 1]}</div>
                      <div>{next_due[point - 1]}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const Flashcard = ({
  data,
  cardFront,
  next,
  isShowing = false,
  keyboardCounter,
  pressKeyboard
}) => {
  const { id, word, next_due } = data;
  const { autoPlayAudio, enableAnimation } = useContext(FlashcardsContext);
  const [flipped, setFlipped] = useState(false);

  const player = useRef();

  useEffect(() => {
    if (player && player.current) {
      if (isShowing) {
        if (cardFront === "Audio Only") {
          if (!flipped)
            setTimeout(() => {
              if (player.current) {
                try {
                  player.current.play();
                } catch {}
              }
            }, 700);
        } else {
          if (flipped && autoPlayAudio) {
            setTimeout(() => {
              if (player.current)
                try {
                  player.current.play();
                } catch {}
            }, 700);
          }
        }
      } else {
        player.current.pause();
      }
    }
  }, [isShowing, flipped]);

  useEffect(() => {
    if (isShowing && keyboardCounter > 0) {
      if (pressKeyboard === "Space") {
        setFlipped(true);
      } else if (flipped && pressKeyboard) {
        const point = pressKeyboard.substring(5, 6);
        if (point > 0 && point < 7 && isShowing) {
          if (data.repeated) {
            if (point > 2 && point < 5) choosePoint(point - 1);
          } else {
            choosePoint(point - 1);
          }
        }
      }
    }
  }, [keyboardCounter]);

  function playPauseAudio() {
    if (player && player.current)
      player.current.paused ? player.current.play() : player.current.pause();
  }

  function choosePoint(point) {
    if (!data.repeated) http.patch(`/flashcards/${id}`, { quality: point }).catch();
    next(point < 3 ? id : null);
  }

  const rememberPoints = [1, 2, 3, 4, 5, 6];
  const rememberNumbers = ["一", "二", "三", "四", "五", "六"];
  const colors = ["#F87171", "#f28652", "#df9e3d", "#b5bb3f", "#8bcd54", "#4ADE80"];
  const rememberTexts = ["Don't Know", "Forgotten", "Almost", "Barely", "Remembered", "Perfect"];

  if (enableAnimation)
    return (
      <div className="flex justify-center py-12 sm:py-20" style={{ perspective: 1000 }}>
        <div
          className={`relative ${flipped ? "flipped" : ""}`}
          style={{
            transition: "transform 0.5s",
            transformStyle: "preserve-3d",
            transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)"
          }}>
          <div className="w-80 sm:w-125 md:w-150 aspect-[4/3] z-0 relative invisible"></div>

          <div
            className="absolute z-10 inset-0 bg-white dark:bg-gray-800 rounded-3xl overflow-hidden shadow-xl flex flex-col items-center justify-center cursor-pointer visiable"
            style={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden"
            }}
            onClick={() => setFlipped(true)}>
            <CardFront
              word={word}
              cardFront={cardFront}
              isShowing={isShowing}
              flipped={flipped}
              onClickAudio={playPauseAudio}
            />
          </div>

          <div
            className="absolute z-20 inset-0"
            style={{
              transform: "rotateY(180deg)",
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden"
            }}>
            <CardBack
              word={word}
              rememberPoints={rememberPoints}
              data={data}
              choosePoint={choosePoint}
              colors={colors}
              rememberNumbers={rememberNumbers}
              rememberTexts={rememberTexts}
              next_due={next_due}
              player={player}
              playPauseAudio={playPauseAudio}
            />
          </div>
        </div>
      </div>
    );

  return (
    <div className="flex justify-center py-12 sm:py-20">
      <div className="relative">
        <div className="w-80 sm:w-100 md:w-125 aspect-[4/3] z-0 relative invisible"></div>

        {!flipped ? (
          <div
            className="absolute z-10 inset-0 bg-white dark:bg-gray-800 rounded-3xl overflow-hidden shadow-xl flex flex-col items-center justify-center cursor-pointer visiable"
            style={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden"
            }}
            onClick={() => setFlipped(true)}>
            <CardFront
              word={word}
              cardFront={cardFront}
              isShowing={isShowing}
              flipped={flipped}
              onClickAudio={playPauseAudio}
            />
          </div>
        ) : (
          <div className="absolute z-20 inset-0">
            <CardBack
              word={word}
              rememberPoints={rememberPoints}
              data={data}
              choosePoint={choosePoint}
              colors={colors}
              rememberNumbers={rememberNumbers}
              rememberTexts={rememberTexts}
              next_due={next_due}
              player={player}
              playPauseAudio={playPauseAudio}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Flashcard;
