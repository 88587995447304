const parseErrorMessage = (error, defaultMessage = "Unknown error.") => {
  console.error("parseErrorMessage", error);

  if (error && error.response) {
    // console.log("error not defined, setting error to error.response");
    error = error.response;
  }

  if (error && error.data) {
    // console.log("error not defined, setting error to error.data");
    error = error.data;
  }

  if (error && error.errors) {
    // console.log("error.errors exists, checking if there's also message");
    let message = "";

    if (error.message && typeof error.message === "string") {
      message += error.message + " ";
    }

    if (typeof error.errors === "object") {
      // console.log("error.errors is an object, implode by key");
      Object.keys(error.errors).map(key => {
        if (typeof error.errors[key][0] === "string") {
          message += error.errors[key][0] + " ";
        }
      });
    }

    return message.trim();
  }

  if (error && error.message && typeof error.message === "string") {
    // console.log("error.message exists, return message");
    return error.message.trim();
  }

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    typeof error.response.data.error === "string"
  ) {
    let message = error.response.data.error;

    if (error.response.statusText) {
      message = `${error.response.statusText}: ${message}`;
    }

    return message;
  }

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message &&
    typeof error.response.data.message === "string"
  ) {
    return error.response.data.message;
  }

  return defaultMessage;
};

export default parseErrorMessage;
