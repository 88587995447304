import { useState, useEffect } from "react";
import axios from "axios";

window.requestCache = {};

const instance = ({ ...options }) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + "/v3",
    withCredentials: true,
    ...options
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (process.env.NODE_ENV === "development") {
        console.dir("error.response", error.response);
      }

      if (error && error.response && error.response.status === 401) {
        console.log("error", error);
        // window.location = process.env.REACT_APP_AUTH_URL + "?redirectUrl=" + window.location.href;
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};

export const http = instance();

export const useGet = (url, lazy = false, options = {}) => {
  const [state, setState] = useState({
    loading: false,
    error: false,
    res: null
  });

  const { onComplete, onError } = options;

  function doRequest(options = {}) {
    setState(prev => ({ ...prev, loading: true }));
    http
      .get(url, options)
      .then(res => {
        setState(prev => ({ ...prev, loading: false, res: res.data }));
        if (onComplete) onComplete(res.data.data);
      })
      .catch(error => {
        setState(prev => ({ ...prev, error, loading: false }));
        if (axios.isCancel(error)) return;
        if (onError) onError(error);
      });
  }

  useEffect(() => {
    const source = axios.CancelToken.source();
    let cancelled = false;
    setState(prev => ({ ...prev, loading: true }));
    http
      .get(url, options)
      .then(res => {
        if (cancelled) return;
        if (!res) return;
        setState(prev => ({ ...prev, loading: false, res: res.data }));
        if (onComplete) onComplete(res.data.data);
      })
      .catch(error => {
        setState(prev => ({ ...prev, error, loading: false }));
        if (cancelled) return;
        if (axios.isCancel(error)) return;
        if (onError) onError(error);
      });
    //doRequest({ cancelToken: source.token }, cancelled);
    return () => {
      source.cancel();
      cancelled = true;
    };
  }, [url]);

  const { loading, error, res } = state;

  if (lazy) return [doRequest, { loading, error, ...res }];
  return { loading, error, ...res, refresh: doRequest };
};

export const usePost = (url, { onComplete, onError } = {}) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  function doRequest(data = null) {
    setLoading(true);
    http
      .post(url, data)
      .then(res => {
        setLoading(false);
        setLoaded(true);
        if (onComplete) onComplete(res);
      })
      .catch(err => {
        setLoading(false);
        if (onError) onError(err);
      });
  }

  return [doRequest, { loading, loaded }];
};

export const useLazyPost = ({ onComplete, onError } = {}) => {
  const [loading, setLoading] = useState(false);

  function doRequest(url, data = null) {
    setLoading(true);
    http
      .post(url, data)
      .then(res => {
        setLoading(false);
        if (onComplete) onComplete(res);
      })
      .catch(err => {
        setLoading(false);
        if (onError) onError(err);
      });
  }

  return [doRequest, { loading }];
};

export const useDelete = (url, { onComplete, onError } = {}) => {
  const [loading, setLoading] = useState(false);

  function doRequest(data = null) {
    setLoading(true);
    http
      .delete(url, data)
      .then(res => {
        setLoading(false);
        if (onComplete) onComplete(res);
      })
      .catch(err => {
        setLoading(false);
        if (onError) onError(err);
      });
  }

  return [doRequest, { loading }];
};

export const usePatch = (url, { onComplete, onError } = {}) => {
  const [loading, setLoading] = useState(false);

  function doRequest(data = null) {
    setLoading(true);
    http
      .patch(url, data)
      .then(res => {
        setLoading(false);
        if (onComplete) onComplete(res);
      })
      .catch(err => {
        setLoading(false);
        if (onError) onError(err);
      });
  }

  return [doRequest, { loading }];
};
